import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner';

import '../stylesheets/Register.css'
import {ReactComponent as Logo} from '../assets/icons/examlogo2.svg'
import { useSendVerificationEmail } from '../hook/useSendVerificationEmail'

import footericonright from '../assets/icons/footericon-right.svg'
import footericonleft from '../assets/icons/footericon-left.svg'


function Signupemailconfirmation() {

    const [code, setCode] = useState('')
    const { verifyemail, isLoading } = useSendVerificationEmail()
  
    const verifyEmail = async(e) => {
      e.preventDefault()
      await verifyemail(code)
    }
  
  
    return (
      <div>
        {/* Header */}
        <div className="row justify-content-between header">
          <div className="col-lg-2 col-sm-3 col-4">
              <Logo />
          </div>
          <div className="col-lg-3 header-right col-sm-4 col-6">
              <p>
              Don’t have an account? Signup <Link to="/Register">here</Link>
              </p>
          </div>
        </div>
        {/* Content */}
        <div className='row mainbody'>
          <div className='col-lg-2'>
            <h1 className='title'>Verify Email</h1>
          </div>
          <div className='row justify-content-around pt-md-5 pt-lg-0 pt-4'>
            <div className='col-lg-7'>
            <div className='row justify-content-center'>
              <form className='col-lg-6 col-sm-5 col-8'>
                <div className='text rest-p'>
                  <p>
                    Enter the Email Verification code sent to your email 
                  </p>
                </div>
                <div className='login-input pt-3'>
                  <label className='label'>Verification code<span>*</span></label>
                  <input type='text' name="code" onChange={(e) => {setCode(e.target.value)}} />
                </div>
                <button className='register-button resetcode-button' onClick={verifyEmail}>  { isLoading   ? <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner> : 'Verify Code'}</button>
              </form>
            </div>
            <div className='row justify-content-between pt-5'>
              <div className='col-lg-2 footericonleft col-sm-2 col-2'>
                <img src={footericonleft} alt="footericonleft" />
              </div>
              <div className='col-lg-2 footericonright col-sm-2 col-2'>
                <img src={footericonright} alt="footericonleft" />
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Signupemailconfirmation