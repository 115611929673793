import axios from 'axios'
import { useCallback, useState } from 'react';
import { Cookies } from 'react-cookie';
import { API } from '../helper/action';

export const usePoint = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [points, setPoint] = useState({})
    const [history, setHistory] = useState([])
    const cookies = new Cookies();

    let token = cookies.get("jwt")

    const point = useCallback(async () => {
        setIsLoading(true)
        setError(null)

        await axios.get(`${API}/user/points`,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            setIsLoading(false)
            // console.log(response);
            setPoint(response.data.data.points)
            setHistory(response.data.data.point_history)
        }).catch((err) => {
            setIsLoading(false)
            setError(err)
        })
    }, [token])

    
    return { point, points, isLoading, error, history }
}
