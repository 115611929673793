import axios from 'axios'
import { useCallback, useState } from 'react';
import { Cookies } from 'react-cookie';
import { API } from '../helper/action';

export const useDashboard = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const cookies = new Cookies();
    
    let token = cookies.get("jwt")
    

    const dashboard = useCallback(async (setData, days) => {
        setIsLoading(true)
        setError(null)
        

        await axios.get(`${API}/user/dashboard-parameters`,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            setIsLoading(false)
            setData(response.data.data)
            response.data.data.study_stat.forEach(element => {
                let today = new Date(element.date).getDay()
                // let getday = Object.key(days)[today]
                days[today] = element.attempts
                console.log(today);
              });
            
        }).catch((err) => {
            setIsLoading(false)
            setError(err)
        })
    }, [token])

    
    return { dashboard, isLoading, error }
}
