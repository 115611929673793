import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ReactPaginate from "react-paginate";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

import "../stylesheets/Pastquestions.css";
import arrowicon from "../assets/icons/arrowicon.svg";
// import pqimg from "../assets/icons/pqimg.svg";
import searchicon from "../assets/icons/Combined-Shape.svg";
import { ReactComponent as Nexticon } from "../assets/icons/nextarrw.svg";
import { ReactComponent as Previcon } from "../assets/icons/prevarrw.svg";
import { ReactComponent as Pqimg } from "../assets/icons/pqimg.svg";
import { usePastquestion } from "../hook/pastquestion";
import { useGeneratmockexam } from "../hook/generatemockexam";
import Loader from "../Components/Loader";

function Pastquestions() {
  const [pastquestion, setPastquestion] = useState([]);
  const [totalcount, setTotalcount] = useState([]);
  const [filtereddata, setFiltereddata] = useState([]);
  const [subject, setSubject] = useState([]);
  const [years, setYears] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const { Pastquestion, Pastquestion2, isLoading } = usePastquestion();
  const { examtype, examLoading } = useGeneratmockexam();

  const year = function yearlist(startYear = 1985) {
    const endDate = new Date().getFullYear();
    let years = [];

    while (startYear <= endDate) {
      years.push(startYear);
      startYear++;
    }
    setYears(years);
  };

  const filterByExam = async (e) => {
    const searchword = e.target.value;
    let category;
    switch (searchword) {
      case "JAMB - UTME":
        category = "1";
        break;
      case "WAEC - SSCE":
        category = "2";
        break;
      case "WAEC - GCE":
        category = "3";
        break;
      case "NECO":
        category = "4";
        break;
      case "NOUN":
        category = "5";
        break;
      default:
        category = "";
    }
    await examtype(category, setSubject);
    const newfilter = pastquestion.filter((value) => {
      return value.category.name.includes(searchword);
    });
    setFiltereddata(newfilter);
  };

  const filterBySubject = (e) => {
    const searchword = e.target.value;

    const newfilter = filtereddata.filter((value) => {
      return value.name.includes(searchword);
    });
    setFiltereddata(newfilter);
  };

  const filterByYear = (e) => {
    const searchword = e.target.value;
    if (filtereddata.length === 0) {
      const newfilter = pastquestion.filter((value) => {
        return value.from_year.includes(searchword);
      });
      setFiltereddata(newfilter);
    } else {
      const newfilter = filtereddata.filter((value) => {
        return value.from_year.includes(searchword);
      });
      setFiltereddata(newfilter);
    }
  };

  const itemsPerPage = 8;
  const endOffset = itemOffset + itemsPerPage;
  const PageCount = Math.ceil(totalcount.total || 0 / itemsPerPage);
  const PageCount1 = Math.ceil(filtereddata.length || 0 / itemsPerPage);

  useEffect(() => {
    Pastquestion(setPastquestion, setTotalcount);
    year();
  }, [Pastquestion]);

  const pagenumber = pastquestion.length / totalcount.per_page + 1;
  const handlePageClick = async (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalcount.total;
    const setnewOffset = newOffset + itemsPerPage;
    const remainitem = pastquestion.length - setnewOffset;
    if (remainitem < itemsPerPage) {
      await Pastquestion2(setPastquestion, pastquestion, pagenumber);
    }
    setItemOffset(newOffset);
  };
  const handlePageClick1 = async (event) => {
    const newOffset = (event.selected * itemsPerPage) % filtereddata.length;
    setItemOffset(newOffset);
  };

  const filteredpost = filtereddata
    .slice(itemOffset, endOffset)
    .map((post, index) => {
      return (
        <div className="pastq-box" key={index}>
          <div className="pastq-box-img">
            {/* <img src={pqimg} alt="pqimg" /> */}
            <Pqimg />
          </div>
          <div className="pastq-box-info">
            <h6>{post.name}</h6>
            <p>
              Subject : <span>{post.slug}</span>
            </p>
            <p>
              Type : <span>{post.category.name}</span>
            </p>
            {/* <p>
            Exam fee : <span>Free</span>
          </p> */}
            <Link to="/availableexam">
              Exam Details
              <img src={arrowicon} alt="arrowicon" />
            </Link>
          </div>
        </div>
      );
    });

  const displayPosts = pastquestion
    .slice(itemOffset, endOffset)
    .map((post, index) => {
      return (
        <div className="pastq-box" key={index}>
          <div className="pastq-box-img">
            {/* <img src={pqimg} alt="pqimg" /> */}
            <Pqimg />
          </div>
          <div className="pastq-box-info">
            <h6>{post.name}</h6>
            <p>
              Subject : <span>{post.slug}</span>
            </p>
            <p>
              Type : <span>{post.category.name}</span>
            </p>
            {/* <p>
              Exam fee : <span>Free</span>
            </p> */}
            <Link to="/availableexam">
              Exam Details
              <img src={arrowicon} alt="arrowicon" />
            </Link>
          </div>
        </div>
      );
    });

  return (
    <div>
      <Navbar />
      <div className="pq-container">
        <div className="row apq justify-content-evenly">
          <div className="col-lg-7 apq7">
            <h6>Past Questions</h6>
            <h3>All Past Questions</h3>
            <p>
              We Will Open The World Of Knowledge For You! This is the latest
              online examination system you will ever need!
            </p>
            <div className="justify-content-start filterbox">
              <Form.Select
                defaultValue="Specify Exam Type"
                className="selectbox selectbox1 pqselectbox"
                onChange={filterByExam}
              >
                <option>Specify Exam Type</option>
                <option value="JAMB - UTME">JAMB - UTME</option>
                <option value="WAEC - SSCE">WAEC - SSCE</option>
                <option value="WAEC - GCE">WAEC - GCE</option>
                <option value="NECO">NECO</option>
                <option value="NOUN">NOUN</option>
              </Form.Select>
              <Form.Select
                defaultValue="Select Subject"
                className="selectbox pqselectbox"
                onChange={filterBySubject}
              >
                <option>{examLoading ? 'Loading Subject ...' : 'Select Subject'}</option>
                {Object.entries(subject).map(([key, value]) => {
                  return (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Select
                className="selectbox"
                onChange={filterByYear}
                defaultValue="Select year"
              >
                <option>Select year</option>
                {years.map((year, index) => {
                  return (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  );
                })}
              </Form.Select>
              <button className="searchbutton bg-black pqsbutton">
                <img
                  src={searchicon}
                  alt="searchicon"
                  className="pqbuttonimg"
                />
              </button>
            </div>
          </div>
          <div className="col-lg-3 apq4">
            <div>
              <h6>All Categories</h6>
              <li>UTME</li>
              <li>WAEC - SSCE</li>
              <li>WAEC - GCE</li>
              <li>NECO</li>
              <li>NOUN</li>
              <li>NURSING SCHOOL</li>
              <li>IETLS</li>
            </div>
            <div>
              <h6>All Subjects</h6>
              <li>MARKETING</li>
              <li>PRIMARY EDUCATION</li>
              <li>POLITICAL SCIENCE</li>
              <li>NECO/FUTHER MATHEMATICS</li>
              <li>NECO-BIOLOGY</li>
              <li>NECO/PHYSICS</li>
              <li>NECO/Financial Account</li>
              <li>NECO- GOVERNMENT</li>
            </div>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="pq-grid">
            {filtereddata.length !== 0 ? filteredpost : displayPosts}
          </div>
        )}
        <div className="mt-5">
          {filtereddata.length !== 0 ? (
            <ReactPaginate
              breakLabel="..."
              previousLabel={<Previcon />}
              nextLabel={<Nexticon />}
              pageCount={PageCount1}
              onPageChange={handlePageClick1}
              containerClassName={"Paginationbtn"}
              previousLinkClassName={"prevbtn"}
              pageClassName={"pageli"}
              nextLinkClassName={"nextbtn"}
              disabledClassName={"paginationdisabled"}
              activeClassName={"paginationactive"}
              breakClassName={"breakli"}
              renderOnZeroPageCount={null}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
            />
          ) : (
            <ReactPaginate
              breakLabel="..."
              previousLabel={<Previcon />}
              nextLabel={<Nexticon />}
              pageCount={PageCount}
              onPageChange={handlePageClick}
              containerClassName={"Paginationbtn"}
              previousLinkClassName={"prevbtn"}
              pageClassName={"pageli"}
              nextLinkClassName={"nextbtn"}
              disabledClassName={"paginationdisabled"}
              activeClassName={"paginationactive"}
              breakClassName={"breakli"}
              renderOnZeroPageCount={null}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Pastquestions;
