import React from "react";

import "../stylesheets/loader.css";

function Loader() {
  return (
   <div>
     <div className="book-cont">
      <div className="loader book">
        <figure className="page"></figure>
        <figure className="page"></figure>
        <figure className="page"></figure>
      </div>
      <h1 className="h12">Loading</h1>
    </div>
   </div>
  );
}

export default Loader;
