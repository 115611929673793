import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../stylesheets/subscription.css";
import { ReactComponent as Loadicon } from "../assets/icons/loadicon.svg";
import Modalsub from "../Components/Modal";
import { usePayment } from "../hook/payment";
import { Cookies } from "react-cookie";

function Subscriptions() {
  const [modalShow, setModalShow] = useState(false);
  const [subplan, setSubplan] = useState({});
  const [account, setAccount] = useState();
  const { getaccount, isLoading } = usePayment();
  const cookies = new Cookies();
  let user = cookies.get("user");
  user = JSON.parse(atob(user));

  const generateaccount = async() => {
    setModalShow(true);
    await getaccount(subplan.amount, setAccount)
  }

  let plan;
  switch (user.user_plan) {
    case "1-Month Plan":
      plan = "500 Per Month";
      break;
    case "3-Month Plan":
      plan = "1200 Quaterly";
      break;
    case "6-Month Plan":
      plan = "2500 Bi-Annually";
      break;
    case "1-Year Plan":
      plan = "5000 Annually";
      break;
    default:
      break;
  }

  return (
    <div className="subscriptioncontent">
      {user.user_plan === undefined ? (
          ""
        ) : user.user_plan === "No Active Subscription" ? (
          ""
        ) : (
          <div>
            <div>
        <h6>My subscription plan</h6>
      </div>
      <div>
        <div className=" subscriptiionbox">
          <h6>
            Student’s <span className="activeplandnd">{user.user_plan}</span>
          </h6>
          <span>
            <p>NGN{plan}</p>
            <Link
            >
              Upgrade
            </Link>
          </span>
        </div>
      </div>
            </div>
        )}

      <div>
        <h6>Plans & Pricing</h6>
        <div className="subscriptionboxplangrid">
          <div
            className={`${
              user.user_plan === "1-Month Plan"
                ? "activesubplan"
                : "subscriptionboxplan"
            }`}
          >
            <div>
              <Loadicon />
            </div>
            <div>
              <h6>1-Month Plan</h6>
            </div>
            <div>
              <h5>NGN500</h5>
              <span>Bill due monthly</span>
            </div>
            <button
              disabled={user.user_plan === "1-Month Plan" ? true : false}
              onMouseOver={() => {
                setSubplan({ plan: "1-Month Plan", amount: "500", description: 'One Month plan Bill Monthly'  });
              }}
              onClick={generateaccount}
            >
              {user.user_plan === "1-Month Plan"
                ? "Subscribed"
                : "Try This Plan"}
            </button>
          </div>
          <div
            className={`${
              user.user_plan === "3-Months Plan"
                ? "activesubplan"
                : "subscriptionboxplan"
            }`}
          >
            <div>
              <Loadicon />
            </div>
            <div>
              <h6>3-Months Plan</h6>
            </div>
            <div>
              <h5>NGN1200</h5>
              <span>Bill due Quaterly</span>
            </div>
            <button
              disabled={user.user_plan === "3-Months Plan" ? true : false}
              onMouseOver={() => {
                setSubplan({ plan: "3-Months Plan", amount: "1200", description: 'Three Month plan Bill due Quaterly'  });
              }}
              onClick={generateaccount}
            >
              {user.user_plan === "3-Months Plan"
                ? "Subscribed"
                : "Try This Plan"}
            </button>
          </div>
          <div
            className={`${
              user.user_plan === "6-Months Plan"
                ? "activesubplan"
                : "subscriptionboxplan"
            }`}
          >
            <div>
              <Loadicon />
            </div>
            <div>
              <h6>6-Months Plan</h6>
            </div>
            <div>
              <h5>NGN2500</h5>
              <span>Bill due Bi-Annually</span>
            </div>
            <button
              disabled={user.user_plan === "6-Months Plan" ? true : false}
              onMouseOver={() => {
                setSubplan({ plan: "6-Month Plan", amount: "2500", description: 'Six Month plan Bill due Bi-Annually'  });
              }}
              onClick={generateaccount}
            >
              {user.user_plan === "6-Months Plan"
                ? "Subscribed"
                : "Try This Plan"}
            </button>
          </div>
          <div
            className={`${
              user.user_plan === "1-Year Plan"
                ? "activesubplan"
                : "subscriptionboxplan"
            }`}
          >
            <div>
              <Loadicon />
            </div>
            <div>
              <h6>1-Year Plan</h6>
            </div>
            <div>
              <h5>NGN5000</h5>
              <span>Bill due Annually</span>
            </div>
            <button
              disabled={user.user_plan === "1-Year Plan" ? true : false}
              onMouseOver={() => {
                setSubplan({ plan: "1-Year Plan", amount: "5000", description: 'One Year plan Bill due Annually'  });
              }}
              onClick={generateaccount}
            >
              {user.user_plan === "1-Year Plan"
                ? "Subscribed"
                : "Try This Plan"}
            </button>
          </div>
        </div>
        <Modalsub
          data={subplan}
          show={modalShow}
          account={account}
          accountloading={`${isLoading}`}
          onHide={() => setModalShow(false)}
        />
      </div>
    </div>
  );
}

export default Subscriptions;
