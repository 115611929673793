import React, { useEffect, useState } from 'react'
import '../stylesheets/pointtracker.css'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { usePoint } from '../hook/point';
import Redeem from '../Components/Redeem';

function Pointtracker() {
    const { point, points, isLoading } = usePoint()
    const [display, setDisplay] = useState(false)
    const [show, setShow] = useState(false)

    function click (points) {
        if(pointscore > points) {
            setShow(true)
        }
    }

    useEffect(() => {
        point()
    }, [point])
    let pointscore = points === null ? 0 : points.current_point / 10
    return (
        <div className='point-container'>
            <div className='current-point'>
                <h1>{isLoading ? '...' : points === null ? 0 : points.current_point}<span className={`${isLoading ? 'd-none' : ''}`}>Pts</span></h1>
                <p>
                    accumulated points
                </p>
            </div>
            <div className={` ${display ? 'd-none' : ''}`}>
                <div className='progress-container'>
                    <div>
                        <div className='progress-set'>
                            <ProgressBar now={pointscore} />
                        </div>
                        <div className='progress-indicator-sett'>
                            <div className='row'>
                                <div className={`vertical-line ten-percent  ${pointscore >= 10 ? 'notover' : ''}`}></div>
                                <div className={`vertical-line thirty-percent  ${pointscore >= 30 ? 'notover' : ''}`}></div>
                                <div className={`vertical-line fifty-percent  ${pointscore >= 50 ? 'notover' : ''}`}></div>
                                <div className={`vertical-line seventy-percent  ${pointscore >= 70 ? 'notover' : ''}`}></div>
                                <div className={`vertical-line hundred-percent  ${pointscore >= 100 ? 'notover' : ''}`}></div>
                            </div>
                            <div className='row vertical-box-container'>
                                <div className={`vertical-box ten-percent ${pointscore >= 10 ? 'notoverbox' : ''}`}> <span>100</span> </div>
                                <div className={`vertical-box vertical-box-3 thirty-percent ${pointscore >= 30 ? 'notoverbox' : ''}`}><span>300</span> </div>
                                <div className={`vertical-box vertical-box-3 fifty-percent ${pointscore >= 50 ? 'notoverbox' : ''}`}><span>500</span> </div>
                                <div className={`vertical-box vertical-box-3 seventy-percent ${pointscore >= 70 ? 'notoverbox' : ''}`}><span>700</span> </div>
                                <div className={`vertical-box vertical-box-4 hundred-percent ${pointscore >= 100 ? 'notoverbox' : ''}`}><span>1000</span> </div>
                            </div>
                        </div>
                    </div>
                    <div className='claim-airtime'>
                        <button onClick={() => setDisplay(true)}>
                            Claim Your reward
                        </button>
                    </div>
                </div>
                <div className='claim-instruction'>
                    <p>
                        <span>6000 Points</span> is highest reward to claim
                    </p>
                    <p>
                        (It’s resets once any viable amount of the reward is claimed)
                    </p>
                </div>
                <div className='claim-list'>
                    <div>
                        <span>
                            100 Points -
                        </span>
                        <span>
                            {" "}NGN 100
                        </span>
                    </div>
                    <div>
                        <span>
                            300 Points -
                        </span>
                        <span>
                            {" "}NGN 300
                        </span>
                    </div>
                    <div>
                        <span>
                            500 Points -
                        </span>
                        <span>
                            {" "}NGN 500
                        </span>
                    </div>
                    <div>
                        <span>
                            700 Points -
                        </span>
                        <span>
                            {" "}NGN 700
                        </span>
                    </div>
                    <div>
                        <span>
                            1000 Points -
                        </span>
                        <span>
                            {" "} NGN 1000
                        </span>
                    </div>
                </div>
                <div className='claim-info'>
                    <p>Get closer to claiming airtime rewards every time you practice on ExamsAfrica, earn and accumulate points. Go ahead to swap those accumulated points for MTN airtime! </p>
                </div>
            </div>
            <div className={` ${display ? '' : 'd-none'}`}>
                <div className='claim-option-tab'>
                    <div onClick={click(10)} className={`option-tab ${pointscore > 10 ? 'activeoption' : ''}`}>
                        Claim Airtime
                    </div>
                    <div onClick={click(10)} className={`option-tab ${pointscore > 10 ? 'activeoption' : ''}`}>
                        Claim Data
                    </div>
                    <div onClick={click(300)} className={`option-tab ${pointscore > 300 ? 'activeoption' : ''}`}>
                        Buy WAEC Result Pin NGN3000
                    </div>
                    <div onClick={click(100)} className={`option-tab ${pointscore > 100 ? 'activeoption' : ''}`}>
                        Buy JAMB Result Pin NGN1000
                    </div>
                    <div onClick={click(600)} className={`option-tab ${pointscore > 600 ? 'activeoption' : ''}`}>
                        Buy JAMB Form NGN6000
                    </div>
                    <div onClick={click(500)} className={`option-tab ${pointscore > 500 ? 'activeoption' : ''}`}>
                        Buy WAEC Form NGN5000
                    </div>
                </div>
                <div className='text-center claim-info-2'>
                    <p>
                        <span>100 Points</span> is the lowest claimable reward,
                    </p>
                    <p>
                        <span> 1 Point = 1 Naira.</span>
                    </p>
                    <p>
                        Only options you have enough points for will be clickable
                    </p>
                </div>
            </div>
            <Redeem show={show}
        onHide={() => {setShow(false)}} />
        </div>
    )
}

export default Pointtracker