import React from 'react'
import Modal from "react-bootstrap/Modal";

function Redeem(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="confirm-modal"
        >
            <Modal.Body>
                <p className="text-center generate-popup">Coming Soon</p>
            </Modal.Body>
        </Modal>
    )
}

export default Redeem