import { useState } from 'react'
import axios from 'axios'
import { Cookies } from 'react-cookie';
import { API } from '../helper/action';

export const useExamsubmit = () => {
    const [error, setError] = useState(null)
    const [isSubmitLoading, setIsLoading] = useState(null)
    // const [successmessage, setSuccessmessage] = useState(null)
    const cookies = new Cookies();

    const examsubmit = async (selectedanswer, setSubmit, setSubmitModalShow, setProgShow ) => {
        setIsLoading(true)
        setError(null)
        setProgShow(true)
        setSubmitModalShow(false)

        // console.log(selectedanswer)
        // const formData = new FormData()
        // formData.append(selectedanswer)
        // console.log(formData)


        let token = cookies.get("jwt")

       await axios.post(`${API}/user/exams/exam-submission`, selectedanswer ,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        } ).then((response) => {
            // const user = response.data
            // console.log(user)
            setSubmit(response.data.data.exam)
            setIsLoading(false)
            setProgShow(false)
            setSubmitModalShow(true)
        }).catch((err) => {
            setIsLoading(false)
            setError(err.response.data.message)
            // console.log(err.response.data.errors.username)
        })
    }
    
    return { examsubmit, isSubmitLoading, error}
}
