import React, { useState } from 'react'
import { Link } from "react-router-dom";

import "../stylesheets/Navbar.css";
import { ReactComponent as Examlogo} from "../assets/icons/exams-2.svg";
import { ReactComponent as Logo} from "../assets/icons/exams2.svg";

function Navbar() {
        const [menu, setMenu] = useState(false)

        const hamburger = () => { 
        setMenu(!menu)
    }

    return (
      <div>
        <div className="row justify-content-between Navbar-container">
            <div className="col-lg-2 col-sm-2">
                <Link to="/" aria-label='Home'>
                <Examlogo />
                </Link>
            </div>
            <div className="col-lg-7 col-sm-10">
            <div className="Navbar-menu">
                <Link to="/pastquestions" className="">
                Past Questions
                </Link>
                <Link to="/blog" className="">
                Blog
                </Link>
                <Link to="/f.a.q" className="">
                FAQ
                </Link>
                <Link to="/contact" className="">
                Contact Us
                </Link>
                <Link to="/register" className="regbutton">
                Register
                </Link>
                <Link to="/login" className="loginbutton">
                Log In
                </Link>
            </div>
            </div>
        </div>
        <div className='row justify-content-between mobile-menu'>
            <div className="col-4 mobile-img-box" >
                <Link to="/" aria-label='Home'>
                    <Logo />
                </Link>
            </div>
            <div className='col-2 hamburger-container' onClick={hamburger}>
                <span className={`hamburger ${menu ? "hamburgerX1" : ""}`}></span>
                <span className={`hamburger ${menu ? "hamburgerX3" : ""}`}></span>
                <span className={`hamburger ${menu ? "hamburgerX2" : ""}`}></span>
            </div>
            <div className={`${menu ? "show1" : "hide1"}`}>
                <li>
                    <Link to="/pastquestions" className="">
                    Past Questions
                    </Link>
                </li>
                <li>
                    <Link to="/blog" className="">
                    Blog
                    </Link>
                </li>
                <li>
                    <Link to="/f.a.q" className="">
                    FAQ
                    </Link>
                </li>
                <li> 
                    <Link to="/contact" className="">
                    Contact Us
                    </Link>
                </li>
                <li className='mobile-button'>
                    <Link to="/register" className="regbutton menureg">
                    Register
                    </Link>
                </li>
                <li >
                    <Link to="/login" className="loginbutton menulogin">
                    Log In
                    </Link>
                </li>
            </div>
        </div>
    </div>
    );
}

export default Navbar