import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

import "../stylesheets/Dashboard.css";
import {ReactComponent as Progressbarimg} from "../assets/icons/progressbar.svg";
import Carousel from 'react-bootstrap/Carousel';
import eye from '../assets/icons/eye.svg'
import dateicon from '../assets/icons/dateicon.svg'
import readicon from '../assets/icons/readicon.svg'
import praticeimg from "../assets/images/newsimage1.png";
// import praticeimg2 from "../assets/images/newsimage2.png";s
import sypimage from "../assets/images/sypimage.svg";
import { useDashboard } from "../hook/dashboard";
import searchicon from "../assets/icons/Combined-Shape.svg";
import { useGeneratmockexam } from "../hook/generatemockexam";
import { useProfile } from "../hook/profile";
// import { Cookies } from "react-cookie";
import Welcome from "../Components/Welcome";
import { useBlog } from "../hook/blog";

function Dashboard() {
  const [data, setData] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const { dashboard } = useDashboard();
  const [param, setParam] = useState({
    year: "",
    subject_id: "",
  });
  const [blog, setBlog] = useState([])
  const { blogpost } = useBlog()
  const [subject, setSubject] = useState([]);
  const [years, setYears] = useState([]);
  const { examtype, examLoading } = useGeneratmockexam();
  const { profile } = useProfile();
  const navigate = useNavigate();
  // const cookies = new Cookies();
  // let user = cookies.get("user");
  // user = JSON.parse(atob(user));
  const [days, setDays] = useState({
    0: '',
1: '',
2: '',
3: '',
4: '',
5: '',
6: ''
  })

  // console.log(days);
  

  // const date =  data.study_stat.forEach(element => {
  //   console.log(element);
  // });
  // ((stat) => {
  //       return (
  //         let today = new Date(stat.date).getDay();
  //       let getday = Object.key(days)[today]
  //       setDays({...days, t: stat.attempts})
  //       )
        
  //     })

  // console.log(date);

  const allyear = function yearlist(startYear = 1985) {
    const endDate = new Date().getFullYear();
    let years = [];

    while (startYear <= endDate) {
      years.push(startYear);
      startYear++;
    }
    setYears(years);
  };

  const filterByExam = async (e) => {
    const searchword = e.target.value;
    let category;
    switch (searchword) {
      case "JAMB - UTME":
        category = "1";
        break;
      case "WAEC - SSCE":
        category = "2";
        break;
      case "WAEC - GCE":
        category = "3";
        break;
      case "NECO":
        category = "4";
        break;
      case "NOUN":
        category = "5";
        break;
      default:
        category = "";
    }
    await examtype(category, setSubject);
  };

  const getfilteredexam = async (e) => {
    let searchdata = param;
    searchdata = btoa(JSON.stringify(searchdata));
    sessionStorage.setItem("search", searchdata);
    navigate("/availableexam", {
      state: { message: "dashboard" },
      replace: false,
    });
  };

  // const ProgressBar = () => {
  //   const max = 100;
  //   const SVG_DASHARRAY_MAX = 126;
  //   const dashOffset = Math.round(
  //     SVG_DASHARRAY_MAX - (50 * SVG_DASHARRAY_MAX) / max
  //   );

  //   const containerWidth = 60;

  //   return (
  //     <svg
  //       viewBox={`0 0 120 ${containerWidth}`}
  //       width="100%"
  //     // className={className}
  //     >
  //       <style>{`@keyframes progress {
  //         to { stroke-dashoffset: ${dashOffset}; }
  //         }`}</style>
  //       <path
  //         d="M20,50 A10,10 0 1,1 100,50"
  //         fill="none"
  //         strokeLinecap="round"
  //         strokeWidth={4}
  //         stroke="#F5F7FA"
  //       />
  //       <path
  //         d="M20,50 A10,10 0 1,1 100,50"
  //         fill="none"
  //         strokeLinecap="round"
  //         strokeWidth={4}
  //         stroke="#4DF8BA"
  //         strokeDasharray={SVG_DASHARRAY_MAX}
  //         strokeDashoffset={SVG_DASHARRAY_MAX}
  //         style={{
  //           animation: "progress 1s ease-out forwards",
  //         }}
  //       />
  //       <text
  //         x={10}
  //         y={58}
  //         fill="black"
  //         style={{
  //           fontSize: "3px",
  //           fontFamily: "'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
  //           fontWeight: 700,
  //           opacity: "60%",
  //           letterSpacing: "-0.02em",
  //           textalign: "center",
  //         }}
  //       >
  //         Barely <br /> Practising
  //       </text>

  //       <image x={35} y={15} href={progressbarimg} />
  //       {/* <Progressbarimg x={35} y={15} /> */}
  //       <text
  //         x={90}
  //         y={58}
  //         fill="black"
  //         style={{
  //           fontSize: "3px",
  //           fontFamily: "'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
  //           fontWeight: 700,
  //           opacity: "60%",
  //           letterSpacing: "-0.02em",
  //           textalign: "center",
  //         }}
  //       >
  //         Practising Alot!
  //       </text>
  //     </svg>
  //   );
  // };

  // let plan;
  // switch (user.user_plan) {
  //   case "1-Month Plan":
  //     plan = "500 Per Month";
  //     break;
  //   case "3-Month Plan":
  //     plan = "1200 Quaterly";
  //     break;
  //   case "6-Month Plan":
  //     plan = "2500 Bi-Annually";
  //     break;
  //   case "1-Year Plan":
  //     plan = "5000 Annually";
  //     break;
  //   default:
  //     break;
  // }

  let location = useLocation();
  let from = location.state

  useEffect(() => {
    if (from === 'login') {
      setModalShow(true)
    }
    blogpost(setBlog)
    dashboard(setData, days, setDays);
    profile();
    allyear();
  }, [dashboard, profile, from, blogpost, days]);

  return (
    <div className="dashboardcontent">
      {/* <h6 className="dashboardtitle">User Activity</h6> */}
      <div className="filterbox filterbox1 pb-4">
        <Form.Select
          defaultValue="Specify Exam Type"
          className="selectbox"
          onChange={filterByExam}
        >
          <option>Specify Exam Type</option>
          <option value="JAMB - UTME">JAMB - UTME</option>
          <option value="WAEC - SSCE">WAEC - SSCE</option>
          <option value="WAEC - GCE">WAEC - GCE</option>
          <option value="NECO">NECO</option>
          <option value="NOUN">NOUN</option>
        </Form.Select>
        <Form.Select
          defaultValue="Select Subject"
          className="selectbox"
          onChange={(e) => {
            setParam({
              ...param,
              subject_id:
                e.target.value === "Select Subject" ? "" : e.target.value,
            });
          }}
        >
          <option>
            {examLoading ? "Loading Subject ..." : "Select Subject"}
          </option>
          {Object.entries(subject).map(([key, value]) => {
            return (
              <option key={key} value={key}>
                {value}
              </option>
            );
          })}
        </Form.Select>
        <Form.Select
          defaultValue="Select year"
          className="selectbox selectbox1 selectboxmobile"
          onChange={(e) => {
            setParam({
              ...param,
              year: e.target.value === "Select year" ? "" : e.target.value,
            });
          }}
        >
          <option>Select year</option>
          {years.map((year, index) => {
            return (
              <option key={index} value={year}>
                {year}
              </option>
            );
          })}
        </Form.Select>
        <button
          className="searchbutton searchbutton1"
          onClick={getfilteredexam}
        >
          <img
            src={searchicon}
            alt="searchicon"
            onClick={getfilteredexam}
          />
        </button>
      </div>
      <div className="dashboardboxcontainer update-dashboardboxcontainer">
        <div className=" dashboardbox update-dashboardbox">
          <h6 className="text-light">Overall Score</h6>
          <div className="text-center">
            <Progressbarimg />
          </div>
          <div>
            <div className="overal-total">
              <span><span></span>Total Attempts</span>
              <span>{data.total_attempts}</span>
            </div>
            <div className="overal-total total-score border-0">
              <span><span></span>Total Score</span>
              <span>{data.highest_score}</span>
            </div>
          </div>
        </div>
        <div className="dashboardbox update-dashboardbox">
          <h6>Study Stats</h6>
          <p>*The number represents how many times you’ve passed an exam everyday</p>
          <div>
            <Carousel>
              <Carousel.Item>
                <div className="d-flex justify-content-between">
                  <div className="daycard">
                    <p>Mon</p>
                    <p>{days[1] === '' ? 0 : days[1]}</p>
                  </div>
                  <div className="daycard">
                    <p>Tue</p>
                    <p>{days[2] === '' ? 0 : days[2]}</p>
                  </div>
                  <div className="daycard">
                    <p>Wed</p>
                    <p>{days[3] === '' ? 0 : days[3]}</p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="d-flex justify-content-between ">
                  <div className="daycard">
                    <p>Thu</p>
                    <p>{days[4] === '' ? 0 : days[4]}</p>
                  </div>
                  <div className="daycard">
                    <p>Fri</p>
                    <p>{days[5] === '' ? 0 : days[5]}</p>
                  </div>
                  <div className="daycard">
                    <p>Sat</p>
                    <p>{days[6] === '' ? 0 : days[6]}</p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="d-flex justify-content-between">
                  <div className="daycard">
                    <p>Sun</p>
                    <p>{days[0] === '' ? 0 : days[0]}</p>
                  </div>
                  
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="stat-info">
            <span>
              2%
            </span>
            <span>
              You’ve passed 2% exams more this week!
            </span>
          </div>
        </div>
        <div className=" dashboardbox update-dashboardbox">
          <div className="text-center dash-img">
            <img src={sypimage} alt="practiceimage" />
          </div>
          <div className="text-center">
            <Link to='/availableexam' className="dash-practice">
              Practise Now
            </Link>
          </div>
        </div>
        {/* <div className="">
          <ProgressBar />
        </div> */}
      </div>
      <div className="shortcutcontainer">
        <div className="shortcut-filter">
          <div className="news"><span></span>News & Articles of the week!</div>
          <Link to='/blog'>
            See more
          </Link>
        </div>
        <div className="shortcutboxcontainer">
          {
            blog.slice(0,3).map((post, index) => {
              return (
                <div key={index} className="shortcutbox update-shortcutbox">
            <div className="shortcutboximg">
              <img src={praticeimg} alt="practiceimage" />
            </div>
            <h6>{post.data_values.title}</h6>
            <div className="d-flex justify-content-between">
              <div className="newsicons">
                <div>
                  <img src={eye} alt="views" />
                </div>
                <span>400 Views</span>
              </div>
              <div className="newsicons">
                <div>
                  <img src={dateicon} alt="date" />
                </div>
                <span>Posted 26/06/23</span>
              </div>
              <div className="newsicons">
                <div>
                  <img src={readicon} alt="views" />
                </div>
                <Link to="/singleblog" onClick={() => localStorage.setItem('postid', post.id)}>Read more</Link>
              </div>
            </div>
          </div>
              )
            })
          }
          {/* <div className="shortcutbox update-shortcutbox">
            <div className="shortcutboximg">
              <img src={praticeimg} alt="practiceimage" />
            </div>
            <h6>WAEC Bans The Use of Calculators?</h6>
            <div className="d-flex justify-content-between">
              <div className="newsicons">
                <div>
                  <img src={eye} alt="views" />
                </div>
                <span>400 Views</span>
              </div>
              <div className="newsicons">
                <div>
                  <img src={dateicon} alt="date" />
                </div>
                <span>Posted 26/06/23</span>
              </div>
              <div className="newsicons">
                <div>
                  <img src={readicon} alt="views" />
                </div>
                <Link>Read more</Link>
              </div>
            </div>
          </div>
          <div className="shortcutbox update-shortcutbox">
            <div className="shortcutboximg">
              <img src={praticeimg2} alt="practiceimage" />
            </div>
            <h6>Un scholarships</h6>
            <div className="d-flex justify-content-between">
              <div className="newsicons">
                <div>
                  <img src={eye} alt="views" />
                </div>
                <span>400 Views</span>
              </div>
              <div className="newsicons">
                <div>
                  <img src={dateicon} alt="date" />
                </div>
                <span>Posted 26/06/23</span>
              </div>
              <div className="newsicons">
                <div>
                  <img src={readicon} alt="views" />
                </div>
                <Link>Read more</Link>
              </div>
            </div>
          </div>
          <div className="shortcutbox update-shortcutbox">
            <div className="shortcutboximg">
              <img src={praticeimg} alt="practiceimage" />
            </div>
            <h6>The perfect Night lamp</h6>
            <div className="d-flex justify-content-between">
              <div className="newsicons">
                <div>
                  <img src={eye} alt="views" />
                </div>
                <span>400 Views</span>
              </div>
              <div className="newsicons">
                <div>
                  <img src={dateicon} alt="date" />
                </div>
                <span>Posted 26/06/23</span>
              </div>
              <div className="newsicons">
                <div>
                  <img src={readicon} alt="views" />
                </div>
                <Link>Read more</Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Welcome show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}

export default Dashboard;
