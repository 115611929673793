import { useState } from 'react'
import axios from 'axios'
import { Cookies } from 'react-cookie';
import { API } from '../helper/action';

export const useTicket = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [successmessage, setSuccessmessage] = useState(null)
    const cookies = new Cookies();

    const ticket = async (data) => {
        setIsLoading(true)
        setError(null)

        console.log(data)

        let token = cookies.get("jwt")
       await axios.post(`${API}/user/tickets`, data ,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            const user = response.data.message
            setSuccessmessage(user)
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
            setError(err.response.data.message)
            // console.log(err.response.data.errors.username)
        })
    }
    
    return { ticket, isLoading, error, successmessage}
}
