import axios from "axios";
import { useCallback, useState } from "react";
import { Cookies } from "react-cookie";
import { API } from "../helper/action";

export const useProfile = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [pickedinterest, setPickedInterest] = useState([])
    // const [pickedinterest, setInterest] = useState([])
    const [interest, setInterest] = useState({
        "JAMB - UTME": false,
        "WAEC - SSCE": false,
        "WAEC - GCE": false,
        NECO: false,
        NOUN: false,
        NURSING: false,
        IETLS: false,
      });

    const cookies = new Cookies()
    let token = cookies.get("jwt");

    const maxAge = 1 * 24 * 60 * 60

    const profile = useCallback(
        async () => {
            setIsLoading(true);
            setError(null);
            const cookies = new Cookies()
            let token = cookies.get("jwt");
            await axios
                .get(`${API}/user/fetch/profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    setIsLoading(false);
                    let user = {
                        firstname: response.data.data.user_profile.firstname,
                        lastname: response.data.data.user_profile.lastname,
                        username: response.data.data.user_profile.username,
                        email: response.data.data.user_profile.email,
                        image: response.data.data.user_profile.image,
                        mobile: response.data.data.user_profile.mobile,
                        address: response.data.data.user_profile.address.address,
                        state: response.data.data.user_profile.address.state,
                        zip: response.data.data.user_profile.address.zip,
                        country: response.data.data.user_profile.address.country,
                        city: response.data.data.user_profile.address.city,
                        user_plan: response.data.data.user_plan,
                        referral: response.data.data.user_profile.referral_id
                    }
                    user = btoa(JSON.stringify(user))
                    // console.log(user);

                    cookies.set("user", user, { path: '/', maxAge: maxAge, sameSite: 'lax', secure: true });

                })
                .catch((err) => {
                    setIsLoading(false);
                    setError(err);
                });
        },
        [maxAge]
    );

    const fetchinterest = useCallback(
        async (pickedinterest) => {
            setIsLoading(true);
            setError(null);
            await axios
                .get(`${API}/user/fetch/interests`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    setIsLoading(false);
                    setPickedInterest(response.data.data.Categories)
                    response.data.data.Categories.map(interests => interest[interests.name] = true)
                    response.data.data.Categories.map(interests => pickedinterest[interests.name] = true)
                })
                .catch((err) => {
                    setIsLoading(false);
                    setError(err);
                });
        }, [token, interest]
    );

    return { profile, isLoading, error, fetchinterest, pickedinterest, interest, setInterest };
};
