import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ReactPaginate from "react-paginate";
import { Cookies } from "react-cookie";

import "../stylesheets/Mockexam.css";

import Loader from "../Components/Loader";
import searchicon from "../assets/icons/Combined-Shape.svg";
import { ReactComponent as Nexticon } from "../assets/icons/nextarrw.svg";
import { ReactComponent as Previcon } from "../assets/icons/prevarrw.svg";
import { useCommunitymockexam } from "../hook/communitymockexam";
import { useGeneratmockexam } from "../hook/generatemockexam";
import { usePastquestion } from "../hook/pastquestion";
// import { useExamquestion } from '../hook/examquestion';

function Communitymockexam() {
  const [examid, setExamid] = useState({
    exam: "",
    subject: "",
    name: "",
    exam_type: "",
  });
  const [availableexam, setAvailableexam] = useState([]);
  const [totalcount, setTotalcount] = useState([]);
  const { communitymockexam, isLoading, communitymockexam2, filteredexam, filteredexam2, total } =
    useCommunitymockexam();
  const cookies = new Cookies();
  const [filtereddata, setFiltereddata] = useState([]);
  const [subject, setSubject] = useState([]);
  const [years, setYears] = useState([]);
  const { examtype, examLoading } = useGeneratmockexam();
  const [itemOffset, setItemOffset] = useState(0);
  const { Category, categories } = usePastquestion()
  const [param, setParam] = useState({
    category_id: "",
    year: "",
    subject_id: "",
  });

  const maxAge = 1 * 2 * 60 * 60;

  const getexamquestion = async (e) => {
    const exam_info = btoa(JSON.stringify(examid));
    cookies.set("id", exam_info, {
      path: "/",
      maxAge: maxAge,
      sameSite: "lax",
      secure: true,
    });
  };

  const year = function yearlist(startYear = 1985) {
    const endDate = new Date().getFullYear();
    let years = [];

    while (startYear <= endDate) {
      years.push(startYear);
      startYear++;
    }
    setYears(years);
  };

  const filterByExam = async (e) => {
    setParam({...param, category_id: e.target.value === 'Specify Exam Type' ? '' : e.target.value})
    await examtype(e.target.value, setSubject);
  };

  const getfilteredexam = async (e) => {
    e.preventDefault();
    await filteredexam(setFiltereddata, param);
  };

  const itemsPerPage = 6;
  const endOffset = itemOffset + itemsPerPage;
  const PageCount = Math.ceil(totalcount.total / itemsPerPage) || 0;
  const PageCount1 = Math.ceil(filtereddata.length / itemsPerPage);

  useEffect(() => {
    communitymockexam(setAvailableexam, setTotalcount);
    year();
    Category();
  }, [communitymockexam, Category]);

  const pagenumber = availableexam.length / totalcount.per_page + 1;
  const handlePageClick = async (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalcount.total;
    const setnewOffset = newOffset + itemsPerPage;
    const remainitem = availableexam.length - setnewOffset;
    if (remainitem < itemsPerPage) {
      await communitymockexam2(setAvailableexam, availableexam, pagenumber);
    }
    setItemOffset(newOffset);
  };

  const pagenumber1 = filtereddata.length / total.per_page + 1;
  const handlePageClick1 = async (event) => {
    const newOffset = (event.selected * itemsPerPage) % total.total;
    const setnewOffset = newOffset + itemsPerPage;
    const remainitem = filtereddata.length - setnewOffset;
    if (remainitem < itemsPerPage && filtereddata.length !== total.total) {
      await filteredexam2(setFiltereddata,param, filtereddata, pagenumber1);
    }
    setItemOffset(newOffset);
  };

  function convertDate(date) {
    const fullyear = new Date(date);
    let year = fullyear.getFullYear();
    let month = fullyear.getMonth() + 1;
    let day = fullyear.getDate();
    day = day.toString().padStart(2, "0");

    month = month.toString().padStart(2, "0");
    const formattedToday = day + "/" + month + "/" + year;
    return formattedToday;
  }

  const filteredpost = filtereddata
    .slice(itemOffset, endOffset)
    .map((post, index) => {
      return (
        <div className="mockexambox" key={index}>
          <h5>{post.subject.name}</h5>
          <div>
            <p className="text-start">
              Subject :{" "}
              <span className="text-capitalize">{post.subject.slug}</span>
            </p>
            <p className="text-start">
              Date: <span>{convertDate(post.created_at)}</span>
            </p>
          </div>
          <div>
            <p>
              Type : <span>OBJ</span>
            </p>
            <p>
              Exam: <span>{post.subject.category.name}</span>
            </p>
          </div>
          <div>
            <p>
              Year:{" "}
              <span>
                {post.from_year} - {post.to_year}{" "}
              </span>
            </p>

            <p>
              Status: <span>Success</span>
            </p>
          </div>
          <div className="justify-content-center pt-3">
            <Link
              to="/exam-questions"
              onMouseOver={() =>
                setExamid({
                  ...examid,
                  exam: post.id,
                  subject: post.subject.slug,
                  name: post.subject.name,
                  exam_type: post.subject.category.name,
                })
              }
              onTouchStart={() =>
                setExamid({
                  ...examid,
                  exam: post.id,
                  subject: post.subject.slug,
                  name: post.subject.name,
                  exam_type: post.subject.category.name,
                })
              }
              onClick={getexamquestion}
            >
              Practise exam
            </Link>
          </div>
        </div>
      );
    });

  const displayPosts = availableexam
    .slice(itemOffset, endOffset)
    .map((post, index) => {
      return (
        <div className="mockexambox" key={index}>
          <h5>{post.subject.name}</h5>
          <div>
            <p className="text-start">
              Subject :{" "}
              <span className="text-capitalize">{post.subject.slug}</span>
            </p>
            <p className="text-start">
              Date: <span>{convertDate(post.created_at)}</span>
            </p>
          </div>
          <div>
            <p>
              Type : <span>OBJ</span>
            </p>
            <p>
              Exam: <span>{post.subject.category.name}</span>
            </p>
          </div>
          <div>
            <p>
              Year:{" "}
              <span>
                {post.from_year} - {post.to_year}{" "}
              </span>
            </p>

            <p>
              Status: <span>Success</span>
            </p>
          </div>
          <div className="justify-content-center pt-3">
            <Link
              to="/exam-questions"
              onMouseOver={() =>
                setExamid({
                  ...examid,
                  exam: post.id,
                  subject: post.subject.slug,
                  name: post.subject.name,
                  exam_type: post.subject.category.name,
                })
              }
              onTouchStart={() =>
                setExamid({
                  ...examid,
                  exam: post.id,
                  subject: post.subject.slug,
                  name: post.subject.name,
                  exam_type: post.subject.category.name,
                })
              }
              onClick={getexamquestion}
            >
              Practise exam
            </Link>
          </div>
        </div>
      );
    });

  return (
    <div className="mt-4">
      <div>
        <div className="filterbox filterbox1">
          <Form.Select
            className="selectbox selectbox1"
            onChange={filterByExam}
            defaultValue="Specify Exam Type"
          >
             <option value='Specify Exam Type'>Specify Exam Type</option>
              {categories.map((category, index) => {
                return (
                  <option value={category.id} key={index}>{category.name}</option>
                )
              })}
          </Form.Select>
          <Form.Select
            className="selectbox"
            defaultValue="Select Subject"
            onChange={(e) => {
              setParam({
                ...param,
                subject_id:
                  e.target.value === "Select Subject" ? "" : e.target.value,
              });
            }}
          >
            <option>
              {examLoading ? "Loading Subject ..." : "Select Subject"}
            </option>
            {Object.entries(subject).map(([key, value]) => {
              return (
                <option key={key} value={key}>
                  {value}
                </option>
              );
            })}
          </Form.Select>
          <Form.Select
            className="selectbox"
            onChange={(e) => {
              setParam({
                ...param,
                year: e.target.value === "Select year" ? "" : e.target.value,
              });
            }}
            defaultValue="Select year"
          >
            <option>Select year</option>
            {years.map((year, index) => {
              return (
                <option key={index} value={year}>
                  {year}
                </option>
              );
            })}
          </Form.Select>
          <button className="searchbutton searchbutton1" onClick={getfilteredexam}>
            <img src={searchicon} alt="searchicon" />
          </button>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="mockexamgrid">
          {filtereddata.length !== 0 ? filteredpost : displayPosts}
        </div>
      )}
      <div className="mt-4 paginate">
        {filtereddata.length !== 0 ? (
          <ReactPaginate
            breakLabel="..."
            previousLabel={<Previcon />}
            nextLabel={<Nexticon />}
            pageCount={PageCount1}
            onPageChange={handlePageClick1}
            containerClassName={"Paginationbtn"}
            previousLinkClassName={"prevbtn"}
            pageClassName={"pageli"}
            nextLinkClassName={"nextbtn"}
            disabledClassName={"paginationdisabled"}
            activeClassName={"paginationactive"}
            breakClassName={"breakli"}
            renderOnZeroPageCount={null}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
          />
        ) : (
          <ReactPaginate
            breakLabel="..."
            previousLabel={<Previcon />}
            nextLabel={<Nexticon />}
            pageCount={PageCount}
            onPageChange={handlePageClick}
            containerClassName={"Paginationbtn"}
            previousLinkClassName={"prevbtn"}
            pageClassName={"pageli"}
            nextLinkClassName={"nextbtn"}
            disabledClassName={"paginationdisabled"}
            activeClassName={"paginationactive"}
            breakClassName={"breakli"}
            renderOnZeroPageCount={null}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
          />
        )}

        {/* <p>
          {isLoading
            ? ""
            : ` Results: ${pageindex === 0 ? "1" : pageindex} - ${
                pagelist === 0
                  ? examperpage
                  : pagelist < examperpage
                  ? availableexam.length
                  : pagelist
              } of ${availableexam.length}`}
        </p> */}
      </div>
    </div>
  );
}

export default Communitymockexam;
