import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useLogout } from "../hook/useLogout";

import "../stylesheets/Dashboardlayout.css";
import bluelogo from "../assets/icons/examsbluelogo.svg";
import { ReactComponent as Bluelogo } from "../assets/icons/updateicon.svg";
import { ReactComponent as Sidebarmenu } from "../assets/icons/sidebarmenuicon.svg";
import { ReactComponent as Dashboardicon } from "../assets/icons/dashboardicon.svg";
import { ReactComponent as Practiceicon } from "../assets/icons/practiceicon.svg";
import { ReactComponent as Referralicon } from "../assets/icons/referralicon.svg";
// import { ReactComponent as Mockexamicon } from "../assets/icons/mockexamicon.svg";
import { ReactComponent as Subscriptionicon } from "../assets/icons/subscriptionicon.svg";
import { ReactComponent as Trackericon } from "../assets/icons/trackericon.svg";
import { ReactComponent as Transactionhistoryicon } from "../assets/icons/thicon.svg";
import { ReactComponent as Supporticon } from "../assets/icons/supporticon.svg";
import { ReactComponent as Profilesettingsicon } from "../assets/icons/psicon.svg";
import { ReactComponent as Logouticon } from "../assets/icons/logouticon.svg";
import { ReactComponent as Dropdownicon } from "../assets/icons/dropdownicon.svg";
import Practice from "./Practice";
import Dashboard from "./Dashboard";
import Examhistory from "./Examhistory";
import Mockexam from "./Mockexam";
import Communitymockexam from "./Communitymockexam";
import Mockexamcompetition from "./Mockexamcompetition";
import Subscriptions from "./Subscriptions";
import Transactionhistory from "./Transactionhistory";
import Support from "./Support";
import Profilesettings from "./Profilesettings";
import Generatemockexam from "./Generatemockexam";
import { Cookies } from "react-cookie";
import Leaderboard from "./Leaderboard";
import Examcorrection from "./Examcorrection";
import Pointtracker from "./Pointtracker";
import Pointshistory from "./Pointshistory";
import Questionandanswer from "./QuestionandAnswer";
import Referral from "./Referral";

function Dashboardlayout() {
  const [sidebar, setSidebar] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [mockdropdown, setMockropdown] = useState(false);
  const [pointdropdown, setPointdropdown] = useState(false);
  const [mobilesidebar, setMobilesidebar] = useState(false);
  const { logout } = useLogout();
  const cookies = new Cookies();
  let user = cookies.get("user");
  user = JSON.parse(atob(user))

  const handlelogout = () => {
    logout();
  };

  const handleclick = () => {
    setSidebar(!sidebar);
  };

  const dropdownclick = () => {
    setDropdown(!dropdown);
    setMockropdown(false);
  };

  const mockdropdownclick = () => {
    // setMockropdown(!mockdropdown);
    // setDropdown(false);
   let f = mockdropdown
   return f
  };

  mockdropdownclick()

  const pointdropdownclick = () => {
    setPointdropdown(!pointdropdown);
    setDropdown(false);
    setMockropdown(false);
  }

  const closedropdown = () => {
    const element = document.getElementsByClassName("dropdownmenu");
    if (element) {
      setDropdown(false);
      setMockropdown(false);
      setPointdropdown(false)
    }
    // setDropdown(false)
  };

  const location = useLocation();
  let pagename = location.pathname;
  pagename = pagename.slice(1);

  let navbarpage;
  switch (pagename) {
    case "dashboard":
      navbarpage = "Dashboard";
      break;
    case "availableexam":
      navbarpage = "Available Exams";
      break;
    case "examhistory":
      navbarpage = "Practise history";
      break;
    case "mockexam":
      navbarpage = "My Mock Exams";
      break;
    case "generatemockexam":
      navbarpage = "Generate Mock Exams";
      break;
    case "communitymockexam":
      navbarpage = "Community Mock Exams";
      break;
    case "mockexamcompetition":
      navbarpage = "Mock Exams competition";
      break;
    case "leaderboard":
      navbarpage = "Competition Leaderboard";
      break;
    case "perfomance":
      navbarpage = "Exam Corrections";
      break;
    case "referral":
      navbarpage = "Referral";
      break;
    case "questionandanswer":
      navbarpage = "Question and Answer";
      break;
    case "subscriptions":
      navbarpage = "Subscription";
      break;
    case "transactionhistory":
      navbarpage = "Transaction History";
      break;
    case "support":
      navbarpage = "Support";
      break;
    case "point-tracker":
      navbarpage = "Point Tracker";
      break;
    case "pointhistory":
      navbarpage = "Point History";
      break;
    case "settings":
      navbarpage = "My Profile";
      break;
    default:
      navbarpage = "Dashboard";
  }

  return (
    <div>
      <div className={` ${sidebar ? "sidbarcollapse" : "dashboardlayout"}`}>
        <div className="sidebar">
          <div>
            {/* <img src={bluelogo} alt="bluelogo" /> */}
            <Bluelogo />
            <span onClick={handleclick}>
              <Sidebarmenu />
            </span>
          </div>
          <div className="sidebarlist">
            <Nav defaultActiveKey="/dashboard" className="flex-column">
              <div
                className={`nav-link ${pagename === "dashboard" ? "active" : ""
                  }`}
              >
                <Link to="/dashboard" className="sidebarname">
                  <Dashboardicon
                    className="dashboardiconclass"
                    fill="rgba(255, 255, 255, 0.6);"
                  />
                  <span
                    className={`sn sna ${sidebar ? "d-none" : ""}`}
                    onClick={closedropdown}
                  >
                    Dashboard
                  </span>
                </Link>
              </div>
              <div
                className={`nav-link ${dropdown ? "active" : ""}`}
                onClick={dropdownclick}
              >
                <Link className={` ${sidebar ? "dropdownbox" : "sidebarname"}`}>
                  <Practiceicon
                    className="dashboardiconclass"
                    fill="rgba(255, 255, 255, 0.6);"
                  />
                  <span className="sn">Practise</span>
                  <Dropdownicon
                    className="dashboardiconclass dropdownicon"
                    fill="rgba(255, 255, 255, 0.6);"
                  />
                </Link>
              </div>
              <span
                className={`check ${dropdown ? "dropdownmenu" : "nodropdownmenu"
                  }`}
              >
                <div
                  className={`dropdownmenutab nav-link ${pagename === "availableexam" ? "active" : ""
                    }`}
                >
                  <Link to="/availableexam" className="sn">
                    Available Exams
                  </Link>
                </div>
                <div
                  className={`dropdownmenutab nav-link ${pagename === "examhistory" ? "active" : ""
                    }`}
                >
                  <Link to="/examhistory" className="sn">
                    Practise History
                  </Link>
                </div>
              </span>
              {/* <div
                className={`nav-link ${mockdropdown ? "active" : ""}`}
                onClick={mockdropdownclick}
              >
                <Link className={` ${sidebar ? "dropdownbox" : "sidebarname"}`}>
                  <Mockexamicon
                    className="dashboardiconclass"
                    fill="rgba(255, 255, 255, 0.6);"
                  />
                  <span className="sn">Mock Exams</span>
                  <Dropdownicon
                    className="dashboardiconclass dropdownicon"
                    fill="rgba(255, 255, 255, 0.6);"
                  />
                </Link>
              </div>
              <span
                className={`check ${mockdropdown ? "dropdownmenu dropdownmenu2" : "nodropdownmenu"
                  }`}
              >
                <div
                  className={`dropdownmenutab1 dropdownmenutab nav-link ${pagename === "mockexam" ? "active" : ""
                    }`}
                >
                  <Link to="/mockexam" className="sn">
                    My Mock Exams
                  </Link>
                </div>
                <div
                  className={`dropdownmenutab1 dropdownmenutab nav-link ${pagename === "generatemockexam" ? "active" : ""
                    }`}
                >
                  <Link to="/generatemockexam" className="sn">
                    Generate Mock Exams
                  </Link>
                </div>
                <div
                  className={`dropdownmenutab1 dropdownmenutab nav-link ${pagename === "communitymockexam" ? "active" : ""
                    }`}
                >
                  <Link to="/communitymockexam" className="sn">
                    Community Mock Exams
                  </Link>
                </div>
                <div
                  className={`dropdownmenutab1 dropdownmenutab nav-link ${pagename === "mockexamcompetition" ? "active" : ""
                    }`}
                >
                  <Link to="/mockexamcompetition" className="sn">
                    Mock Exams competition
                  </Link>
                </div>
              </span> */}
              <div
                className={`nav-link ${pagename === "subscriptions" ? "active" : ""
                  }`}
              >
                <Link to="/subscriptions" className="sidebarname">
                  <Subscriptionicon
                    className="dashboardiconclass"
                    fill="rgba(255, 255, 255, 0.6);"
                  />
                  <span
                    className={`sn ${sidebar ? "d-none" : ""}`}
                    onClick={closedropdown}
                  >
                    Subscriptions
                  </span>
                </Link>
              </div>
              <div
                className={`nav-link ${pagename === "transactionhistory" ? "active" : ""
                  }`}
              >
                <Link to="/transactionhistory" className="sidebarname">
                  <Transactionhistoryicon
                    className="dashboardiconclass"
                    fill="rgba(255, 255, 255, 0.6);"
                  />
                  <span
                    className={`sn ${sidebar ? "d-none" : ""}`}
                    onClick={closedropdown}
                  >
                    Transaction History
                  </span>
                </Link>
              </div>
              <div
                className={`nav-link ${pagename === "support" ? "active" : ""}`}
              >
                <Link to="/support" className="sidebarname">
                  <Supporticon
                    className="dashboardiconclass"
                    fill="rgba(255, 255, 255, 0.6);"
                  />
                  <span
                    className={`sn ${sidebar ? "d-none" : ""}`}
                    onClick={closedropdown}
                  >
                    Support
                  </span>
                </Link>
              </div>
              <div
                className={`nav-link ${pointdropdown ? "active" : ""}`}
                onClick={pointdropdownclick}
              >
                <Link className={` ${sidebar ? "dropdownbox" : "sidebarname"}`}>
                  <Trackericon
                    className="dashboardiconclass dashboardiconclass2"
                    fill="rgba(255, 255, 255, 0.6);"
                  />
                  <span className="sn">Points</span>
                  <Dropdownicon
                    className="dashboardiconclass dropdownicon"
                    fill="rgba(255, 255, 255, 0.6);"
                  />
                </Link>
              </div>
              <span
                className={`check ${pointdropdown ? "dropdownmenu" : "nodropdownmenu"
                  }`}
              >
                <div
                  className={`dropdownmenutab nav-link ${pagename === "point-tracker" ? "active" : ""
                    }`}
                >
                  <Link to="/point-tracker" className="sn">
                    Points Tracker
                  </Link>
                </div>
                <div
                  className={`dropdownmenutab nav-link ${pagename === "pointhistory" ? "active" : ""
                    }`}
                >
                  <Link to="/pointhistory" className="sn">
                    Points History
                  </Link>
                </div>
              </span>
              <div
                className={`nav-link ${pagename === "referral" ? "active" : ""
                  }`}
              >
                <Link to="/referral" className="sidebarname">
                  <Referralicon
                    className="dashboardiconclass"
                    fill="rgba(255, 255, 255, 0.6);"
                  />
                  <span
                    className={`sn sna ${sidebar ? "d-none" : ""}`}
                    onClick={closedropdown}
                  >
                    Referrals
                  </span>
                </Link>
              </div>
              <div
                className={`nav-link ${pagename === "settings" ? "active" : ""
                  }`}
              >
                <Link to="/settings" className="sidebarname">
                  <Profilesettingsicon
                    className="dashboardiconclass"
                    fill="rgba(255, 255, 255, 0.6);"
                  />
                  <span
                    className={`sn ${sidebar ? "d-none" : ""}`}
                    onClick={closedropdown}
                  >
                    Profile Settings
                  </span>
                </Link>
              </div>
            </Nav>
          </div>
          <div className="logoutbox">
            <Link className="sidebarname" onClick={handlelogout}>
              <Logouticon
                className="dashboardiconclass"
                fill="rgba(255, 255, 255, 0.6);"
              />
              <span className={` ${sidebar ? "d-none" : ""}`}>Logout</span>
            </Link>
          </div>
        </div>
        <div
          className={` ${mobilesidebar ? "mobilesidebarshow" : "mobilesidebar"
            }`}
        >
          <Offcanvas
            show={mobilesidebar}
            onHide={() => setMobilesidebar(false)}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <img src={bluelogo} alt="bluelogo" />
                {/* <Bluelogo /> */}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="sidebarlist">
                <Nav defaultActiveKey="/dashboard" className="flex-column">
                  <div
                    className={`nav-link ${pagename === "dashboard" ? "active" : ""
                      }`}
                    onClick={(e) => setMobilesidebar(false)}
                  >
                    <Link to="/dashboard" className="sidebarname">
                      <Dashboardicon
                        className="dashboardiconclass"
                        fill="rgba(255, 255, 255, 0.6);"
                      />
                      <span
                        className={`sn sna ${sidebar ? "d-none" : ""}`}
                        onClick={closedropdown}
                      >
                        Dashboard
                      </span>
                    </Link>
                  </div>
                  <div
                    className={`nav-link ${pagename === "practice"
                        ? "active"
                        : dropdown
                          ? "active"
                          : ""
                      }`}
                    onClick={dropdownclick}
                  >
                    <Link
                      className={` ${sidebar ? "dropdownbox" : "sidebarname"}`}
                    >
                      <Practiceicon
                        className="dashboardiconclass"
                        fill="rgba(255, 255, 255, 0.6);"
                      />
                      <span className="sn" >
                        Practise
                      </span>
                      <Dropdownicon
                        className="dashboardiconclass dropdownicon"
                        fill="rgba(255, 255, 255, 0.6);"

                      />
                    </Link>
                  </div>
                  <span
                    className={`check ${dropdown ? "dropdownmenu" : "nodropdownmenu"}`}
                  >
                    <div
                      className={`dropdownmenutab nav-link ${pagename === "availableexam" ? "active" : ""
                        }`}
                      onClick={(e) => setMobilesidebar(false)}
                    >
                      <Link to="/availableexam" className="sn">
                        Available Exams
                      </Link>
                    </div>
                    <div
                      className={`dropdownmenutab nav-link ${pagename === "examhistory" ? "active" : ""
                        }`}
                      onClick={(e) => setMobilesidebar(false)}
                    >
                      <Link to="/examhistory" className="sn">
                        Practise History
                      </Link>
                    </div>
                  </span>
                  {/* <div
                    className={`nav-link ${pagename === "mockexam"
                        ? "active"
                        : mockdropdown
                          ? "active"
                          : ""
                      }`}
                    onClick={mockdropdownclick}
                  >
                    <Link
                      className={` ${sidebar ? "dropdownbox" : "sidebarname"}`}
                    >
                      <Mockexamicon
                        className="dashboardiconclass"
                        fill="rgba(255, 255, 255, 0.6);"
                      />
                      <span className="sn" >
                        Mock Exams
                      </span>
                      <Dropdownicon
                        className="dashboardiconclass dropdownicon"
                        fill="rgba(255, 255, 255, 0.6);"

                      />
                    </Link>
                  </div>
                  <span
                    className={`check ${mockdropdown ? "dropdownmenu dropdownmenu2" : "nodropdownmenu"
                      }`}
                  >
                    <div
                      className={`dropdownmenutab1 dropdownmenutab nav-link ${pagename === "mockexam" ? "active" : ""
                        }`}
                      onClick={(e) => setMobilesidebar(false)}
                    >
                      <Link to="/mockexam" className="sn">
                        My Mock Exams
                      </Link>
                    </div>
                    <div
                      className={`dropdownmenutab1 dropdownmenutab nav-link ${pagename === "generatemockexam" ? "active" : ""
                        }`}
                      onClick={(e) => setMobilesidebar(false)}
                    >
                      <Link to="/generatemockexam" className="sn">
                        Generate Mock Exams
                      </Link>
                    </div>
                    <div
                      className={`dropdownmenutab1 dropdownmenutab nav-link ${pagename === "communitymockexam" ? "active" : ""
                        }`}
                      onClick={(e) => setMobilesidebar(false)}
                    >
                      <Link to="/communitymockexam" className="sn">
                        Community Mock Exams
                      </Link>
                    </div>
                    <div
                      className={`dropdownmenutab1 dropdownmenutab nav-link ${pagename === "mockexamcompetition" ? "active" : ""
                        }`}
                      onClick={(e) => setMobilesidebar(false)}
                    >
                      <Link to="/mockexamcompetition" className="sn">
                        Mock Exams competition
                      </Link>
                    </div>
                  </span> */}
                  <div
                    className={`nav-link ${pagename === "subscriptions" ? "active" : ""
                      }`}
                  >
                    <Link to="/subscriptions" className="sidebarname">
                      <Subscriptionicon
                        className="dashboardiconclass"
                        fill="rgba(255, 255, 255, 0.6);"
                      />
                      <span
                        className={`sn ${sidebar ? "d-none" : ""}`}
                        onClick={closedropdown}
                      >
                        Subscriptions
                      </span>
                    </Link>
                  </div>
                  <div
                    className={`nav-link ${pagename === "transactionhistory" ? "active" : ""
                      }`}
                    onClick={(e) => setMobilesidebar(false)}
                  >
                    <Link to="/transactionhistory" className="sidebarname">
                      <Transactionhistoryicon
                        className="dashboardiconclass"
                        fill="rgba(255, 255, 255, 0.6);"
                      />
                      <span
                        className={`sn ${sidebar ? "d-none" : ""}`}
                        onClick={closedropdown}
                      >
                        Transaction History
                      </span>
                    </Link>
                  </div>
                  <div
                    className={`nav-link ${pagename === "support" ? "active" : ""
                      }`}
                    onClick={(e) => setMobilesidebar(false)}
                  >
                    <Link to="/support" className="sidebarname">
                      <Supporticon
                        className="dashboardiconclass"
                        fill="rgba(255, 255, 255, 0.6);"
                      />
                      <span
                        className={`sn ${sidebar ? "d-none" : ""}`}
                        onClick={closedropdown}
                      >
                        Support
                      </span>
                    </Link>
                  </div>
                  <div
                    className={`nav-link ${pointdropdown
                      ? "active"
                      : ""
                      }`}
                    onClick={pointdropdownclick}
                  >
                    <Link
                      className={` ${sidebar ? "dropdownbox" : "sidebarname"}`}
                    >
                      <Trackericon
                        className="dashboardiconclass"
                        fill="rgba(255, 255, 255, 0.6);"
                      />
                      <span className="sn" >
                        Point
                      </span>
                      <Dropdownicon
                        className="dashboardiconclass dropdownicon"
                        fill="rgba(255, 255, 255, 0.6);"

                      />
                    </Link>
                  </div>
                  <span
                    className={`check ${pointdropdown ? "dropdownmenu" : "nodropdownmenu"
                      }`}
                  >
                    <div
                      className={`dropdownmenutab nav-link ${pagename === "point-tracker" ? "active" : ""
                        }`}
                      onClick={(e) => setMobilesidebar(false)}
                    >
                      <Link to="/point-tracker" className="sn">
                        Points Tracker
                      </Link>
                    </div>
                    <div
                      className={`dropdownmenutab nav-link ${pagename === "pointhistory" ? "active" : ""
                        }`}
                      onClick={(e) => setMobilesidebar(false)}
                    >
                      <Link to="/pointhistory" className="sn">
                        Points History
                      </Link>
                    </div>
                  </span>
                  <div
                    className={`nav-link ${pagename === "referral" ? "active" : ""
                      }`}
                  >
                    <Link to="/referral" className="sidebarname">
                      <Referralicon
                        className="dashboardiconclass"
                        fill="rgba(255, 255, 255, 0.6);"
                      />
                      <span
                        className={`sn sna ${sidebar ? "d-none" : ""}`}
                        onClick={closedropdown}
                      >
                        Referrals
                      </span>
                    </Link>
                  </div>
                  <div
                    className={`nav-link ${pagename === "settings" ? "active" : ""
                      }`}
                    onClick={(e) => setMobilesidebar(false)}
                  >
                    <Link to="/settings" className="sidebarname">
                      <Profilesettingsicon
                        className="dashboardiconclass"
                        fill="rgba(255, 255, 255, 0.6);"
                      />
                      <span
                        className={`sn ${sidebar ? "d-none" : ""}`}
                        onClick={closedropdown}
                      >
                        Profile Settings
                      </span>
                    </Link>
                  </div>
                </Nav>
              </div>
              <div className="logoutbox">
                <Link to="" className="sidebarname" onClick={handlelogout}>
                  <Logouticon
                    className="dashboardiconclass"
                    fill="rgba(255, 255, 255, 0.6);"
                  />
                  <span className={` ${sidebar ? "d-none" : ""}`}>Logout</span>
                </Link>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <div
          className={` ${sidebar ? "maincontainerexpand" : "maincontainer"}`}
        >
          <div className="dashnav">
            <div className="mobilenavbar">
              <span onClick={() => setMobilesidebar(!mobilesidebar)}>
                <Sidebarmenu />
              </span>
              <h5>{navbarpage}</h5>
            </div>
            <h5>{navbarpage}</h5>
            <div className="dashnavaccount">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="navprofile">
                  {user.username}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    <Link className="dropdown-link" to='/settings'>
                      Change Password
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    <Link className="dropdown-link" to='/settings'>
                      Profile Settings
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    <Link className="dropdown-link" onClick={handlelogout}>
                      Logout
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="content">
            <Routes>
              <Route path="dashboard" index element={<Dashboard />} />
              <Route path="availableexam" exact element={<Practice />} />
              <Route path="examhistory" element={<Examhistory />} />
              <Route path="mockexam" element={<Mockexam />} />
              <Route path="communitymockexam" element={<Communitymockexam />} />
              <Route
                path="mockexamcompetition"
                element={<Mockexamcompetition />}
              />
              <Route path="generatemockexam" element={<Generatemockexam />} />
              <Route path="subscriptions" element={<Subscriptions />} />
              <Route path="leaderboard" element={<Leaderboard />} />
              <Route path="perfomance" element={<Examcorrection />} />
              <Route path="referral" element={<Referral />} />
              <Route path="questionandanswer" element={<Questionandanswer />} />
              <Route path="point-tracker" element={<Pointtracker />} />
              <Route path="pointhistory" element={<Pointshistory />} />
              <Route
                path="transactionhistory"
                element={<Transactionhistory />}
              />
              <Route path="support" element={<Support />} />
              <Route path="settings" element={<Profilesettings />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboardlayout;
