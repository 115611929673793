import React from "react";
import { Link } from "react-router-dom";

import "../stylesheets/Footer.css";

import footerlogo from "../assets/icons/footerlogo.svg";
import linkedinicon from "../assets/icons/linkedinicon.svg";
import twittericon from "../assets/icons/twittericon.svg";
import instagramicon from "../assets/icons/instagramicon.svg";
import facebookicon from "../assets/icons/facebookicon.svg";

function Footer() {
  return (
    <div>
      <div className="row footer">
        <div className="row justify-content-around m-0">
          <div className="col-lg-2 col-sm-3 col-auto">
            <h1>Main</h1>
            <Link to="/">Home</Link>
          </div>
          <div className="col-lg-2 col-sm-3 col-auto">
            <h1>Need Help</h1>
            <Link to="/contact" target="_top">
              Contact us
            </Link>
            <Link to="/f.a.q" target="_top">
              FAQ
            </Link>
          </div>
          <div className="col-lg-2 col-sm-3 col-auto">
            <h1>Features</h1>
            <Link to="/pastquestions" target="_top">
              Past Questions
            </Link>
          </div>
          <div className="col-lg-2 col-sm-3 footer-center">
            <h1>Article</h1>
            <Link to="/blog" target="_top">
              Blog
            </Link>
          </div>
        </div>
        <div className="row justify-content-center text-center m-0">
          <div className="col-lg-8 footer-logo">
            <div>
              <img src={footerlogo} alt="footerlogo" loading="lazy" />
            </div>
            <p>
              Welcome to our exam preparation website, where we help you achieve
              your academic goals by providing comprehensive study materials and
              resources. We understand that preparing for exams can be stressful
              and overwhelming. That's why we're here to guide you through the
              process and make it as smooth as possible.
            </p>
            <div>
              <a href="https://www.linkedin.com/company/exams-africa/" className="footer-icon" target='_blank' rel="noreferrer">
                <img src={linkedinicon} alt="linkedinicon" loading="lazy" />
              </a>
              <a href="#0" className="footer-icon" target='_blank' rel="noreferrer">
                <img src={twittericon} alt="twittericon" loading="lazy" />
              </a>
              <a href="https://www.instagram.com/examsafrica/" className="footer-icon" target='_blank' rel="noreferrer">
                <img src={instagramicon} alt="instagramicon" loading="lazy" />
              </a>
              <a href="#0" className="footer-icon" target='_blank' rel="noreferrer">
                <img src={facebookicon} alt="facebook" loading="lazy" />
              </a> 
            </div>
          </div>
        </div>
      </div>
      <div className="footer-end row">
        © Copyright 2023 All Rights Reserved by Velox Technology Resources Limited
      </div>
    </div>
  );
}

export default Footer;
