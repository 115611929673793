import './App.css';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'
import Home from './Pages/Home';
import Register from './Pages/Register';
import Login from './Pages/Login';
import Forgetpassword from './Pages/Forgetpassword';
import Emailconfirmation from './Pages/Emailconfirmation';
import Signupemailconfirmation from './Pages/signupemailconfirmation';
import Singleblog from './Pages/Singleblog';
import Resetpassword from './Pages/Resetpassword';
import Blog from './Pages/Blog';
import Faq from './Pages/Faq';
import ContactUs from './Pages/ContactUs';
import Pastquestions from './Pages/Pastquestions';
import Examquestion from './Pages/Examquestion';
import Resetcode from './Pages/Resetcode';
import Dashboardlayout from './Pages/Dashboardlayout';
import {PrivateRoutes} from './hook/protectedroutes'
import { useAuthContext } from './hook/context'
import Termscondition from './Pages/Termsandcondition';
import Privacy from './Pages/Privacy';



function App() {
  
  const {isAuth } = useAuthContext()
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route index exact element={<Home/>} />
          <Route path='/register' exact element={!isAuth ? <Register/> : <Navigate to='/dashboard'/>} />
          <Route path='/login' exact element={!isAuth ? <Login/> : <Navigate to='/dashboard'/>} />
          <Route path='/forgetpassword' exact element={<Forgetpassword/>} />
          <Route path='/emailconfirmation' exact element={<Emailconfirmation/>} />
          <Route path='/singleblog' exact element={<Singleblog/>} />
          <Route path='/emailverification' exact element={<Signupemailconfirmation/>} />
          <Route path='/resetpassword' exact element={<Resetpassword/>} />
          <Route path='/resetcode' exact element={<Resetcode />} />
          <Route path='/blog' exact element={<Blog/>} />
          <Route path='/f.a.q' exact element={<Faq/>} />
          <Route path='/contact' exact element={<ContactUs/>} />
          <Route path='/pastquestions' exact element={<Pastquestions/>} />
          <Route path='/exam-questions' exact element={<Examquestion/>} />
          <Route path='/termsandcondition' exact element={<Termscondition/>} />
          <Route path='/privacypolicy' exact element={<Privacy/>} />
          <Route element={<PrivateRoutes />}>
            <Route path='/*' exact element={<Dashboardlayout/>} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
