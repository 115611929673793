import React, { useCallback, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useSignup } from '../hook/useSignup'
import Spinner from 'react-bootstrap/Spinner';
// import countrycode from '../assets/country.json'
import PhoneInput from 'react-phone-number-input'

import '../stylesheets/Register.css'
import { ReactComponent as Logo } from '../assets/icons/examlogo2.svg'
// import { ReactComponent as Facebook } from '../assets/icons/facebookreg.svg'
import { ReactComponent as Google } from '../assets/icons/googlereg.svg'
import { ReactComponent as Email } from '../assets/icons/emailreg.svg'
import 'react-phone-number-input/style.css'
import {
  LoginSocialGoogle,
  // LoginSocialFacebook,
} from 'reactjs-social-login'

import footericonright from '../assets/icons/footericon-right.svg'
import footericonleft from '../assets/icons/footericon-left.svg'
import { ReactComponent as Eye } from '../assets/icons/eye.svg'
import { ReactComponent as Arrowicon } from '../assets/icons/arrowback.svg'
import { ReactComponent as Eyeslash } from '../assets/icons/eye-slash.svg'

function Register() {
  
  const [data, setData] = useState({
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    mobile: '',
    password: '',
    password_confirmation: ''
  })
  const [terms, setTerms] = useState(false)
  const [aggrement, setAggrement] = useState('')
  const [confirmpassword, setConfirmpassword] = useState('')
  const [registerstep, setRegisterstep] =  useState(false)
  const [view, setView] = useState(false)
  const [cview, setCview] = useState(false)
  const [show, setShow] = useState(false)
  const { signup, referal_signup, isLoading, errorusername, erroremail, errorfirstname, errorlastname, social_register } = useSignup()
  const [interest, setInterest] = useState({
    'UTME': false,
    'SSCE': false,
    "GCE": false,
    "NECO": false,
    "NOUN": false,
    "NURSING": false,
    // "IETLS": false,
  });
  const [provider, setProvider] = useState('')
  const [profile, setProfile] = useState(null)
  

  const onLoginStart = useCallback(() => {
    // alert('login start')
    // console.log(provider);
    // console.log(profile);
    let p = profile
    return p
  }, [profile])
  const [queryParameters] = useSearchParams()
  // const location = useLocation()
  // const params = new URLSearchParams(location.search)

  // console.log(queryParameters.get('ref'), params.get('ref'));
  // const onLogoutSuccess = useCallback(() => {
  //   setProfile(null)
  //   setProvider('')
  //   alert('logout success')
  // }, [])

  const passwordview = () => {
    const element = document.querySelector('#password')
    element.setAttribute("type", "text");
    setView(true)
  }

  const passwordhide = () => {
    const element = document.querySelector('#password')
    element.setAttribute("type", "password");
    setView(false)
  }

  const cpasswordview = () => {
    const element = document.querySelector('#confirmpassword')
    element.setAttribute("type", "text");
    setCview(true)
  }

  const cpasswordhide = () => {
    const element = document.querySelector('#confirmpassword')
    element.setAttribute("type", "password");
    setCview(false)
  }

  const checkpassword = (e) => {
    setData({ ...data, password_confirmation: e.target.value })
    if (data.password !== e.target.value) {
      setConfirmpassword('password dont match')
    } else {
      setConfirmpassword('')
    }
  }

  const setMobile = (value) => {
    setData(existingValues => ({
      // Retain the existing values
      ...existingValues,
      // update the lastName
      mobile: value,
    }))
  }


  const valuearray = Object.values(interest)
  const limitcheck = valuearray.filter(valuearray => valuearray === true)
  const handlechecked = (e) => {
    setInterest({ ...interest, [e.target.name]: e.target.checked })

    let examid;
    switch (e.target.name) {
      case "UTME":
        examid = "1";
        break;
      case "SSCE":
        examid = "2";
        break;
      case "GCE":
        examid = "3";
        break;
      case "NECO":
        examid = "4";
        break;
      case "NOUN":
        examid = "5";
        break;
      default: examid = "0";
    }
    if (e.target.checked) {
      data[`category_id[${examid}]`] = examid
    } else {
      setData(current => {
        const copy = { ...current };
        delete copy[`category_id[${examid}]`];
        return copy;
      });
    }
  }

  const handlesubmit = async (e) => {
    e.preventDefault()
    if (terms) {
  // const [queryParameters] = useSearchParams()
  let ref = queryParameters.get('ref')
      if(queryParameters.get('ref') === null) {
        await signup(data)
      } else {
        referal_signup(data, ref)
      }
      
    } else {
      setAggrement('Terms & condition not approved')
    }
  }

  return (
    <div className='main'>
      {/* Header */}
      <div className="row justify-content-between header">
        <div className="col-lg-2 col-sm-3 col-4">
          <Link to='/'>
            <Logo />
          </Link>
        </div>
        <div className="col-lg-3 header-right col-sm-4 col-6">
          <p>
            Already have an account? Login <Link to="/Login" aria-label='Login'>here</Link>
          </p>
        </div>
      </div>
      {/* Content */}
      <div className='row mainbody'>
        <div className='col-lg-2'>
          <h1 className='title register-title'>Create An Account</h1>
        </div>
        <div className='col-lg-8 pt-md-5 pt-lg-0 pt-4'>
          <div className={`row justify-content-center social-list-button ${registerstep ? 'd-none' : ''}`}>
            <div className='register-social-button'>
              <button onClick={() => setRegisterstep(true)}>
                <Email className='me-2'/>Sign Up with email
              </button>
            </div>
            {/*<LoginSocialFacebook*/}
            {/*  className="social-button register-social-button"*/}
            {/*  isOnlyGetToken={false}*/}
            {/*  appId='905156100582307'*/}
            {/*  onLoginStart={onLoginStart}*/}
            {/*  onResolve={({ provider, data }) => {*/}
            {/*    setProvider(provider)*/}
            {/*    setProfile(data)*/}
            {/*    // console.log(data);*/}
            {/*    const info = {*/}
            {/*      provider: provider,*/}
            {/*      email: data.email,*/}
            {/*      name: data.name*/}
            {/*    }*/}
            {/*    social_register(info)*/}
            {/*  }}*/}
            {/*  onReject={(err) => {*/}
            {/*    console.log(err)*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {*/}
            {/*    provider === 'facebook' && isLoading ? */}
            {/*    <div className='d-flex'>*/}
            {/*        <Spinner animation="border" role="status">*/}
            {/*      <span className="visually-hidden">Loading...</span>*/}
            {/*    </Spinner>*/}
            {/*    <p className='m-0 regload'>Loading...</p>*/}
            {/*      </div>*/}
            {/*    : */}
            {/*      <span><Facebook className='me-2'/>Sign up with Facebook</span>*/}
            {/*  }*/}
            {/*  */}
            {/*</LoginSocialFacebook>*/}

            <LoginSocialGoogle
              className="social-button register-social-button"
              client_id='1092396342303-98jlepi00ug694mae72bgcnm867rcnup.apps.googleusercontent.com'
              onLoginStart={onLoginStart}
              scope="https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
              onResolve={({ provider, data }) => {
                setProvider(provider)
                setProfile(data)
                const info = {
                  provider: provider,
                  email: data.email,
                  name: data.name
                }
                social_register(info)
              }}
              isOnlyGetToken={false}
              onReject={(err) => {
                console.log(err)
              }}
            >
              {
                provider === 'google' && isLoading ? 
                <div className='d-flex'>
                    <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p className='m-0 regload'>Loading...</p>
                  </div>
                : 
                  <span><Google className='me-2'/>Sign up with Google</span>
              }
            </LoginSocialGoogle>
              <p className="errormessage">{erroremail}</p>
          </div>
          <div className={`row justify-content-center ${registerstep ? '' : 'd-none'}`}>
            <form className='col-lg-6 col-sm-6 col-8' onSubmit={handlesubmit}>
              <div className={`${show ? 'd-none' : ''}`}>
                <div className='row '>
                  <div className='col-lg-6 input-half'>
                    <label>First Name</label>
                    <input type="text" name="firstname" onChange={(e) => setData({ ...data, firstname: e.target.value })} value={data.firstname} />
                    <p className='errormessage'>{errorfirstname === undefined ? '' : errorfirstname}</p>
                  </div>
                  <div className='col-lg-6 input-half-2'>
                    <label>Last Name</label>
                    <input type="text" name="lastname" onChange={(e) => setData({ ...data, lastname: e.target.value })} value={data.lastname} />
                    <p className='errormessage'>{errorlastname === undefined ? '' : errorlastname}</p>
                  </div>
                </div>
                <div className='pt-3'>
                  <label>Username</label>
                  <input type='text' name="username" onChange={(e) => setData({ ...data, username: e.target.value })} value={data.username} />
                  <p className='errormessage'>{errorusername === undefined ? '' : errorusername}</p>
                </div>
                <div className='pt-3'>
                  <label>Email Address</label>
                  <input type='email' name="email" onChange={(e) => setData({ ...data, email: e.target.value })} value={data.email} />
                  <p className='errormessage'>{erroremail === undefined ? '' : erroremail}</p>
                </div>
                {/* <div className='pt-3'>
                <label>Country</label>
                <select className='countryselect' id="country_select" onChange={(e) => {setCountry(e.target.value);   const index = e.target.selectedIndex;
                    const el = e.target.childNodes[index]
                    const option =  el.getAttribute('data-key'); setKey(option) }}>
                    {countrycode.map((country) => {
                        return <option key={country.code} data-key={country.code}  value={country.name}>{country.name}</option>
                      })}
                </select>
              </div> */}
                <div className='pt-3'>
                  <label>Mobile Number</label>
                  <PhoneInput
                    international
                    name="Enter phone number"
                    countryCallingCodeEditable={false}
                    defaultCountry='NG'
                    value={data.mobile}
                    onChange={setMobile} />
                </div>
                <div className='row pt-3'>
                  <div className='col-lg-6 input-half'>
                    <label>Password</label>
                    <div className='passwordinput'>
                      <input type="password" name="password" id='password' onChange={(e) => setData({ ...data, password: e.target.value })} value={data.password} autoComplete='current-password' />
                      <span onClick={passwordview} className={`${view ? 'd-none' : ''}`}><Eyeslash /></span>
                      <span onClick={passwordhide} className={`${view ? '' : 'd-none'}`}><Eye /></span>
                    </div>
                  </div>
                  <div className='col-lg-6 input-half-2'>
                    <label>Confirm Password</label>
                    <div className='passwordinput'>
                      <input type="Password" name="confirmpassword" id='confirmpassword' onChange={checkpassword} value={data.password_confirmation} />
                      <span onClick={cpasswordview} className={`${cview ? 'd-none' : ''}`}><Eyeslash /></span>
                      <span onClick={cpasswordhide} className={`${cview ? '' : 'd-none'}`}><Eye /></span>
                    </div>
                  </div>
                  <span className='errormessage text-start'>{confirmpassword}</span>
                </div>
              </div>
              <div className={`${show ? '' : 'd-none'}`}>
                <div className=''>
                  <Arrowicon onClick={() => { setShow(false) }} />
                </div>
                <p className='reg-instruction'>Kindly add and save the exams of your choice, these will enhance your Navigation seamlessly!</p>
                <div className="interest-form">
                  <div >
                    <label htmlFor='UTME'>UTME</label>
                    <input
                      type="checkbox"
                      name='UTME'
                      // value={(e) => e.target.checked ? 'true' : 'false'}
                      onChange={handlechecked}
                      disabled={interest.UTME ? false : limitcheck.length >= 3 ? true : false}
                    />
                  </div>
                  <div >
                    <label htmlFor='WAEC - SSCE'>WAEC - SSCE</label>
                    <input
                      type="checkbox"
                      name='SSCE'
                      // value={(e) => e.target.checked ? 'true' : 'false'}
                      onChange={handlechecked}
                      disabled={interest.SSCE ? false : limitcheck.length >= 3 ? true : false}
                    />
                  </div>
                  <div >
                    <label htmlFor='WAEC - GCE'>WAEC - GCE</label>
                    <input
                      type="checkbox"
                      name='GCE'
                      // value={(e) => e.target.checked ? 'true' : 'false'}
                      onChange={handlechecked}
                      disabled={interest.GCE ? false : limitcheck.length >= 3 ? true : false}
                    />
                  </div>
                  <div >
                    <label htmlFor='NECO'>NECO</label>
                    <input
                      type="checkbox"
                      name='NECO'
                      // value={(e) => e.target.checked ? 'true' : 'false'}
                      onChange={handlechecked}
                      disabled={interest.NECO ? false : limitcheck.length >= 3 ? true : false}
                    />
                  </div>
                  <div >
                    <label htmlFor='NOUN'>NOUN</label>
                    <input
                      type="checkbox"
                      name='NOUN'
                      // value={(e) => e.target.checked ? 'true' : 'false'}
                      onChange={handlechecked}
                      disabled={interest.NOUN ? false : limitcheck.length >= 3 ? true : false}
                    />
                  </div>
                  <div >
                    <label htmlFor='NURSING'>NURSING</label>
                    <input
                      type="checkbox"
                      name='NURSING'
                      // value={(e) => e.target.checked ? 'true' : 'false'}
                      onChange={handlechecked}
                      disabled={interest.NURSING ? false : limitcheck.length >= 3 ? true : false}
                    />
                  </div>
                  {/* <div >
                    <label htmlFor='IETLS'>IETLS</label>
                    <input
                      type="checkbox"
                      name='IETLS'
                      // value={(e) => e.target.checked ? 'true' : 'false'}
                      onChange={handlechecked}
                      disabled={interest.IETLS ? false : limitcheck.length >= 3 ? true : false}
                    />
                  </div> */}
                </div>
              </div>
              {
                show ? <div className='checkbox pt-2'>
                  <input type="checkbox" name="agreement" onChange={(e) => setTerms(!terms)} value={terms} />
                  <span>
                    By signing up, you’re agreeing to our <Link aria-label='Terms and condition' to='/termsandcondition'>terms & conditions</Link> and <Link aria-label='privacy policy' to='/privacypolicy'>privacy policy</Link>
                  </span>
                </div> : <span></span>
              }
              {
                show ? <button className='register-button' onClick={handlesubmit} disabled={isLoading}>
                  {isLoading ? <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner> : 'Register Now'} </button> : <div className='nxt-button register-button' onClick={() => setShow(true)}>Next</div>

              }

              <p className='errormessage'>{terms ? '' : aggrement}</p>
              <p className='errormessage'>{errorfirstname || errorlastname || errorusername || erroremail}</p>
            </form>
          </div>
          <div className='row justify-content-between pt-4 registerfooter'>
            <div className='col-lg-2 footericonleft col-sm-2 col-2'>
              <img src={footericonleft} alt="footericonleft" />
            </div>
            <div className='col-lg-2 footericonright col-sm-2 col-2'>
              <img src={footericonright} alt="footericonleft" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register