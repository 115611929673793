import axios from 'axios'
import { useState } from 'react';
import { Cookies } from 'react-cookie';
import { API } from '../helper/action';

export const useGeneratmockexam = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [examLoading, setExamLoading] = useState(null)
    const cookies = new Cookies();
    const [data, setData] = useState()


    const examtype = async (catergory, setSubject) => {
        setExamLoading(true)
        const category = catergory

        let token = cookies.get("jwt")

        await axios.get(`${API}/user/my-exams/getSubjects/${category}`,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            setExamLoading(false)
            setData(response.data.data.Subjects)
            setSubject(response.data.data.Subjects)
        }).catch((err) => {
        })
    }

    const generateexam = async (data, setMessage, setModalShow) => {
        setIsLoading(true)
        setError(null)

        let token = cookies.get("jwt")


        await axios.post(`${API}/user/my-exams`, data ,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
                setIsLoading(false)
                setMessage(response.data.message)
                // Navigate('/mockexam')
                if (typeof response.data.message === "string") {
                    return setModalShow(true)
                }
            
        }).catch((err) => {
            setIsLoading(false)
            setError(err)
            // console.log(err)
        })
    }
    
    return { examtype, data, generateexam, isLoading, error, examLoading }
}
