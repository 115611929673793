import axios from 'axios'
import { useCallback, useState } from 'react';
import { Cookies } from 'react-cookie';
import { API } from '../helper/action';
// import { useProfile } from './profile';

export const useReferral = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    // const [response, setResponse] = useState([])
    
    // const { profile } = useProfile()

   
    const maxAge = 1 * 24 * 60 * 60

    const referal = useCallback(async () => {
        const cookies = new Cookies();
        setIsLoading(true)
        setError(null)
        let data = ''

        let token = cookies.get("jwt")

        await axios.post(`${API}/user/generate-ref-code`, data,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            // console.log(response);
            setIsLoading(false)
            let user = {
                firstname: response.data.data.user.firstname,
                lastname: response.data.data.user.lastname,
                username: response.data.data.user.username,
                email: response.data.data.user.email,
                mobile: response.data.data.user.mobile,
                address: response.data.data.user.address.address,
                state: response.data.data.user.address.state,
                zip: response.data.data.user.address.zip,
                country: response.data.data.user.address.country,
                city: response.data.data.user.address.city,
                referral: response.data.data.user.referral_id
            }
            user = btoa(JSON.stringify(user))
            cookies.set("user" , user, { path: '/', maxAge: maxAge, sameSite: 'lax', secure: true });
        }).catch((err) => {
            setIsLoading(false)
            setError(err)
        })
    }, [ maxAge])

    const get_referal = useCallback(async (setData) => {
        const cookies = new Cookies();
        setIsLoading(true)
        setError(null)

        let token = cookies.get("jwt")

        await axios.get(`${API}/user/referrals`,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            // console.log(response);
            setData(response.data.data.User)
            setIsLoading(false)
            
        }).catch((err) => {
            setIsLoading(false)
            setError(err)
        })
    }, [])

    
    return { referal, isLoading, error, get_referal }
}
