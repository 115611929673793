import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Welcomeimg from '../assets/images/welcome.png'

function Welcome(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className='welcome-header' closeButton>
            </Modal.Header>
            <Modal.Body className='welcome-message' >
                <div>
                    <img src={Welcomeimg} alt='welcome message' />
                </div>
                <div>
                    <h4>
                        Welcome!
                    </h4>
                    <p>
                    Go to Practice - use the search button to find your preferred exams and subject.Goodluck!!!
                    </p>
                    <p>
                    Download Exams Africa app on play store for an easy access!!
                    </p>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Welcome