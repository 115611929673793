import React from "react";

import "../stylesheets/leaderboard.css";

function Leaderboard() {
    const data = [
        {
            Name: 'Chinedu Abosede',
            Point: '300'
        },
        {
            Name: 'Chinedu Abosede',
            Point: '300'
        },
        {
            Name: 'Chinedu Abosede',
            Point: '300'
        },
        {
            Name: 'Chinedu Abosede',
            Point: '300'
        },
        {
            Name: 'Chinedu Abosede',
            Point: '300'
        },
        {
            Name: 'Chinedu Abosede',
            Point: '300'
        },
        {
            Name: 'Chinedu Abosede',
            Point: '300'
        },
        {
            Name: 'Chinedu Abosede',
            Point: '300'
        },
        {
            Name: 'Chinedu Abosede',
            Point: '300'
        }
    ]
    return (
        <div>
            <div className="leaderboard-container">
                <div className="lead">
                {
                    data.map((list, index) => {
                        return (
                            <div className="leaderboard-list">
                                <div className="d-flex gap-3 gap-lg-5 gap-md-5">
                                    <span className={`ranking rank${index + 1}`}>{index + 1}</span>
                                    <div>
                                        <h5 className="mb-0">{list.Name}</h5>
                                        <p className="mb-0">Submitted 8 Days ago</p>
                                    </div>
                                </div>
                                <div>
                                    <h5 className="mb-0">{list.Point} Points</h5>
                                    <p className="mb-0">Out of 350 obtainable points</p>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </div>
    );
}

export default Leaderboard;
