import React, { useRef, useState } from "react";
import { useExamquestion } from "../hook/examquestion";
// import Loader from "../Components/Loader";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { Cookies } from "react-cookie";

import "../stylesheets/examquestion.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useExamsubmit } from "../hook/exam_submit";
import { ReactComponent as Submitimage } from "../assets/images/submit-image.svg";
// import { ReactComponent as Loaderin } from "../assets/loader.svg";
import { ReactComponent as Subscriberror } from "../assets/icons/subsriberror.svg";
import { ReactComponent as ProgressUpdate } from "../assets/icons/progressupdate.svg";
import { ReactComponent as Playaudio } from "../assets/icons/playaudio.svg";
import { ReactComponent as Needcontext } from "../assets/icons/needcontext.svg";
import { ReactComponent as Confirmsubmiticon } from "../assets/icons/confirmsubmit.svg";
import Morecontext from "../Components/Needcontext";
import { useCorrection } from "../hook/correction";
import Gradingprog from "../Components/Gradingprog";
// import { useCorrection } from "../hook/correction";



function Exitmodal(props) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="confirm-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Exit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">Are you sure we want to exit</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="No-button" onClick={props.onHide} autoFocus>
          No
        </Button>
        <Button onClick={goBack}>Yes</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Confirmsubmit(props) {

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    // className="confirm-modal"
    >

      <Modal.Body>
        <div className="text-center">
          <Confirmsubmiticon />
        </div>
        <p className="text-center sub-con">Are you sure you want to Submit?</p>
        <p className="m-auto text-center w-75">
          You are about to submit your exam, make sure to cross check your answers before clicking submit
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-button" onClick={props.onHide} autoFocus>
          Cancel
        </Button>
        <Button onClick={() => { props.submit() }}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
}


function SubmitModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="submitmodal"
    >
      <Modal.Header className="border-0" closeButton>
        {props.submit === "true" ? (
          ""
        ) : (
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="submit-title"
          >
            Your Exam Result
          </Modal.Title>
        )}
      </Modal.Header>
      {props.submit === "true" ? (
        <Modal.Body className="text-center exam-submit">
          <Submitimage />
          <h5>Exam Submitted!</h5>
          <p>
            Your answers to this exam has been submitted successfully, your
            results will be displayed in a few seconds
          </p>
          <p>
            <Spinner />
          </p>
        </Modal.Body>
      ) : (
        <Modal.Body className="text-center exam-submit">
          <div className="submit-table-container">
            <div className="submit-table">
              <p className="submit-table-border">Exams Name</p>
              <p className=" result-title">{props.resultinfo.exam_name}</p>
            </div>
            <div className="colored-submit-table">
              <p className="submit-table-border">Exam Category</p>
              <p>{props.resultinfo.exam_type}</p>
            </div>
            <div className="submit-table">
              <p className="submit-table-border">Exam Subject</p>
              <p>{props.resultinfo.exam_subject}</p>
            </div>
            <div className="colored-submit-table">
              <p className="submit-table-border">Total Question</p>
              <p>{props.resultinfo.total_question}</p>
            </div>
            <div className="submit-table">
              <p className="submit-table-border">Total Mark</p>
              <p>{props.resultinfo.pass_percentage}</p>
            </div>
            <div className="colored-submit-table">
              <p className="submit-table-border">Pass Mark</p>
              <p>{props.resultinfo.pass_percentage}</p>
            </div>
            <div className="submit-table">
              <p className="submit-table-border">Pass Mark Percentage</p>
              <p>{props.resultinfo.pass_percentage}%</p>
            </div>
            <div className="colored-submit-table">
              <p className="submit-table-border">Your Mark</p>
              <p>{Math.floor(props.resultinfo.result_mark)}</p>
            </div>
            <div className="submit-table">
              <p className="submit-table-border">Total Correct Answer</p>
              <p>{props.resultinfo.total_correct_ans}</p>
            </div>
            <div className="colored-submit-table">
              <p className="submit-table-border">Total Wrong Answer</p>
              <p>{props.resultinfo.total_wrong_ans}</p>
            </div>
          </div>
          <div className='result-performance'>
            <Link to='/perfomance' onMouseOver={() => { localStorage.setItem('details', btoa(props.resultinfo.examdetails)); localStorage.setItem('exam', btoa(JSON.stringify({ correct: props.resultinfo.total_correct_ans, wrong: props.resultinfo.total_wrong_ans }))) }}>
              Performance
            </Link>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
}

function Examquestion() {
  const [display, setDisplay] = useState(false);
  const [question, setQuestion] = useState([]);
  const [submit, setSubmit] = useState([]);
  const [exam, setExam] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [needshow, setNeedShow] = useState(false);
  const [progshow, setProgShow] = useState(false);
  const [submitmodalShow, setSubmitModalShow] = useState(false);
  const [confirmsubmit, setConfirmSubmit] = useState(false);
  const [questio_id, setQuestion_id] = useState();
  const [answerid, setAnswer] = useState();
  const { examquestion, isLoading, error } = useExamquestion();
  const { correction, contextLoading, res } = useCorrection();
  const { examsubmit, isSubmitLoading } = useExamsubmit();

  const [message, setMessage] = useState("");
  const [selectedanswer, setSelectedanswer] = useState({
    examId: "",
  });
  const cookies = new Cookies();
  const navigate = useNavigate();
  // const { correction, res, setRes } = useCorrection()

  const Ref = useRef(null);

  // console.log(needshow);

  const [timer, setTimer] = useState("00:00:00");

  let examid = cookies.get("id");
  examid = JSON.parse(atob(examid));

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("01:00:00");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setMinutes(deadline.getMinutes() + 60);
    return deadline;
  };

  const Startexam = async (e) => {
    e.preventDefault();
    if (examid === undefined) {
      return navigate(-1);
    } else {
      await examquestion(setQuestion, setExam, setMessage, examid.exam);
      setDisplay(true);
      clearTimer(getDeadTime());
    }
  };

  const questionname = {
    title: exam === undefined ? "" : exam.title,
    totalmark: exam === undefined ? "" : exam.totalmark,
    pass_percentage: exam === undefined ? "" : exam.pass_percentage,
    exam_type: examid.exam_type,
    total_correct_ans: submit.total_correct_ans,
    total_wrong_ans: submit.total_wrong_ans,
    result_mark: submit.result_mark,
    result_status: submit.result_status,
    total_question: question === undefined ? "" : question.length,
    exam_name: examid.name,
    exam_subject: examid.subject,
    examdetails: submit.details
  };

  let progress = pageNumber/question.length 
  progress = Math.ceil(progress * 100)

  console.log(submitmodalShow, progshow);

  let examperpage = 1;
  let pagesVisted = pageNumber + examperpage;

  function decrement() {
    setPageNumber(pageNumber - 1);
  }
  function increment() {
    setPageNumber(pageNumber + examperpage);
    selectedanswer[questio_id] = answerid;
  }

  const getanswer = (e) => {
    const question_id = e.target.name;
    const id = e.target.value;
    return (
      setAnswer(id),
      setQuestion_id(`answer[${question_id}]`),
      setSelectedanswer({ ...selectedanswer, examId: `${exam.id}` })
    );
  };

  const confirm = () => {
    setConfirmSubmit(true)
  }

  const needcontext = () => {
    // setNeedShow(true)
    let question = document.getElementById('question')
    question = question.textContent
    correction(question, setNeedShow)
    // console.log(question);
  }

  const handlesubmit = async () => {
    // e.preventDefault();
    setConfirmSubmit(false)
    setSubmitModalShow(true);
    await examsubmit(selectedanswer, setSubmit, setSubmitModalShow, setProgShow );
  };

  const displayPosts =
    question === undefined ? (
      <div>no</div>
    ) : (
      question.slice(pageNumber, pagesVisted).map((post, index) => {
        return (
          <div className="questionbox" key={index}>
            <input id="myInput" value={post.exam_id} readOnly hidden />
            <div
            id="question"
              className="question-asked"
              dangerouslySetInnerHTML={{ __html: post.question }}
            />
            <div className="question-option" onChange={getanswer}>
              {post.options.map((option) => {
                return (
                  <div key={option.id} className="each-option">
                    <input
                      type="radio"
                      id={option.id}
                      name={option.question_id}
                      value={option.id}
                    />
                    <label>{option.option}</label>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })
    );

  return (
    <div>
      <div className={`exam-page ${display ? "d-none" : ""}`}>
        <div className="question-modal">
          <h4 className="text-center">Start Exam</h4>
          <div className="start-exam">
            <div className="exam-title">{examid.exam_type}</div>
            <div>Time: 60 minutes</div>
          </div>
          <p className="exam-instruction">
            You are advised to be diligent with answering test questions, your
            performance will be analysed with the aim providing solution to
            lapses detected.
          </p>
          <div className="text-center">
            <button className="start-button" onClick={Startexam}>
              {isLoading ? <Spinner /> : "Start Now"}
            </button>
          </div>
        </div>
      </div>
      <div className={`question-container ${display ? "d-block" : "d-none"}`}>
        {isLoading ? (
          ""
        ) : (
          <div>
            {error === "Kindly Subscribe" ? (
              <div>
                <div className={`exam-page`}>
                  <div className="question-modal">
                    <h4 className="text-center">Not Subscribed</h4>
                    <div className="upgrade-error">
                      <Subscriberror />
                    </div>
                    <p className="exam-upgrade">
                      Kindly subscribe to get access to questions
                    </p>
                    <div className="text-center">
                      <Link to='/subscriptions' className="start-button exam-upgrade-button">
                        Upgrade
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : question === undefined ? (
              <div>
                <div
                  className="question-back-button"
                  onClick={() => navigate(-1)}
                >
                  End Exam
                </div>
                <p className="no_question text-center">{message}</p>
              </div>
            ) : (
              <div>
                <div>
                  <div>
                    <div
                      className="question-back-button"
                      onClick={() => setModalShow(true)}
                    >
                      End Exam
                    </div>
                    <Exitmodal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                  <div className="header-container">
                    <p>{exam.title}</p>
                    <p>
                      Time Remaining: <span id="time">{timer}</span>
                    </p>
                  </div>
                  <div className="question-instruction">
                    <h6>Examination Instruction:</h6>
                    <p>
                      You are advised to be diligent with answering of
                      questions, as Student's performances are analyzed with the
                      secondary aim of detecting the weaknesses and proffering a
                      solution.
                    </p>
                  </div>
                </div>
                <div>{displayPosts}</div>
                <div className="question-pagination">
                  <button
                    className={`previous-pagination ${pageNumber === 0 ? "d-none" : "d-block"
                      }`}
                    onClick={decrement}
                  >
                    Previous
                  </button>
                  {pageNumber === question.length - 1 ? (
                    <button
                      className={`next-pagination `}
                      onClick={confirm}
                    >
                      Submit
                    </button>
                  ) : (
                    <button className={`next-pagination `} onClick={increment}>
                      Next
                    </button>
                  )}
                </div>
                <div className="question-progress">
                  <div>
                    <div>
                      <span>
                        <ProgressUpdate />
                      </span>
                      <div className="question-progress-info">
                        <p>Progress Update</p>
                        <p>{progress}% Done! Keep going you can do it!</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>
                        <Needcontext />
                      </span>
                      <div className="question-progress-info">
                        <p>Need context?</p>
                        {/* <p>The national secretariat will be most likely be place under the authority of leading figure heads in the political party.The national secretariat will be most likely be place under the authority of leading figure heads in the political party. With this try and figure out the right answer.</p> */}
                        <button className="click" onClick={needcontext}>{contextLoading ? <Spinner /> : 'Click Here'}</button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>
                        <Playaudio />
                      </span>
                      <div className="question-progress-info">
                        <p>Play Audio</p>
                        <p>Want the questions to be read aloud? Press the icon to play it</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <SubmitModal
              show={submitmodalShow}
              onHide={() => navigate(-1)}
              submit={`${isSubmitLoading}`}
              resultinfo={questionname}
            />
            <Confirmsubmit
              show={confirmsubmit}
              onHide={() => setConfirmSubmit(false)}
              submit={handlesubmit}
            // resultinfo={questionname}
            />
            <Morecontext
              show={needshow}
              onHide={() => setNeedShow(false)}
              res={res}
              // submit={handlesubmit}
            // resultinfo={questionname}
            />
            <Gradingprog
              show={progshow}
              onHide={() => setProgShow(false)}
              // submit={handlesubmit}
            // resultinfo={questionname}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Examquestion;
