import axios from "axios";
import { useCallback, useState } from "react";
import { Cookies } from "react-cookie";
import { API } from "../helper/action";

export const useExamhistory = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [total, setTotal] = useState([])
  const cookies = new Cookies();

  let token = cookies.get("jwt");

  const practisehistory = useCallback(
    async (setAvailableexam, setTotalcount) => {
      setIsLoading(true);
      setError(null);

      await axios
        .get(`${API}/user/exams/exam-history`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          // console.log(JSON.parse(response.data.data.histories.data.details));
          setAvailableexam(response.data.data.histories.data);
          setTotalcount(response.data.data.histories);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    },
    [token]
  );

  const practisehistory2 = useCallback(
    async (setAvailableexam, availableexam, pagenumber) => {
      setIsLoading(true);
      setError(null);

      await axios
        .get(`${API}/user/exams/exam-history`, {
          params: {
            page: pagenumber,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          const newarray = response.data.data.histories.data;
          setAvailableexam([...availableexam, ...newarray]);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    },
    [token]
  );

  const filteredexam = useCallback(async (setFiltereddata, param) => {
    setIsLoading(true)
    setError(null)

    await axios.get(`${API}/user/exams/exam-history`,
    {
        params: param,
        headers: {
            'Authorization' : `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    } ).then((response) => {
        setIsLoading(false)
        const newarray = response.data.data.histories.data
        setFiltereddata(newarray)
        setTotal(response.data.data.histories)
    }).catch((err) => {
        setIsLoading(false)
        setError(err)
    })
}, [token])

const filteredexam2 = useCallback(async (setFiltereddata, param, filtereddata, pagenumber1) => {
    setIsLoading(true)
    setError(null)

    console.log(param)
    await axios.get(`${API}/user/exams/exam-history`,
    {
        params: {
            year: param.year,
            subject: param.subject,
            page: pagenumber1
        },
        headers: {
            'Authorization' : `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    } ).then((response) => {
        setIsLoading(false)
        const newarray = response.data.data.histories.data
        setFiltereddata(newarray)
        setFiltereddata([...filtereddata, ...newarray])
    }).catch((err) => {
        setIsLoading(false)
        setError(err)
    })
}, [token])

  return { practisehistory, isLoading, error, practisehistory2, filteredexam, total, filteredexam2 };
};
