import React, { useState } from "react";
import { ReactComponent as Speaker } from "../assets/icons/speaker.svg";
// import { ReactComponent as Pause } from "../assets/icons/pauseicon.svg";
import "../stylesheets/examcorrection.css";
// import { useCorrection } from "../hook/correction";
// import Typewriter from 'typewriter-effect';
// import Spinner from 'react-bootstrap/Spinner';
import { ReactComponent as ProgressUpdate } from "../assets/icons/progressupdate.svg";
import { ReactComponent as Needcontext } from "../assets/icons/needcontext.svg";
// import { ReactComponent as Playaudio } from "../assets/icons/playaudio.svg";

function Examcorrection() {
    const [pageNumber, setPageNumber] = useState(0);
    // const [displayexplain, setDisplayExplain] = useState(false)
    const [speaking, setSpeaking] = useState(false)
    // const { correction, res, isLoading, setRes } = useCorrection()
    let exam = atob(localStorage.getItem('details'))
    let score = JSON.parse(atob(localStorage.getItem('exam')))
    let synth = window.speechSynthesis

    const speechHandler = () => {
        const msg = new SpeechSynthesisUtterance()
        let voices = synth.getVoices()
        msg.text = document.getElementsByClassName('speaktext')[0].innerHTML
        // let speaking = synth.speaking
        // setSpeaking(speaking)
        msg.voice = voices.filter(function (voice) { return voice.name === 'Google UK English Female'; })[0];
        synth.speak(msg)
        // console.log(synth.speaking);
        setSpeaking(synth.speaking)
    }

    console.log(speaking);

    const data = JSON.parse(exam, (key, value) => {
        return value;
    })

    let examperpage = 1;
    let pagesVisted = pageNumber + examperpage;
    function decrement() {
        setPageNumber(pageNumber - 1);
        // setDisplayExplain(false)
    }
    function increment() {
        setPageNumber(pageNumber + examperpage);
        // setDisplayExplain(false)
    }

    // const morecontext = async () => {
    //     let explain = document.getElementsByClassName('Typewriter__wrapper')[0].innerHTML
    //     await correction(explain)
    // }


    return (
        <div>
            {/* <div className="question-instruction">
                <p>You got {score.wrong} questions wrong out of 40.</p>
                <p>Let’s fix that, you can find the right answers below!</p>
            </div>
            <div className="corection-table">
                <div>
                    <p>Total Correct Answer</p>
                    <p>{score.correct}</p>
                </div>
                <div className="snd-table">
                    <p>Total Wrong Answer</p>
                    <p className="second-snd-table">{score.wrong}</p>
                </div>
            </div> */}
            <div className="correction-box">
                <div>
                    <span>
                        <ProgressUpdate />
                    </span>
                    <div className="question-progress-info">
                        <p>
                            Progress Update
                        </p>
                        <p>
                            100% Done! Welldone Champ, you did it.
                        </p>
                    </div>
                </div>
                <div>
                    <span>
                        <ProgressUpdate />
                    </span>
                    <div className="question-progress-info">
                        <p>
                            Exam Score
                        </p>
                        <p>
                            {Math.floor(score.correct * 5 / 2)}/100 Points
                        </p>
                    </div>
                </div>
                <div>
                    <span>
                        <Needcontext />
                    </span>
                    <div className="question-progress-info">
                        <p>
                            Feedback
                        </p>
                        <p>
                            You got {score.correct} questions right out of 40.
                        </p>
                        <p>
                            Let’s fix that, you can find the right answers below
                        </p>
                    </div>
                </div>
            </div>
            <div className="correction-section">
                {
                    data.slice(pageNumber, pagesVisted).map((post, index) => {
                        return (
                            <div className="correction-container" key={index}>
                                <p className="question-number">Question {post.question_number}</p>
                                <div className="answer-container">
                                    <div className="d-flex justify-content-between post-question">
                                        <p className="speaktext" dangerouslySetInnerHTML={{ __html: post.question.question }} />
                                        <span><Speaker onClick={() => speechHandler()}/></span>
                                    </div>
                                    <div className="answer">
                                        <div className="answer-title">
                                            {
                                                post.options.map((option, index) => {
                                                    return (
                                                        <span key={index} className={`${JSON.stringify(option.id) !== post.selected_answer ? 'd-none' : option.correct_ans === 1 ? 'correct-dot-ans' : 'wrong-dot-ans'}`}></span>
                                                    )
                                                })}
                                            <p>Your answer:</p>
                                        </div>
                                        <div className="answer-title no-dot">
                                            <span></span>
                                            {
                                                post.options.map((option, index) => {
                                                    return (
                                                        <p key={index} className={`${JSON.stringify(option.id) !== post.selected_answer ? 'd-none' : option.correct_ans === 1 ? 'correct-ans' : 'wrong-ans'}`}>
                                                            {
                                                                option.option
                                                            }
                                                        </p>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="correct-answer">
                                        <div className="correct-answer-title">
                                            <span></span>
                                            <p>Correct answer:</p>
                                        </div>
                                        <div className="answer-title no-dot">
                                            <span></span>
                                            {
                                                post.options.map((option, index) => {
                                                    return (
                                                        <p key={index} className={`${option.correct_ans === 1 ? '' : 'd-none'}`}>
                                                            {option.option}
                                                        </p>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="explain-answer">
                                        <button className={`explanation-button ${displayexplain ? 'd-none' : ''}`} onClick={() => setDisplayExplain(true)}>View Explanation</button>
                                        <div className={`explain-answer-title no-dot  ${displayexplain ? '' : 'd-none'}`} >
                                            <span></span>
                                            <p>Explanation:</p>
                                        </div>
                                        <div className={`answer-title no-dot1  ${displayexplain ? '' : 'd-none'}`}>
                                            <span></span>
                                            <Typewriter
                                            options={{
                                                strings: `${post.question.explanation === null ? 'Coming soon' : post.question.explanation}`,
                                                autoStart: true,
                                                delay: 75,
                                                cursorClassName: 'typewritercursor'
                                            }}
                                            />
                                        </div>
                                        <div className={`answer-title no-dot1  ${res !== ''  ? 'line-2' : 'd-none'}`}>
                                            <span></span>
                                            <Typewriter
                                            options={{
                                                strings: `${res}`,
                                                autoStart: true,
                                                delay: 75
                                            }}
                                            />
                                        </div>
                                        <div className={`more-container ${post.question.explanation === null  ? 'd-none' : ''}`}>
                                            <div className={`speaker `} >
                                                <Speaker onClick={() => speechHandler()}/>
                                            </div>
                                            <button onClick={morecontext}>
                                                {isLoading ? <Spinner /> : 'More Context'}
                                            </button>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="explain-container">
                                        <div className="explain-answer">
                                            <div className="explain-answer-title no-dot1">
                                                <span></span>
                                                <p>Explanation:</p>
                                            </div>
                                            <div className="answer-title no-dot1 ">
                                                <span></span>
                                                <p>{post.question.explanation === null ? 'Coming soon' : post.question.explanation}</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="pag-button-container">
                <button className={`pag-button ${pageNumber === 0 ? 'd-none' : ''}`} onClick={decrement}>
                    Previous
                </button>
                <button className={`pag-button ${pageNumber === data.length - 1 ? 'd-none' : ''}`} onClick={increment}>
                    Next
                </button>
            </div>
        </div>
    );
}

export default Examcorrection;
