import React, { useEffect } from 'react'
import Table from 'react-bootstrap/Table';
import { useTransactionhistory } from '../hook/transactionhistory';
import Loader from "../Components/Loader";
import "../stylesheets/transactionhistory.css";

function Transactionhistory() {
  const { transactions, response, isLoading } = useTransactionhistory()
  function convertDate(date) {
    const fullyear = new Date(date);
    let year = fullyear.getFullYear();
    let month = fullyear.getMonth() + 1;
    let day = fullyear.getDate();
    day = day.toString().padStart(2, "0");

    month = month.toString().padStart(2, "0");
    const formattedToday = day + "/" + month + "/" + year;
    return formattedToday;
  }
  // console.log(response)
  useEffect(() => {
    transactions()
  }, [transactions])
  return (
    <div>
      {
        isLoading ? <Loader />:
        <div className='transactionhistorycontent'>
      {response.length === 0 ? <p className='no-history'>No transaction history</p> : 
      <Table className='transactionhistorytable' borderless variant='white'>
        <thead>
          <tr className='transaction-header'>
            <th>Reference Number</th>
            <th>Subcription Plan</th>
            <th>Amount</th>
            <th>Payment Mode</th>
            <th>Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {
            response.map((res, index) => {
              return (
                <tr key={index}>
                  <td>{res.merchant_reference}</td>
                  <td>{res.amount === '500.00' ? '1-Month Plan' : res.amount === '1200.00' ? '3-Month Plan' : res.amount === '2500.00' ? '6-Month Plan' : res.amount === '5000.00' ? '1-Year Plan' : ''}</td>
                  <td>NGN{res.amount}</td>
                  <td>{res.payment_method}</td>
                  <td className={`${res.payment_status === 'pending' ? 'pending-color' : res.payment_status === 'successful' ? 'succes-color' : res.payment_method === 'error' ? 'text-danger' : ''}`}>{res.payment_status}</td>
                  <td>{convertDate(res.updated_at)}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>}
    </div>
      }
    </div>
  )
}

export default Transactionhistory