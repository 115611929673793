import { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { API } from '../helper/action'

export const useForgetPassword = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)

    let Navigate = useNavigate()

    const forgetpassword = async (email) => {
        setIsLoading(true)
        setError(null)

        const data = {
            email: email
        }

       await axios.post(`${API}/user/auth/password/forgot`, data ,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
                if(response.data) {
                    let emailid =  btoa(JSON.stringify(data))
                    sessionStorage.setItem('emailid', emailid)
                    Navigate('/resetcode')
                }
                setIsLoading(false)
            
        }).catch((err) => {
            setIsLoading(false)
            setError(err.response.data.message)
            // console.log(err.response.data.message)
        })
    }

    return { forgetpassword, isLoading, error}
}
