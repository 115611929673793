import React from 'react'

import '../stylesheets/Register.css'
import {ReactComponent as Logo} from '../assets/icons/examlogo2.svg'
import confirmationimg from '../assets/icons/checkemailimg.svg'
// import { useNavigate } from 'react-router'


function Emailconfirmation() {

  // let Navigate = useNavigate()

  


  // useEffect(() => {

  // function callBack_func() {
  //   Navigate('/resetpassword')
  // }
  
  //   setTimeout(callBack_func, 5000);
  // }, [Navigate])

  return (
    <div>
      {/* Header */}
      <div className="row justify-content-between header header-con">
        <div className="col-lg-2 col-sm-3 col-4">
            <Logo />
        </div>
      </div>
      {/* Content */}
      <div className="row mainbody mainbody-resetcode">
        <div className="col-lg-2">
          <h1 className="title">Check your mail</h1>
        </div>
        <div className="row justify-content-around pt-md-5 pt-lg-0 pt-4">
          <div className="col-lg-7">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-sm-5 col-8">
                <div className='email-con-img'>
                  <img src={confirmationimg} alt="confirmation" />
                </div>
                <div className="text-md py-4 mt-5 text-center">
                  <p>Password verification has been sent to the your email, follow the stated prompts to set up your new password </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Emailconfirmation