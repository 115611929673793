import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ReactPaginate from "react-paginate";
import { Cookies } from "react-cookie";

import "../stylesheets/availableexam.css";

import Loader from "../Components/Loader";
import searchicon from "../assets/icons/Combined-Shape.svg";
import arrowicon from "../assets/icons/arrowicon.svg";
import { ReactComponent as Nexticon } from "../assets/icons/nextarrw.svg";
import { ReactComponent as Previcon } from "../assets/icons/prevarrw.svg";
import { useAvailableexams } from "../hook/availableexam";
import { useGeneratmockexam } from "../hook/generatemockexam";
import { usePastquestion } from "../hook/pastquestion";
import { useExamquestion } from "../hook/examquestion";

function Practice() {
  const [availableexam, setAvailableexam] = useState([]);
  const [totalcount, setTotalcount] = useState([]);
  const [examid, setExamid] = useState({
    exam: '',
    subject: '',
    name: '',
    exam_type: ''
  });
  const { questionandanswer } = useExamquestion();
  const { availableexams, isLoading, availableexams2, filteredexam, total, filteredexam2, dashboardfilteredexam } = useAvailableexams();
  const cookies = new Cookies();
  const [filtereddata, setFiltereddata] = useState([]);
  const [subject, setSubject] = useState([]);
  const [years, setYears] = useState([]);
  const { examtype, examLoading } = useGeneratmockexam();
  const [itemOffset, setItemOffset] = useState(0);
  const { Category, categories } = usePastquestion()
  const [param, setParam] = useState({
    category_id: '',
    year: '',
    subject_id: '',
  })

  const location = useLocation()
  let currentlocation = location.state || {}

  const allyear = function yearlist(startYear = 1985) {
    const endDate = new Date().getFullYear();
    let years = [];

    while (startYear <= endDate) {
      years.push(startYear);
      startYear++;
    }
    setYears(years);
  };

  const filterByExam = async (e) => {
    setParam({...param, category_id: e.target.value === 'Specify Exam Type' ? '' : e.target.value})
    await examtype(e.target.value, setSubject);
  };

  const getfilteredexam = async(e) => {
    e.preventDefault()
    await filteredexam(setFiltereddata, param, setTotalcount)
  }

  const maxAge = 1 * 2 * 60 * 60;
  
  const getquestionandanswer = async (e) => {
    const exam_info = btoa(JSON.stringify(examid))
    cookies.set("id", exam_info, {
      path: "/",
      maxAge: maxAge,
      sameSite: "lax",
      secure: true,
    });
    await questionandanswer()
  };

  const getexamquestion = async (e) => {
    const exam_info = btoa(JSON.stringify(examid))
    cookies.set("id", exam_info, {
      path: "/",
      maxAge: maxAge,
      sameSite: "lax",
      secure: true,
    });
  };

  const itemsPerPage = 9;
  const endOffset = itemOffset + itemsPerPage;
  const PageCount = Math.ceil(totalcount.total / itemsPerPage) || 0;
  const PageCount1 = Math.ceil(total.total / itemsPerPage) || 0;

  useEffect(() => {
    Category()
    if(sessionStorage.getItem('search') !== null) {
      let search = sessionStorage.getItem('search')
      search = JSON.parse(atob(search))
      dashboardfilteredexam(setFiltereddata, search, setTotalcount)
    }else {
      availableexams(setAvailableexam, setTotalcount);
    }
    allyear();
  }, [availableexams, dashboardfilteredexam, Category, currentlocation.message]);

  const pagenumber = availableexam.length / totalcount.per_page + 1;
  const handlePageClick = async (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalcount.total;
    const setnewOffset = newOffset + itemsPerPage;
    const remainitem = availableexam.length - setnewOffset;
    if (remainitem < itemsPerPage) {
      await availableexams2(setAvailableexam, availableexam, pagenumber);
    }
    setItemOffset(newOffset);
  };

  const pagenumber1 = filtereddata.length / total.per_page + 1;
  const handlePageClick1 = async (event) => {
    const newOffset = (event.selected * itemsPerPage) % total.total;
    const setnewOffset = newOffset + itemsPerPage;
    const remainitem = filtereddata.length - setnewOffset;
    if (remainitem < itemsPerPage && filtereddata.length !== total.total) {
      await filteredexam2(setFiltereddata, param, filtereddata, pagenumber1);
    }
    setItemOffset(newOffset);
  };

  const filteredpost = filtereddata
    .slice(itemOffset, endOffset)
    .map((post, index) => {
      return (
        <div className="exam-status-card">
          <div className="availableexambox" key={index}>
          <h3>{post.subject.name}</h3>
          <p>
            Subject :{" "}
            <span className="text-capitalize">{post.subject.slug}</span>
          </p>
          <p>
            Type : <span>OBJ</span>
          </p>
          <p>
            Exam Category : <span>{post.subject.category.name}</span>
          </p>
          <p>
            Year : <span>{post.to_year === null ? post.year : `${post.from_year} - ${post.to_year}`}{" "}</span>
          </p>
          {
            post.value === 2 ? <div className="d-flex justify-content-between text mt-1">
            <Link
              onMouseOver={() => setExamid({...examid, exam : post.id, subject: post.subject.slug, name:  post.subject.name, exam_type: post.subject.category.name })}
              onTouchStart={() => setExamid({...examid, exam : post.id, subject: post.subject.slug, name:  post.subject.name, exam_type: post.subject.category.name })}
              onClick={getquestionandanswer}
            >
              view question and answer
              <img src={arrowicon} alt="arrowicon" />
            </Link>
            <Link
              to="/exam-questions"
              onMouseOver={() => setExamid({...examid, exam : post.id, subject: post.subject.slug, name:  post.subject.name, exam_type: post.subject.category.name })}
              onTouchStart={() => setExamid({...examid, exam : post.id, subject: post.subject.slug, name:  post.subject.name, exam_type: post.subject.category.name })}
              onClick={getexamquestion}
            >
              Practice Exam
              <img src={arrowicon} alt="arrowicon" />
            </Link>
            </div> : ''
          }
        </div>
        {
          post.value === 1 ? <p className="exam-status exam-status-paid">Paid</p> : post.value === 2 ? <p className="exam-status">free</p> : ''
        }
        
        </div>
      );
    });

  const displayPosts = availableexam
    .slice(itemOffset, endOffset)
    .map((post, index) => {
      return (
        <div className="exam-status-card" key={index}>
          <div className="availableexambox" >
          <h3>{post.subject.name}</h3>
          <p>
            Subject :{" "}
            <span className="text-capitalize">{post.subject.slug}</span>
          </p>
          <p>
            Type : <span>OBJ</span>
          </p>
          <p>
            Exam Category : <span>{post.subject.category.name}</span>
          </p>
          <p>
            Year : <span>{post.to_year === null ? post.year : `${post.from_year} - ${post.to_year}`}{" "}</span>
          </p>
          {
            post.value === 2 ? <div className="d-flex justify-content-between text mt-1">
            <Link
              onMouseOver={() => setExamid({...examid, exam : post.id, subject: post.subject.slug, name:  post.subject.name, exam_type: post.subject.category.name })}
              onTouchStart={() => setExamid({...examid, exam : post.id, subject: post.subject.slug, name:  post.subject.name, exam_type: post.subject.category.name })}
              onClick={getquestionandanswer}
            >
              view question and answer
              <img src={arrowicon} alt="arrowicon" />
            </Link>
            <Link
              to="/exam-questions"
              onMouseOver={() => setExamid({...examid, exam : post.id, subject: post.subject.slug, name:  post.subject.name, exam_type: post.subject.category.name })}
              onTouchStart={() => setExamid({...examid, exam : post.id, subject: post.subject.slug, name:  post.subject.name, exam_type: post.subject.category.name })}
              onClick={getexamquestion}
            >
              Practice Exam
              <img src={arrowicon} alt="arrowicon" />
            </Link>
            </div> : ''
          }
        </div>
        {
          post.value === 1 ? <p className="exam-status exam-status-paid">Paid</p> : post.value === 2 ? <p className="exam-status">free</p> : ''
        }
        
        </div>
      );
    });

  return (
    <div className="availableexamcontent">
      <div className="availableexamnav">
        <h6>Available Exams</h6>
        <div className="">
          <div className="filterbox filterbox1">
            <Form.Select
              defaultValue="Specify Exam Type"
              className="selectbox"
              onChange={filterByExam}
            >
              <option value='Specify Exam Type'>Specify Exam Type</option>
              {categories.map((category, index) => {
                return (
                  <option value={category.id} key={index}>{category.name}</option>
                )
              })}
            </Form.Select>
            <Form.Select
              defaultValue="Select Subject"
              className="selectbox"
              onChange={(e) => {setParam({...param, subject_id: e.target.value === 'Select Subject' ? '' : e.target.value})}}
            >
              <option >{examLoading ? 'Loading Subject ...' : 'Select Subject'}</option>
              {Object.entries(subject).map(([key, value]) => {
                return (
                  <option key={key} value={key}>
                    {value}
                  </option>
                );
              })}
            </Form.Select>
            <Form.Select
              defaultValue="Select year"
              className="selectbox selectbox1 selectboxmobile"
              onChange={(e) => {setParam({...param, year: e.target.value === 'Select year' ? '' : e.target.value})}}
            >
              <option>Select year</option>
              {years.map((year, index) => {
                return (
                  <option key={index} value={year}>
                    {year}
                  </option>
                );
              })}
            </Form.Select>
            <button className="searchbutton searchbutton1" onClick={getfilteredexam}>
              <img src={searchicon} alt="searchicon" onClick={getfilteredexam}/>
            </button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="availableexamgrid">
        {filtereddata.length !== 0 ? filteredpost : displayPosts}
          {/* {availableexam.length !== 0 ? displayPosts : filtereddata.length === 0 ? <div> No exam for this subject</div>  : filteredpost } */}
        </div>
      )}
      <div className="mt-4 paginate">
        {filtereddata.length !== 0 ? (
          <ReactPaginate
            breakLabel="..."
            previousLabel={<Previcon />}
            nextLabel={<Nexticon />}
            pageCount={PageCount1}
            onPageChange={handlePageClick1}
            containerClassName={"Paginationbtn"}
            previousLinkClassName={"prevbtn"}
            pageClassName={"pageli"}
            nextLinkClassName={"nextbtn"}
            disabledClassName={"paginationdisabled"}
            activeClassName={"paginationactive"}
            breakClassName={"breakli"}
            renderOnZeroPageCount={null}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
          />
        ) : (
          <ReactPaginate
            breakLabel="..."
            previousLabel={<Previcon />}
            nextLabel={<Nexticon />}
            pageCount={PageCount}
            onPageChange={handlePageClick}
            containerClassName={"Paginationbtn"}
            previousLinkClassName={"prevbtn"}
            pageClassName={"pageli"}
            nextLinkClassName={"nextbtn"}
            disabledClassName={"paginationdisabled"}
            activeClassName={"paginationactive"}
            breakClassName={"breakli"}
            renderOnZeroPageCount={null}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
          />
        )}

        {/* <p>
          {isLoading ? '' : ` Results: ${pageindex === 0 ? "1" : pageindex} - ${pagelist === 0 ?  examperpage :  pagelist < examperpage ? availableexam.length : pagelist} of ${availableexam.length}`}
          </p> */}
      </div>
    </div>
  );
}

export default Practice;
