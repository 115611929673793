import axios from "axios";
import { useCallback, useState } from "react";
import { API } from "../helper/action";

export const useBlog = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const blogpost = useCallback(
        async (setBlog) => {
            setIsLoading(true);
            setError(null);
    
            await axios
                .get(`${API}/user/home/blogs`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    setIsLoading(false);
                    setBlog(response.data.data.blog_elements.data);
                })
                .catch((err) => {
                    setIsLoading(false);
                    setError(err);
                });
        }, []
    )

    const recentblog = useCallback(
        async (setBlog) => {
            setIsLoading(true);
            setError(null);
    
            await axios
                .get(`${API}/user/home/blogs/recent`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    setIsLoading(false);
                    setBlog(response.data.data.recent_blogs);
                })
                .catch((err) => {
                    setIsLoading(false);
                    setError(err);
                });
        }, []
    )

    const singlepost = useCallback(
        async (setBlog) => {
            setIsLoading(true);
            setError(null);

            let id = localStorage.getItem('postid')
    
            await axios
                .get(`${API}/user/home/blog/${id}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    setIsLoading(false);
                    setBlog(response.data.data.blog);
                })
                .catch((err) => {
                    setIsLoading(false);
                    setError(err);
                });
        }, []
    )

    return { blogpost, isLoading, error, recentblog, singlepost };
};
