import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useGeneratmockexam } from '../hook/generatemockexam'
import Spinner from 'react-bootstrap/Spinner';
import Modal from "react-bootstrap/Modal";

import '../stylesheets/Generateexam.css'
import { usePastquestion } from '../hook/pastquestion';

function Exitmodal(props) {
  

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="confirm-modal"
    >
      <Modal.Body>
        <p className="text-center generate-popup">{props.message}</p>
      </Modal.Body>
    </Modal>
  );
}

function Generatemockexam() {
  const datainfo = {
    subject_id: '',
    from: '',
    to: '', 
    mock_exam_type: ''
  }
  const [subject, setSubject] = useState([])
  const [years, setYears] = useState([])
  const [data, setData] = useState(datainfo)
  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState()
  const { Category, categories } = usePastquestion()
  const { examtype, generateexam, isLoading, examLoading } = useGeneratmockexam()

  const Catergorysubmit = async(e) => {
      const category = e.currentTarget.value
      await examtype(category, setSubject)
  }

  const navigate = useNavigate();
  const goBack = () => {
    if (message === 'Questions not available for the year ranges!') {
       setModalShow(false)
    }else {
       
       navigate('/mockexam')
    }
  };

const year = function yearlist(startYear = 1985) {
    const endDate = new Date().getFullYear();
    let years = [];
  
    while (startYear <= endDate) {
      years.push(startYear);
      startYear++;
    }
    setYears(years)
}

const generateexamsubmit = async(e) => {
  e.preventDefault()
  await generateexam(data, setMessage, setModalShow)
  
}
  
  useEffect(() => {
    year();
    Category()
  }, [Category])

  return (
    <div className='generate-content'>
      <div className='generateexam-container'>
        <form className='generateform' onSubmit={generateexamsubmit}>
          <div className='generate-select'>
            <label>
              Select Exam Type
            </label>
            <Form.Select aria-label="Default select example" onChange={Catergorysubmit} >
              <option defaultValue={true}>Select Exam Catergory</option>
              {categories.map((category, index) => {
                return (
                  <option value={category.id} key={index}>{category.name}</option>
                )
              })}
            </Form.Select>
          </div>
          <div className='generate-select'>
            <label>
              Select Subject
            </label>
                <Form.Select aria-label="Default select example" name='subject_id' onChange={(e) => setData({...data, [e.target.name]: e.target.value})}>
                <option defaultValue={true}>{examLoading ? 'Loading Subject ...' : 'Select Subject'}</option>
                { Object.entries(subject).map(([key, value]) => {
              return (
                <option key={key} value={key}>{value}</option>
              )})}
              </Form.Select>
          </div>
          <div className='generate-select'>
            <label>
              Select year range (Start year)
            </label>
            <Form.Select aria-label="Default select example" name='from' onChange={(e) => setData({...data, [e.target.name]: e.target.value})}>
              <option>Select year</option>
              { years.map((year, index) => {
              return (
                <option key={index} value={year}>{year}</option>
              )})}
            </Form.Select>
          </div>
          <div className='generate-select'>
            <label>
              Select year range (End year)
            </label>
            <Form.Select aria-label="Default select example" name='to' onChange={(e) => setData({...data, [e.target.name]: e.target.value})}>
              <option>Select year</option>
              { years.map((year, index) => {
              return (
                <option key={index} value={year}>{year}</option>
              )})}
            </Form.Select>
          </div>
            <p className='exam-type-text'>Check the box below to choose your exam type:</p>
            <div className='generate-radio'>
              <div>
                <input type="radio" id="community" name="mock_exam_type" value="2" onChange={(e) => setData({...data, [e.target.name]: e.target.value})}/>
                <label htmlFor="age1">Public Exam</label>
              </div>
              <div>
                <input type="radio" id="private" name="mock_exam_type" value="1"  onChange={(e) => setData({...data, [e.target.name]: e.target.value})}/>
                <label htmlFor="age2">Private Exam</label>
              </div>
            </div>
            <div className='generate-button-container'>
              <button className='generatebutton' onClick={generateexamsubmit}>
              { isLoading   ? <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner> : 'Submit'}
                
              </button>
            </div>
          <span className='generate-borderline'></span>
        </form>
      </div>
      <Exitmodal
          show={modalShow}
          onHide={goBack }
          message={message}
        />
      <p className='generate-time d-none'>
        Time: 30minutes
      </p>
    </div>
  )
}

export default Generatemockexam