import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import { useResetPassword } from '../hook/useResetPassword'
import Spinner from 'react-bootstrap/Spinner';

import '../stylesheets/Register.css'
import {ReactComponent as Logo} from '../assets/icons/examlogo2.svg'


import footericonright from '../assets/icons/footericon-right.svg'
import footericonleft from '../assets/icons/footericon-left.svg'
import { ReactComponent as Eye } from '../assets/icons/eye.svg'
import { ReactComponent as Eyeslash } from '../assets/icons/eye-slash.svg'

function Resetpassword() {
  const [view, setView] = useState(false)
  const [cview, setCview] = useState(false)
  const [password_confirmation, setPassword_confirmation] = useState('')
  const [password, setPassword] = useState('')
  const [confirmpassword, setConfirmpassword] = useState('')
  const {resetpassword, isLoading} = useResetPassword()

  const handlesubmit = async (e) => {
    e.preventDefault()
    await resetpassword(password, password_confirmation)
}

  const passwordview = () => {
    const element = document.querySelector('#password')
    element.setAttribute("type", "text");
    setView(true)
  }

  const passwordhide = () => {
    const element = document.querySelector('#password')
    element.setAttribute("type", "password");
    setView(false)
  }

  const cpasswordview = () => {
    const element = document.querySelector('#confirmpassword')
    element.setAttribute("type", "text");
    setCview(true)
  }

  const cpasswordhide = () => {
    const element = document.querySelector('#confirmpassword')
    element.setAttribute("type", "password");
    setCview(false)
  }

  const checkpassword = (e) => {
    setPassword_confirmation(e.target.value)
    if (password !== e.target.value) {
      setConfirmpassword('password dont match')
    } else {
      setConfirmpassword('')
    }
  }

  return (
    <div>
    {/* Header */}
    <div className="row justify-content-between header">
        <div className="col-lg-2 col-sm-3 col-4">
            <Logo />
        </div>
        <div className="col-lg-3 header-right col-sm-4 col-6">
            <p>
            Don’t have an account? Signup <Link to="/Register">here</Link>
            </p>
        </div>
      </div>
    {/* Content */}
    <div className='row mainbody'>
      <div className='col-lg-2'>
        <h1 className='title'>Reset Password</h1>
      </div>
      <div className='row justify-content-around pt-md-5 pt-lg-0 pt-4'>
        <div className='col-lg-7'>
        <div className='row justify-content-center'>
          <form className='col-lg-6 col-sm-6 col-8' onSubmit={handlesubmit}>
            <div className='login-input pt-3'>
              <label className='label'> Enter New Password</label>
              <div className='passwordinput'>
                <input type='password' name="password" aria-label='password' id='password' onChange={(e) => setPassword(e.target.value)} value={password} autoComplete='true'/>
                <span onClick={passwordview} className={`${view ? 'd-none' : ''}`}><Eyeslash/></span>
                <span onClick={passwordhide} className={`${view ? '' : 'd-none'}`}><Eye/></span>
              </div>
            </div>
            <div className='login-input pt-3'>
              <label className='label'> Confirm Password</label>
              <div className='passwordinput'>
                <input type="Password" name="confirmpassword" id='confirmpassword' onChange={checkpassword} value={password_confirmation}/>
                <span onClick={cpasswordview} className={`${cview ? 'd-none' : ''}`}><Eyeslash/></span>
                <span onClick={cpasswordhide} className={`${cview ? '' : 'd-none'}`}><Eye/></span>
              </div>
              <span className='errormessage text-start'>{confirmpassword}</span>
            </div>
            <button className='register-button resetpassword-button'>
            { isLoading   ? <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner> : 'Change Password'}
              </button>
          </form>
        </div>
        <div className='row justify-content-between pt-5'>
            <div className='col-lg-2 footericonleft col-sm-2 col-2'>
              <img src={footericonleft} alt="footericonleft" />
            </div>
            <div className='col-lg-2 footericonright col-sm-2 col-2'>
              <img src={footericonright} alt="footericonleft" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Resetpassword