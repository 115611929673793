import { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { API } from '../helper/action'


export const useResetPassword = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    let Navigate = useNavigate()



    const resetcode = async (code) => {
        setIsLoading(true)
        setError(null)

        let id = sessionStorage.getItem('emailid')
        id = JSON.parse(atob(id))



        const data = {
            code: code,
            email: id.email
        }

        await axios.post(`${API}/user/auth/password/verify-code`, data,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    let emailid = btoa(JSON.stringify(data))
                    sessionStorage.setItem('emailid', emailid)
                    Navigate('/resetpassword')
                }
                setIsLoading(false)

            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
    }

    const resetpassword = async (password, password_confirmation) => {
        setIsLoading(true)
        setError(null)
        let id = sessionStorage.getItem('emailid')
        id = JSON.parse(atob(id))

        const data = {
            token: id.code,
            email: id.email,
            password: password,
            password_confirmation: password_confirmation,
        }


        await axios.post(`${API}/user/auth/password/reset`, data,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    Navigate('/login')
                }
                setIsLoading(false)

            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
    }

    return { resetpassword, resetcode, isLoading, error }
}
