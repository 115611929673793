import React, { useEffect, useState } from "react";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

import "../stylesheets/Blog.css";
import { useBlog } from "../hook/blog";
import { Link } from "react-router-dom";
import Loader from "../Components/Loader";

function Singleblog() {
    const [blog, setBlog] = useState([]);
    const { singlepost, isLoading } = useBlog();

    useEffect(() => {
        singlepost(setBlog);
    }, [singlepost]);

    return (
        <div>
            <Navbar />
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    {blog.map((post, index) => {
                        return (
                            <div className="blog-container" key={index}>
                                <div className="row gtlu">
                                    <div className="col-lg-7">
                                        <h6>BLOG TOPIC</h6>
                                        <h3>{post.data_values.title}</h3>
                                    </div>
                                </div>
                                <div className="blog-grid">
                                <p dangerouslySetInnerHTML={{ __html: post.data_values.body}} />
                                    {/* <p >{post.data_values.Post}</p> */}
                                </div>
                                <Link to="/blog" className="blog-button text-white">
                                    Back To Blog Page
                                </Link>
                            </div>
                        );
                    })}
                </div>
            )}
            <Footer />
        </div>
    );
}

export default Singleblog;
