import { useState } from "react";
import axios from "axios";
import { useAuthContext } from './context'
import { useNavigate } from "react-router-dom";
import { API } from "../helper/action";
import { Cookies } from "react-cookie";

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [errorusername, setErrorusername] = useState("");
  const [erroremail, setErroremail] = useState(null);
  const [errorfirstname, setErrorfirstname] = useState(null);
  const [errorlastname, setErrorlastname] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isVerifyLoading, setIsVerifyLoading] = useState(null);
  // const [verify, setVerify] = useState(false)
  const { dispatch } = useAuthContext()
  const cookies = new Cookies();
  const maxAge = 1 * 24 * 60 * 60;

  // const maxAge = 1 * 24 * 60 * 60

  let Navigate = useNavigate();

  const signup = async (data) => {
    setIsLoading(true);
    setError(null);

    await axios
      .post(`${API}/user/auth/register`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        const token = response.data.data.token;
        if (token) {
          sessionStorage.setItem("token", token);
          await axios
            .get(`${API}/user/auth/email/resend-verification`, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.data) {
                Navigate("/login");
                setIsVerifyLoading(false);
              }
            });
          // dispatch({type : 'LOGIN' ,  token: token})
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err)
        setError(err.response.data.errors);
        setErrorusername(err.response.data.errors.username);
        setErrorfirstname(err.response.data.errors.firstname);
        setErrorlastname(err.response.data.errors.lastname);
        setErroremail(err.response.data.errors.email);
        setErrorusername(err.response.data.errors.username);
        // console.log(err.response.data.errors.username)
      });
  };


  const referal_signup = async (data, ref) => {
    setIsLoading(true);
    setError(null);

    await axios
      .post(`${API}/user/auth/register`, data, {
        params: {
          ref: ref
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        const token = response.data.data.token;
        if (token) {
          sessionStorage.setItem("token", token);
          await axios
            .get(`${API}/user/auth/email/resend-verification`, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.data) {
                Navigate("/emailverification");
                setIsVerifyLoading(false);
              }
            });
          // dispatch({type : 'LOGIN' ,  token: token})
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err)
        setError(err.response.data.errors);
        setErrorusername(err.response.data.errors.username);
        setErrorfirstname(err.response.data.errors.firstname);
        setErrorlastname(err.response.data.errors.lastname);
        setErroremail(err.response.data.errors.email);
        setErrorusername(err.response.data.errors.username);
        // console.log(err.response.data.errors.username)
      });
  };

  const emailcode = async () => {
    setIsVerifyLoading(true);
    setError(null);
    let token = sessionStorage.getItem("token");
    await axios
      .get(`${API}/user/auth/email/resend-verification`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data) {
          Navigate("/emailverification");
          setIsVerifyLoading(false);
        }
      });
  };

  const social_register = async (data) => {
    setIsLoading(true);
    setError(null);

    await axios
      .post(`${API}/user/auth/social-register`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        console.log(response);
        const token = response.data.data.token;
        const user_info = {
          firstname: response.data.data.user.firstname,
          lastname: response.data.data.user.lastname,
          username: response.data.data.user.username,
          email: response.data.data.user.email,
      }
      const user = btoa(JSON.stringify(user_info))

        cookies.set("jwt", token, {
          path: "/",
          maxAge: maxAge,
          sameSite: "lax",
          secure: true,
        });
        cookies.set("user" , user, { path: '/', maxAge: maxAge, sameSite: 'lax', secure: true });

        if (response.data) {
          dispatch({ type: "LOGIN", token: token });
          Navigate("/dashboard", { state: "login" });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.response.data.errors)
        setError(err.response.data.errors);
        setErroremail(err.response.data.errors.email);
        // console.log(err.response.data.errors.username)
      });
  };

  return {
    signup,
    social_register,
    emailcode,
    isVerifyLoading,
    isLoading,
    error,
    errorusername,
    erroremail,
    errorfirstname,
    errorlastname,
    referal_signup
  };
};
