import React, { useEffect, useState } from 'react'
import '../stylesheets/referral.css'
import { ReactComponent as Copyicon } from "../assets/icons/copyicons.svg";
import { ReactComponent as Twittericon } from "../assets/icons/twittericon.svg";
import { ReactComponent as Instagramicon } from "../assets/icons/instagramicon.svg";
import { ReactComponent as Facebookicon } from "../assets/icons/facebookicon.svg";
import { Cookies } from 'react-cookie';
import { useReferral } from '../hook/referral';
import Spinner from "react-bootstrap/Spinner";
import { useProfile } from '../hook/profile';


function Referral() {
    const [copy, setCopy] = useState(false)
    const [data, setData] = useState([
        // {
        //     name: 'Kunle Folarinwa',
        //     date: '20/07/2023',
        //     points: 150
        // }
    ])
    const cookies = new Cookies()
    let user = cookies.get('user')
    user = JSON.parse(atob(user))
    // console.log(user);
    const { referal, isLoading, get_referal } = useReferral()
    const {profile} = useProfile()

    function convertDate(date) {
        const fullyear = new Date(date);
        let year = fullyear.getFullYear();
        let month = fullyear.getMonth() + 1;
        let day = fullyear.getDate();
        day = day.toString().padStart(2, "0");
    
        month = month.toString().padStart(2, "0");
        const formattedToday = day + "/" + month + "/" + year;
        return formattedToday;
      }

    const ref = data.map((data, index) => {
        return (
            <div key={index} className='referral-list-body'>
                    <p>{data.firstname + ' ' + data.lastname}</p>
                    <p>{convertDate(data.created_at)}</p>
                    <p>{data.points} Points</p>
                </div>
        )
    })

    useEffect(() => {
        get_referal(setData)
        profile()
    }, [profile, get_referal])

    return (
        <div className='referral-container'>
            <div className='referral-list'>
                <div className='referral-list-title'>
                    <p>Friends Referred</p>
                    <p>Date Sent</p>
                    <p>Points Earned</p>
                </div>
                {data.length === 0 ? 
                <div className='ref-li'>
                    <p> No Referral</p>
                    </div>: ref}
                
            </div>
            <div className='referral-link'>
                <p>
                    Generate referral links to send to your friends and track the people that successfully registered with your links to earn spendable points!
                </p>
                {
                    user.referral === null ? 
                <div className='text-center my-4 '>
                    <button className='gen-ref-link' onClick={() => referal()}> 
                    {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Generate Referral Link"
                  )}
                        
                    </button>
                </div> :
                <div className='referral-link-container'>
                    <p className='account'>{`https://www.exams.africa/register?ref=${user.referral}`}</p>
                    <span className='text-white'
                    onClick={() => {
                        let account =
                          document.querySelector(".account").innerHTML;
                        navigator.clipboard.writeText(account);
                        setCopy(true)
                      }}>{copy ? 'copied' : <Copyicon />}</span>
                </div>
}
                <div className='share-link'>
                    <div>
                        <span>
                            Share your link via
                        </span>
                        <a href="#0" className="footer-icon ref-icon" target='_blank' rel="noreferrer">
                            <Twittericon />
                        </a>
                        <a href="https://www.instagram.com/examsafrica/" className="footer-icon ref-icon" target='_blank' rel="noreferrer">
                            <Instagramicon />
                        </a>
                        <a href="#0" className="footer-icon ref-icon" target='_blank' rel="noreferrer">
                            <Facebookicon />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Referral