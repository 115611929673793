import React, { useState } from "react";
import { ReactComponent as Speaker } from "../assets/icons/speaker.svg";
// import { ReactComponent as Pause } from "../assets/icons/pauseicon.svg";
import "../stylesheets/examcorrection.css";
import { useCorrection } from "../hook/correction";
import Typewriter from 'typewriter-effect';
import Spinner from 'react-bootstrap/Spinner';
import { useExamquestion } from "../hook/examquestion";
import Loader from "../Components/Loader";

function Questionandanswer() {
    const [pageNumber, setPageNumber] = useState(0);
    const [displayexplain, setDisplayExplain] = useState(false)
    // const [speaking, setSpeaking] = useState(false)
    const { correction, res, setRes } = useCorrection()
    const {isLoading} = useExamquestion()
    let synth = window.speechSynthesis
    const speechHandler = () => {
        const msg = new SpeechSynthesisUtterance()
        let voices = synth.getVoices()
        msg.text = document.getElementsByClassName('Typewriter__wrapper')[0].innerHTML
        // let speaking = synth.speaking
        // setSpeaking(speaking)
        msg.voice = voices.filter(function (voice) { return voice.name === 'Google UK English Female'; })[0];
        synth.speak(msg)
    }
    let examquestion = JSON.parse(atob(localStorage.getItem('q&a')))
    let data = examquestion.Questions
    // const pauseSpeech = () => {
    //     synth.pause()
    // }

    // console.log(synth.speaking);

    let examperpage = 1;
    let pagesVisted = pageNumber + examperpage;
    function decrement() {
        setPageNumber(pageNumber - 1);
        setDisplayExplain(false)
        setRes('')
    }
    function increment() {
        setPageNumber(pageNumber + examperpage);
        setDisplayExplain(false)
        setRes('')
    }

    const morecontext = async () => {
        let explain = document.getElementsByClassName('Typewriter__wrapper')[0].innerHTML
        await correction(explain)
    }

   

    return (
        <>
            {
            isLoading ? <Loader /> : 
            <div>
            <div className="question-instruction">
                <p>View all question and answer below</p>
            </div>
            
            <div className="correction-section">
                {
                    data.slice(pageNumber, pagesVisted).map((post, index) => {
                        return (
                            <div className="correction-container" key={index}>
                                <p className="question-number">Question {post.question_number}</p>
                                <div className="answer-container">
                                    <p dangerouslySetInnerHTML={{ __html: post.question }} />
                                    <div className="correct-answer">
                                        <div className="correct-answer-title">
                                            <span></span>
                                            <p>Correct answer:</p>
                                        </div>
                                        <div className="answer-title no-dot">
                                            <span></span>
                                            {
                                                post.options.map((option, index) => {
                                                    return (
                                                        <p key={index} className={`${option.correct_ans === 1 ? '' : 'd-none'}`}>
                                                            {option.option}
                                                        </p>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="explain-answer">
                                        <button className={`explanation-button ${displayexplain ? 'd-none' : ''}`} onClick={() => setDisplayExplain(true)}>View Explanation</button>
                                        <div className={`explain-answer-title no-dot  ${displayexplain ? '' : 'd-none'}`} >
                                            <span></span>
                                            <p>Explanation:</p>
                                        </div>
                                        <div className={`answer-title no-dot1  ${displayexplain ? '' : 'd-none'}`}>
                                            <span></span>
                                            <Typewriter
                                                options={{
                                                    strings: `${post.explanation === null ? 'Coming soon' : post.question.explanation}`,
                                                    autoStart: true,
                                                    delay: 75,
                                                    cursorClassName: 'typewritercursor'
                                                }}
                                            />
                                        </div>
                                        <div className={`answer-title no-dot1  ${res !== '' ? 'line-2' : 'd-none'}`}>
                                            <span></span>
                                            <Typewriter
                                                options={{
                                                    strings: `${res}`,
                                                    autoStart: true,
                                                    delay: 75
                                                }}
                                            />
                                        </div>
                                        <div className={`more-container ${post.explanation === null ? 'd-none' : ''}`}>
                                            <div className={`speaker `} >
                                                <Speaker onClick={() => speechHandler()} />
                                            </div>
                                            <button onClick={morecontext}>
                                                {isLoading ? <Spinner /> : 'More Context'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="pag-button-container">
                <button className={`pag-button ${pageNumber === 0 ? 'd-none' : ''}`} onClick={decrement}>
                    Previous
                </button>
                <button className={`pag-button ${pageNumber === data.length - 1 ? 'd-none' : ''}`} onClick={increment}>
                    Next
                </button>
            </div>
        </div>
        }
        </>
    );
}

export default Questionandanswer;
