import axios from "axios";
import { useCallback, useState } from "react";

export const useCorrection = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [contextLoading, setContextLoading] = useState(null);
    const [res, setRes] = useState('')

    const correction = useCallback(
        async (question, setNeedShow) => {
            setIsLoading(true);
            setContextLoading(true)
            setError(null);
            let data = {
                "prompt": question
            }
    
            await axios
                .post(`https://v1.nocodeapi.com/bitstack/chatgpt/CvMfUQQBDgqdfbLU/search`, data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    setIsLoading(false);
                    setContextLoading(false)
                    setNeedShow(true)
                    // console.log(response.data);
                    setRes(response.data)
                })
                .catch((err) => {
                    setIsLoading(false);
                    setContextLoading(false)
                    setError(err);
                });
        }, []
    )

    return { isLoading, error, correction, res, setRes, contextLoading };
};
