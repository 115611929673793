import React from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Needcontext } from "../assets/icons/needcontext.svg";

function Morecontext(props) {
    console.log(props.res);
    return (
        <div>
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // className="confirm-modal"
            >
                <Modal.Body>
                    <div className="d-flex">
                        <span>
                            <Needcontext />
                        </span>
                        <div className="question-progress-info w-100">
                            <p>Need context?</p>
                            {/* <p>The national secretariat will be most likely be place under the authority of leading figure heads in the political party.The national secretariat will be most likely be place under the authority of leading figure heads in the political party. With this try and figure out the right answer.</p> */}
                            <p>{ props.res !== '' ? props.res : 'Coming Soon'}</p>
                            <button className="click" onClick={() => props.onHide()} >Close</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Morecontext;
