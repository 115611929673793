import React, { useCallback, useEffect, useRef, useState } from "react";
import { useChangepassword } from "../hook/useChangepassword";
import { useUpdateprofile } from "../hook/useUpdateprofile";
import Spinner from "react-bootstrap/Spinner";
import { Cookies } from "react-cookie";

import "../stylesheets/profilesettings.css";
import { ReactComponent as Cancel } from "../assets/icons/cancel.svg";
// import {ReactComponent as User1} from "../assets/icons/user.png"
import pfsettingsicon from "../assets/icons/pfsettingsicon.svg";
import pfpasswordicon from "../assets/icons/pfpasswordicon.svg";
import examinteresticon from "../assets/icons/examinteresticon.svg";
import user1 from "../assets/icons/user.png";
import { useProfile } from "../hook/profile";

function Profilesettings() {
  const [tab, setTab] = useState("profile");
  const [forgetpass, setForgetpass] = useState(false);
  const click = useRef("");
  const [show, setShow] = useState(false);
  const [filename, setFilename] = useState("");
  const { profile, fetchinterest, interest, setInterest } = useProfile();
  const { changepassword, isLoading, successmessage } = useChangepassword();
  const {
    updateprofile,
    profileisLoading,
    profilesuccessmessage,
    updateinterest,
    message,
    interestisLoading,
  } = useUpdateprofile();
  const cookies = new Cookies();
  let user = cookies.get("user");
  user = JSON.parse(atob(user));

  const [upload, setUpload] = useState(user1);
  const [userinfo, setUserinfo] = useState({
    firstname: user.firstname || "",
    lastname: user.lastname || "",
    image: user.image || "",
    address: user.address || "",
    state: user.state || "",
    city: user.city || "",
    email: user.email || "",
    mobile: user.mobile || "",
    country: user.country || "",
  });

  const [pickedinterest] = useState({
    "JAMB - UTME": false,
    "WAEC - SSCE": false,
    "WAEC - GCE": false,
    NECO: false,
    NOUN: false,
    NURSING: false,
    IETLS: false,
  });

  const [current_password, setCurrent_password] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [data, setData] = useState({});

  const handleclick = () => {
    setTab("profile");
  };
  const tabclick = () => {
    setTab("password");
  };

  const file = () => {
    click.current.click();
  };

  const forgetpassword = () => {
    setForgetpass(!forgetpass);
  };

  const submitchangepassword = async (e) => {
    e.preventDefault();
    await changepassword(current_password, password, password_confirmation);
  };

  const submitupdateprofile = async (e) => {
    e.preventDefault();
    await updateprofile(userinfo, setShow, show);
    window.location.reload(false);
  };
  const valuearray = Object.values(interest);
  const limitcheck = valuearray.filter((valuearray) => valuearray === true);
  const checkinterest = Object.keys(interest).filter(
    (key) => interest[key] === true
  );

  // const checkinterest = Object.keys(interest).filter(
  //   (key) => interest[key] === true
  // );
  // let examids;
  // checkinterest.map((lamb) => {
  //   switch (lamb) {
  //     case "JAMB - UTME":
  //       examids = "1";
  //       break;
  //     case "WAEC - SSCE":
  //       examids = "2";
  //       break;
  //     case "WAEC - GCE":
  //       examids = "3";
  //       break;
  //     case "NECO":
  //       examids = "4";
  //       break;
  //     case "NOUN":
  //       examids = "5";
  //       break;
  //     default:
  //       examids = "0";
  //   }
  //   return (data[`category_id[${examids}]`] = examids);
  // });;


  
  const handlechecked = useCallback (
    (e) => {
      setInterest({ ...interest, [e.target.name]: e.target.checked });
      
      let examids;
      checkinterest.map((lamb) => {
        switch (lamb) {
          case "JAMB - UTME":
            examids = "1";
            break;
          case "WAEC - SSCE":
            examids = "2";
            break;
          case "WAEC - GCE":
            examids = "3";
            break;
          case "NECO":
            examids = "4";
            break;
          case "NOUN":
            examids = "5";
            break;
          default:
            examids = "0";
        }
        return (data[`category_id[${examids}]`] = examids);
      })
      let examid;
      switch (e.target.name) {
        case "JAMB - UTME":
          examid = "1";
          break;
        case "WAEC - SSCE":
          examid = "2";
          break;
        case "WAEC - GCE":
          examid = "3";
          break;
        case "NECO":
          examid = "4";
          break;
        case "NOUN":
          examid = "5";
          break;
        default:
          examid = "0";
      }
      if (e.target.checked) {
        data[`category_id[${examid}]`] = examid;
      } else {
        setInterest({ ...interest, [e.target.name]: false });
        setData((current) => {
          const copy = { ...current };
          delete copy[`category_id[${examid}]`];
          return copy;
        });
      }
    }, [data, interest, setInterest, checkinterest]
  )

  const submitupdateinterest = async (e) => {
    e.preventDefault();
    let pickedinterests = {};
    const checkinterest = Object.keys(pickedinterest).filter(
      (key) => pickedinterest[key] === true
    );
    let examids;
    checkinterest.map((lamb) => {
      switch (lamb) {
        case "JAMB - UTME":
          examids = "1";
          break;
        case "WAEC - SSCE":
          examids = "2";
          break;
        case "WAEC - GCE":
          examids = "3";
          break;
        case "NECO":
          examids = "4";
          break;
        case "NOUN":
          examids = "5";
          break;
        default:
          examids = "0";
      }
      return (pickedinterests[`category_id[${examids}]`] = examids);
    });

    await updateinterest(pickedinterests, data);
  };

  useEffect(() => {
    profile();
    fetchinterest(pickedinterest);

  }, [profile, fetchinterest, interest, pickedinterest]);

  return (
    <div>
      <div className="profilesettingbox">
        <div className=" psboxtitle">
          <p
            className={`${tab === "profile" ? "activetitle " : "notactive"}`}
            onClick={handleclick}
          >
            <img src={pfsettingsicon} alt="pfsettingsicon" />
            <span>Profile settings</span>
          </p>
          <p
            className={`${tab === "password" ? "activetitle" : "notactive"}`}
            onClick={tabclick}
          >
            <img src={pfpasswordicon} alt="pfpasswordicon" />
            <span>Change Password</span>
          </p>
          <p
            className={`${tab === "interest" ? "activetitle" : "notactive"}`}
            onClick={() => setTab("interest")}
          >
            <img src={examinteresticon} alt="pfpasswordicon" />
            <span>Exam Interests</span>
          </p>
        </div>
        <div>
          <div className={`${tab === "profile" ? "activetab " : "nonetab"}`}>
            <div>
              <form className={show ? "d-none" : ""}>
                {/* <div className="imagebox">
                  <span className="pfp">
                    <img src={userinfo.image} alt="profilepicture" />
                  </span>
                </div> */}
                <div className="psinput">
                  <div>
                    <label>First Name</label>
                    <p>{user.firstname}</p>
                  </div>
                  <div className="mobileinput">
                    <label>Last Name</label>
                    <p>{user.lastname}</p>
                  </div>
                  <div>
                    <label>Country</label>
                    <p>Nigeria</p>
                  </div>
                </div>
                <div className="psinput">
                  <div className="mobileinput-l">
                    <label>Last Name</label>
                    <p>{user.lastname}</p>
                  </div>
                  <div>
                    <label>Address</label>
                    <p>{user.address === null ? "None" : user.address}</p>
                  </div>
                </div>
                <div className="psinput">
                  <div>
                    <label>Email Address</label>
                    <p>{user.email}</p>
                  </div>
                  <div>
                    <label>State</label>
                    <p>{user.state === null ? "None" : user.state}</p>
                  </div>
                </div>
                <div className="psinput">
                  <div>
                    <label>Phone Number</label>
                    <p>{user.mobile === null ? "None" : user.mobile}</p>
                  </div>
                  <div className="psinput1">
                    <div>
                      <label>City</label>
                      <p>{user.city === null ? "None" : user.city}</p>
                    </div>
                    {/* <div>
                        <label>
                          Zip Code
                        </label>
                        <p>Sunmonu</p>
                      </div> */}
                  </div>
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(!show);
                  }}
                >
                  Edit
                </button>
                <span className="successmessageprofile d-block text-center mt-2">
                  {profilesuccessmessage === "Profile Updated Successfully"
                    ? profilesuccessmessage
                    : ""}
                </span>
              </form>
              <form
                onSubmit={submitupdateprofile}
                className={show ? "" : "d-none"}
              >
                <div className="imagebox d-none">
                  <span className="pfp">
                    <img src={upload} alt="profilepicture" />
                  </span>
                  <div className="d-block">
                    <input
                      filename={userinfo.image}
                      type="file"
                      ref={click}
                      onChange={(e) => {
                        setUserinfo({ ...userinfo, image: e.target.files[0] });
                        setUpload(URL.createObjectURL(e.target.files[0]));
                        setFilename(e.target.files[0].name);
                      }}
                      hidden
                    />
                    <span onClick={file}>Update Photo</span>
                    <p>{filename}</p>
                    <p>Update your profile picture and personal details</p>
                  </div>
                </div>
                <div className="psinput">
                  <div>
                    <label>First Name</label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setUserinfo({ ...userinfo, firstname: e.target.value })
                      }
                      value={userinfo.firstname}
                      readOnly
                    />
                  </div>
                  <div className="mobileinput">
                    <label>Last Name</label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setUserinfo({ ...userinfo, lastname: e.target.value })
                      }
                      value={userinfo.lastname}
                      readOnly
                    />
                  </div>
                  <div>
                    <label>Address</label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setUserinfo({ ...userinfo, address: e.target.value })
                      }
                      value={userinfo.address}
                      required
                    />
                  </div>
                </div>
                <div className="psinput">
                  <div className="mobileinput-l">
                    <label>Last Name</label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setUserinfo({ ...userinfo, lastname: e.target.value })
                      }
                      value={userinfo.lastname}
                      readOnly
                    />
                  </div>
                  <div>
                    <label>State</label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setUserinfo({ ...userinfo, state: e.target.value })
                      }
                      value={userinfo.state}
                      required
                    />
                  </div>
                </div>
                <div className="psinput">
                  <div>
                    <label>Email Address</label>
                    <input
                      type="email"
                      onChange={(e) =>
                        setUserinfo({ ...userinfo, email: e.target.value })
                      }
                      value={userinfo.email}
                      readOnly
                    />
                  </div>
                  <div>
                    <label>City</label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setUserinfo({ ...userinfo, city: e.target.value })
                      }
                      value={userinfo.city}
                      required
                    />
                  </div>
                </div>
                <div className="psinput psinput-mobile">
                  <div>
                    <label>Phone Number</label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setUserinfo({ ...userinfo, mobile: e.target.value })
                      }
                      value={userinfo.mobile}
                      readOnly
                    />
                  </div>
                </div>
                <div className="profile-edit-submit">
                  <p onClick={() => setShow(false)}>Back</p>
                  <button>
                    {profileisLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
                <span className="successmessageprofile d-block text-center mt-2">
                  {profilesuccessmessage === "Profile Updated Successfully"
                    ? profilesuccessmessage
                    : ""}
                </span>
              </form>
            </div>
          </div>
          {/* forget password tab */}
          <div className={`${tab === "password" ? "activetab" : "nonetab"}`}>
            <div
              className={`${forgetpass ? "cancelicon" : "nonetab"}`}
              onClick={forgetpassword}
            >
              <Cancel />
            </div>
            <form
              className={` ${forgetpass ? "nonetab" : "pspassword"}`}
              onSubmit={submitchangepassword}
            >
              <label>Current Password</label>
              <input
                type="password"
                onChange={(e) => setCurrent_password(e.target.value)}
                value={current_password}
                autoComplete="false"
              />
              <span
                className={`errormessageprofile ${
                  successmessage === "Current password not match"
                    ? ""
                    : "d-none"
                }`}
              >
                {successmessage === "Current password not match"
                  ? successmessage
                  : ""}
              </span>
              <label>New Password</label>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                autoComplete="false"
              />
              <label>Confirm Password</label>
              <input
                type="password"
                onChange={(e) => setPassword_confirmation(e.target.value)}
                value={password_confirmation}
                autoComplete="false"
              />
              <button>
                {isLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Change Password"
                )}
              </button>
              <span className="successmessageprofile">
                {successmessage === "Password Changed successfully."
                  ? successmessage
                  : ""}
              </span>
              <p className="fpassword" onClick={forgetpassword}>
                Forgot password?
              </p>
            </form>
            <div className={`${forgetpass ? "forgetpass" : "nonetab"}`}>
              <form className="psemaireset">
                <p>
                  Enter the e-mail associated with your account and a reset code
                  will be sent to you
                </p>
                <label>Enter Email Address</label>
                <input type="email" />
                <button>Send Reset Code</button>
              </form>
            </div>
          </div>
          {/* { interest } */}
          <div className={`${tab === "interest" ? "activetab" : "nonetab"}`}>
            <div className="text-start interest-info">
              <span></span>
              <p>Kindly add and save your exams of interest</p>
            </div>
            <form className="interest-form" onSubmit={submitupdateinterest}>
              <div>
                <label htmlFor="JAMB - UTME">JAMB - UTME</label>
                <input
                  type="checkbox"
                  name="JAMB - UTME"
                  checked={interest["JAMB - UTME"]}
                  onChange={handlechecked}
                  disabled={
                    interest["JAMB - UTME"]
                      ? false
                      : limitcheck.length >= 3
                      ? true
                      : false
                  }
                />
              </div>
              <div>
                <label htmlFor="WAEC - SSCE">WAEC - SSCE</label>
                <input
                  type="checkbox"
                  name="WAEC - SSCE"
                  checked={interest["WAEC - SSCE"]}
                  onChange={handlechecked}
                  disabled={
                    interest["WAEC - SSCE"]
                      ? false
                      : limitcheck.length >= 3
                      ? true
                      : false
                  }
                />
              </div>
              <div>
                <label htmlFor="WAEC - GCE">WAEC - GCE</label>
                <input
                  type="checkbox"
                  name="WAEC - GCE"
                  checked={interest["WAEC - GCE"]}
                  onChange={handlechecked}
                  disabled={
                    interest["WAEC - GCE"]
                      ? false
                      : limitcheck.length >= 3
                      ? true
                      : false
                  }
                />
              </div>
              <div>
                <label htmlFor="NECO">NECO</label>
                <input
                  type="checkbox"
                  name="NECO"
                  checked={interest.NECO}
                  onChange={handlechecked}
                  disabled={
                    interest.NECO
                      ? false
                      : limitcheck.length >= 3
                      ? true
                      : false
                  }
                />
              </div>
              <div>
                <label htmlFor="NOUN">NOUN</label>
                <input
                  type="checkbox"
                  name="NOUN"
                  checked={interest.NOUN}
                  onChange={handlechecked}
                  disabled={
                    interest.NOUN
                      ? false
                      : limitcheck.length >= 3
                      ? true
                      : false
                  }
                />
              </div>
              <div>
                <label htmlFor="NURSING">NURSING</label>
                <input
                  type="checkbox"
                  name="NURSING"
                  checked={interest.NURSING}
                  onChange={handlechecked}
                  disabled={
                    interest.NURSING
                      ? false
                      : limitcheck.length >= 3
                      ? true
                      : false
                  }
                />
              </div>
              <div>
                <label htmlFor="IETLS">IETLS</label>
                <input
                  type="checkbox"
                  name="IETLS"
                  // value={(e) => e.target.checked ? 'true' : 'false'}
                  onChange={handlechecked}
                  disabled={
                    interest.IETLS
                      ? false
                      : limitcheck.length >= 3
                      ? true
                      : false
                  }
                />
              </div>
              <button>
                {" "}
                {interestisLoading ? (
                  <p className="h13">loading</p>
                ) : (
                  "Add & Save Changes"
                )}{" "}
              </button>
              <p className={`${interestisLoading ? "d-none" : ""}`}>
                {message}
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profilesettings;
