import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

import "../stylesheets/Modal.css";

import {ReactComponent as Arrowback} from "../assets/icons/arrowback.svg";
import {ReactComponent as Transfericon} from "../assets/icons/transfericon.svg";
import {ReactComponent as Processicon} from "../assets/icons/processicon.svg";
// import {ReactComponent as Ussdicon} from "../assets/icons/ussdicon.svg";
import {ReactComponent as Vouchericon} from "../assets/icons/vouchericon.svg";
import { ReactComponent as Logo } from "../assets/icons/modallogo.svg";
import { ReactComponent as Successimg } from "../assets/icons/successimg.svg";
import { ReactComponent as Copyicon } from "../assets/icons/copyicon.svg";
import { usePayment } from "../hook/payment";

function Success(props) {
  return (
    <div className="row justify-content-center">
      <div className="arrow-cursor" >
        <Arrowback onClick={() => props.setUssdcode('')}/>
      </div>
      <div
        className={`col-lg-8 col-8 text-center res-mesg ${
          props.ussdcode === "success" ? "successcolor" : "errorcolor"
        }`}
      >
        <Successimg />
        <h3>{props.ussdcode === "success" ? "SUCCESS!" : "Error!"}</h3>
        <p>
          {props.ussdcode === "success"
            ? "Voucher entered successfully and payment has been completed. Go back to dashboard to begin enjoying all features"
            : "Voucher entry failed and payment has not been completed. Go back to voucher page, try entering the code again to enjoy all features"}
        </p>
        {props.ussdcode === 'success' ? <Link to='/transactionhistory' className="tran-history">Transaction History</Link> : <p></p>}
      </div>
    </div>
  );
}

function Processpayment(props) {
  return (
    <div className={`process ${props.process === 'paid' ? '' : 'd-none'}`}>
      <Processicon />
      <h1>
        Processing!
      </h1>
      <p>
        Your request is being processed, kindly check your transaction history for the status of your transaction <Link to='/transactionhistory'>here</Link>
      </p>
      <p>
        Have any complaints? contact our support 
      </p>
    </div>
  )
}

function Modalsub(props) {
  const [tab, setTab] = useState("transfer");
  const [ussdcode, setUssdcode] = useState('');
  const [paid, setPaid] = useState('')
  const [data, setData] = useState({
    voucher_code: ''
  })
  // const navigate = useNavigate();
  const {isLoading, voucher} = usePayment()

  const voucherpayment = async(e) => {
    e.preventDefault()
    await voucher(data, setUssdcode)
  }

 const generateaccount = async() => {
  setTab("transfer");
    // getaccount(props.data.amount, setAccount)
  }
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className=" psboxtitle">
          {/* <p
            className={`${tab === "Card" ? "activetitle" : "notactive"}`}
            onClick={() => {
              setTab("Card");
            }}
          >
            <Cardicon />
            <span>Card</span>
          </p> */}
          <p
            className={`${tab === "transfer" ? "activetitle" : "notactive"}`}
            onClick={generateaccount}
          >
            <Transfericon />
            <span>Bank transfer</span>
          </p>
          {/* <p
            className={`${tab === "ussd" ? "activetitle" : "notactive"}`}
            onClick={() => {
              setTab("ussd");
            }}
          >
            <Ussdicon />
            <span>USSD</span>
          </p> */}
          <p
            className={`${tab === "voucher" ? "activetitle" : "notactive"}`}
            onClick={() => {
              setTab("voucher");
            }}
          >
            <Vouchericon />
            <span>Voucher</span>
          </p>
        </div>
        <Modal.Body>
          <div className={`row ${tab === "transfer" ? "modaltab" : "nonetab"}`}>
            <div className={`col-lg-6 transfercontainer col-auto ${paid === '' ? '' : 'd-none'}`}>
              <p>Proceed to your bank app to complete this transfer</p>
              <div className="transferinfo">
                <div>
                  <p>Account Number</p>
                  <div className="d-flex">
                  {props.account === undefined ? <p className="account h12">loading</p> : props.accountloading === 'true' ? <p className="account h12">loading</p> : <p className="account">{props.account.accountInformation.accountNumber}</p>}
                    
                    <span className={props.account === undefined ? 'd-none' : props.accountloading === 'true' ? 'd-none' : 'd-block'}
                      onClick={() => {
                        let account =
                          document.querySelector(".account").innerHTML;
                        navigator.clipboard.writeText(account);
                      }}
                    >
                      COPY
                    </span>
                  </div>
                </div>
                <div>
                  <p>Bank Name</p>
                  <div className="">
                  {props.account === undefined ? <p className="account h12 text-start">loading</p> : props.accountloading === 'true' ? <p className="account h12 text-start">loading</p> : <p className="account">{props.account.accountInformation.bankName}</p>}
                    {/* <p>{props.account === undefined ? '...' : props.accountloading === 'true' ? '...' : props.account.accountInformation.bankName}</p> */}
                  </div>
                </div>
                <div>
                  <p>Account Name</p>
                  <div className="">
                  {props.account === undefined ? <p className="account h12 text-start">loading</p> : props.accountloading === 'true' ? <p className="account h12 text-start">loading</p> : <p className="account">Exams Africa limited ({props.account.accountInformation.accountName})</p>}
                    {/* <p>{props.account === undefined ? '...' : props.accountloading === 'true' ? '...' : `Exams Africa limited (${props.account.accountInformation.accountName})`}</p> */}
                  </div>
                </div>
              </div>
              <div className="pricetab">
              <div>
                <p>NGN{props.data.amount}</p>
                <p className="pricetag">{props.data.description}</p>
              </div>
            </div>
              <button disabled={props.accountloading === 'true' ? true : false} onClick={() => setPaid("paid")}>I have made this bank transfer</button>  
            </div>
            <div className={`col-lg-3 col-4 pricebox ${paid === '' ? '' : 'd-none'}`}>
              <div>
                <p>NGN{props.data.amount}</p>
                <p className="pricetag">{props.data.description}</p>
              </div>
            </div>
            <Processpayment process={paid} />
          </div>
          <div className={`row ${tab === "ussd" ? "modaltab" : "nonetab"}`}>
            <form className="col-lg-6 col-auto">
              <p>
                Please select your choice of bank and the accurate USSD code can
                be seen beside it, tap on the button below to dial it and
                complete payment
              </p>
              <span className="copy"
                  onClick={() => {
                    let account =
                      document.querySelector(".ussdcode").value;
                    navigator.clipboard.writeText(account);
                  }}
                >
                  <Copyicon />
                  <span>COPY USSD CODE</span>
              </span>
              <label>Select bank</label>
              <div className="d-flex">
                <input type="text" value={"*901*2500*2456781948#"} readOnly={true} className="ussdcode"/>
                <select>
                  <option>Access bank</option>
                </select>
              </div>
              <div className="pricetab">
              <div>
                <p>NGN{props.data.amount}</p>
                <p className="pricetag">Six month plan Bill due Bi-Annually</p>
              </div>
            </div>
              <button>Pay NGN{props.data.amount}</button>
            </form>
            <div className="col-lg-3 col-4 pricebox">
              <div>
                <p>NGN{props.data.amount}</p>
                <p className="pricetag">Six month plan Bill due Bi-Annually</p>
              </div>
            </div>
          </div>
          <div className={`row ${ussdcode !== '' ? 'modaltab1' : tab === "voucher" ? "modaltab" :  "nonetab"}`}>
            <form
              className={`col-lg-5 col-sm-5 col-8 ${ussdcode === "" ? "d-block" : "d-none"}`}
              onSubmit={voucherpayment}
            >
              <p>
                Please enter an exams.africa voucher worth NGN{props.data.amount} to complete
                payment
              </p>
              <label>Voucher Code</label>
              <input type="number" onChange={(e) => setData({...data, voucher_code: e.target.value})} required/>
              <button
                // onClick={(e) => {
                //   e.preventDefault();
                //   setUssdcode("success");
                // }}
                // onClick={voucherpayment}
              >
                {isLoading ? <p className=" h14">loading</p> : 'Send Code'}
              </button>
            </form>
            <div
              className={`border-0  ${
                ussdcode === "success"
                  ? "d-block"
                  : ussdcode === "error"
                  ? "d-block"
                  : "d-none"
              }`}
            >
              <Success ussdcode={ussdcode} setUssdcode={setUssdcode} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalfooter">
          <Logo />
          {/* <Button onClick={props.onHide}>Close</Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Modalsub;
