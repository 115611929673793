import React, { useEffect } from 'react'
import Table from 'react-bootstrap/Table';
import Loader from "../Components/Loader";
import { usePoint } from '../hook/point';

function Pointshistory() {
    const { point, isLoading, history } = usePoint()
    function convertDate(date) {
        const fullyear = new Date(date);
        let year = fullyear.getFullYear();
        let month = fullyear.getMonth() + 1;
        let day = fullyear.getDate();
        day = day.toString().padStart(2, "0");

        month = month.toString().padStart(2, "0");
        const formattedToday = day + "/" + month + "/" + year;
        return formattedToday;
    }

    useEffect(() => {
        point()
    }, [point])
    return (
        <div>
            {
                isLoading ? <Loader /> :
                    <div className='transactionhistorycontent'>
                        {history.length === 0 ? <p className='no-history'>No Point history</p> :
                            <Table className='transactionhistorytable text-center' borderless variant='white'>
                                <thead>
                                    <tr className='transaction-header'>
                                        <th>Date</th>
                                        <th>Previous Balance</th>
                                        <th>Point</th>
                                        <th>Current Balance</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        history.map((res, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{convertDate(res.updated_at)}</td>
                                                    <td>{res.previous_point}</td>
                                                    <td>{res.point}</td>
                                                    <td>{res.current_point}</td>
                                                    <td className={`${res.action === 'credit' ? 'succes-color' : res.action === 'debit' ? 'text-danger' : ''}`}>{res.action}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>}
                    </div>
            }
        </div>
    )
}

export default Pointshistory