import React from 'react'
import { useNavigate } from "react-router-dom";
import "../stylesheets/termsandprivacy.css";
import { ReactComponent as Arrowback } from '../assets/icons/arrowback.svg'

function Privacy() {
    const navigate = useNavigate()

    return (
        <div className="termsandcondition">
            <div className="termsback">
                <Arrowback onClick={() => navigate(-1)}/>
            </div>
            <h1 className="text-center">Privacy Policy </h1>
            <div>
                <p>
                    At Examsafrica, we take your privacy seriously. We are committed to protecting the personal
                    information that you share with us, and we want you to be aware of how we use it
                </p>
                <p>
                    We collect personal information when you create an account with us, such as your name,
                    email address, and password. We also collect information about your usage of our edtech
                    product, such as which features you use and how often you access them.
                </p>
                <p>
                    We use your personal information to provide you with access to our edtech product and to
                    improve your experience. We may also use your information to send you updates about our
                    product or to contact you about any issues or concerns you may have.
                </p>
                <p>
                    We will not share your personal information with any third parties, except as required by law
                    or to protect our rights and interests.
                </p>
                <p>
                    If you have any questions or concerns about our privacy policy, please contact us at
                    hello@mockexams.ng. We are always happy to help.
                </p>
            </div>
        </div>
    )
}

export default Privacy