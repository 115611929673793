import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';

import Navbar from '../Components/Navbar'
import Footer from "../Components/Footer";

import "../stylesheets/faq.css";
import blogimg from "../assets/images/blogimg.jpg";
import arrowicon from "../assets/icons/arrowiconwhite.svg";
import { useBlog } from '../hook/blog';
import { Link } from 'react-router-dom';

function Faq() {
  const [blog, setBlog] = useState([])
  const { recentblog } = useBlog()

  useEffect(() => {
    recentblog(setBlog)
  }, [recentblog])

  return (
    <div>
      <Navbar />
      <div className='faq-container'>
        <div className='row wdpua'>
          <div className='col-lg-7'>
            <h6>
              FAQ
            </h6>
            <h3>
              What do people usually ask?
            </h3>
            <p>
              We Will Open The World Of Knowledge For You! This is the latest online examination system you will ever need!
            </p>
          </div>
        </div>
        <Accordion className='row justify-content-around accord'>
          <div className='col-lg-6 col-sm-6'>
            <Accordion.Item eventKey="0">
              <Accordion.Header className='accord-title'>What is Exams Africa?</Accordion.Header>
              <Accordion.Body>
                Exams Africa is a platform designed to help Africans prepare prepare for exams. We offer a comprehensive collection of past exam questions from a wide range of subjects, allowing users to test their knowledge and improve their skills.
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div className='col-lg-6 col-sm-6'>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Who can use Exams Africa?</Accordion.Header>
              <Accordion.Body>
                Exams Africa is designed for anyone who is looking to practise for upcoming exams. This includes students preparing for high school or college exams, as well as professionals looking to advance their careers.
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div className='col-lg-6 col-sm-6'>
            <Accordion.Item eventKey="2">
              <Accordion.Header>What subjects are covered on Exams Africa?</Accordion.Header>
              <Accordion.Body>
                Our platform offers exam questions from a variety of subjects, including math, science, English, and history. We are constantly updating our collection to ensure that we have the most current and relevant exam questions available.
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div className='col-lg-6 col-sm-6'>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Is Exams Africa free to use?</Accordion.Header>
              <Accordion.Body>
              Exams.Africa isn't free but comes with a reasonable subscription module. We believe that everyone should have access to the resources they need to succeed, regardless of their financial situation.
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div className='col-lg-6 col-sm-6'>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Can I track my progress on Exams Africa?</Accordion.Header>
              <Accordion.Body>
                Yes, our platform includes a tracking system that allows users to monitor their progress and identify areas where they need to improve. This allows users to focus their studying and maximize their exam performance.
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div className='col-lg-6 col-sm-6'>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Is Exams Africa available on mobile devices?</Accordion.Header>
              <Accordion.Body>
                Yes, our platform is fully mobile-responsive and you can also download our mobile app, allowing users to access and use it on their smartphones and tablets. This makes it easy to practise for exams on the go, anytime, anywhere.
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div className='col-lg-6 col-sm-6'>
            <Accordion.Item eventKey="6">
              <Accordion.Header>How often are the exam questions on Exams Africa updated?</Accordion.Header>
              <Accordion.Body>
                We are constantly updating our collection of exam questions to ensure that they are current and relevant. This means that users can trust that the questions they are practising with on our platform are reflective of what they will encounter on their actual exams.
              </Accordion.Body>
            </Accordion.Item>
          </div>
        </Accordion>
        <div className='row gelnf'>
          <h3 className='text-center'>
            Get Every Latest News Feed
          </h3>
          <span></span>
          <div className='faq-grid'>
            {
              blog.map((post, index) => {
                return (
                  <div className='' key={index}>
                    <div>
                      <img src={blogimg} alt="blogimg" />
                    </div>
                    <h5>
                      {post.title}
                    </h5>
                    <p>
                      Start your revision early. There is no substitute for starting early with revision. You need to give yourself enough time to review everything that you have studied, and make sure that.......
                    </p>
                    <Link className='blog-button' onClick={() => localStorage.setItem('postid', post.id)}>
                      <p>
                        View This Article
                      </p>
                      <img src={arrowicon} alt="arrowicon" />
                    </Link>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Faq