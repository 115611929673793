import { useState } from 'react'
import axios from 'axios'
import { useAuthContext } from './context'
import { useNavigate } from 'react-router-dom'
import { Cookies } from 'react-cookie';
import { API } from '../helper/action';

export const useLogin = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const cookies = new Cookies();
    const { dispatch } = useAuthContext()

    const maxAge = 1 * 24 * 60 * 60
    
    let Navigate = useNavigate()

    const login = async (username, password) => {
        setIsLoading(true)
        setError(null)

        const data = {
            user_login: username,
            password: password
        }

        await axios.post(`${API}/user/auth/login`, data ,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            // const user = response.data.data.user
            // console.log(response)
            const token = response.data.data.token
            let user = {
                firstname: response.data.data.user.firstname,
                lastname: response.data.data.user.lastname,
                username: response.data.data.user.username,
                email: response.data.data.user.email,
                mobile: response.data.data.user.mobile,
                address: response.data.data.user.address.address,
                state: response.data.data.user.address.state,
                zip: response.data.data.user.address.zip,
                country: response.data.data.user.address.country,
                city: response.data.data.user.address.city,
                referral: response.data.data.user.referral_id
            }
            user = btoa(JSON.stringify(user))
            cookies.set("jwt" , token, { path: '/', maxAge: maxAge, sameSite: 'lax', secure: true });
            cookies.set("user" , user, { path: '/', maxAge: maxAge, sameSite: 'lax', secure: true });
                if(response.data) {
                    
                    dispatch({type : 'LOGIN' ,  token: token})
                    Navigate('/dashboard', {state: 'login'})
                }
                setIsLoading(false)
            
        }).catch((err) => {
            setIsLoading(false)
            setError(err.response.data.message)
            // console.log(err.response.data.message)
        })
    }

    const social_login = async (data) => {
        setIsLoading(true);
        setError(null);
    
        await axios.post(`${API}/user/auth/social-login`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            // console.log( 'read')
            const token = response.data.data.token
            const user_info = {
                firstname: response.data.data.user.firstname,
                lastname: response.data.data.user.lastname,
                username: response.data.data.user.username,
                email: response.data.data.user.email,
                referral: response.data.data.user.referral_id
            }
            console.log( response.data.data.user.firstname,)

            const user = btoa(JSON.stringify(user_info))
            // console.log( 'read2')

            cookies.set("jwt" , token, { path: '/', maxAge: maxAge, sameSite: 'lax', secure: true });
            cookies.set("user" , user, { path: '/', maxAge: maxAge, sameSite: 'lax', secure: true });
            // console.log( 'read3')

                if(response.data) {
                    
                    dispatch({type : 'LOGIN' ,  token: token})
                    Navigate('/dashboard', {state: 'login'})
                }
                setIsLoading(false)
            // if (response.data) {
              
            // }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            // console.log(err.response)
            setError(err.response.data.message);
            // setErroremail(err.response.data.errors.email);
            console.log(err.response)
          });
      };

    return { login, isLoading, error, social_login}
}
