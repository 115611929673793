import axios from "axios";
import { useCallback, useState } from "react";
import { Cookies } from "react-cookie";
import { API } from "../helper/action";

export const useMockexam = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [total, setTotal] = useState([])
  const cookies = new Cookies();

  let token = cookies.get("jwt");

  const mockexam = useCallback(
    async (setAvailableexam, setTotalcount) => {
      setIsLoading(true);
      setError(null);

      await axios
        .get(`${API}/user/my-exams`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          setAvailableexam(response.data.data.exam.data);
          setTotalcount(response.data.data.exam);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    },
    [token]
  );

  const mockexam2 = useCallback(
    async (setAvailableexam, availableexam, pagenumber) => {
      setIsLoading(true);
      setError(null);

      await axios
        .get(`${API}/user/my-exams`, {
          params: {
            page: pagenumber,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          const newarray = response.data.data.exam.data;
          setAvailableexam([...availableexam, ...newarray]);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    },
    [token]
  );

  const filteredexam = useCallback(async (setFiltereddata, param) => {
    setIsLoading(true)
    setError(null)

    await axios.get(`${API}/user/my-exams/community-mock-exams`,
    {
        params: param,
        headers: {
            'Authorization' : `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    } ).then((response) => {
        setIsLoading(false)
        const newarray = response.data.data.exam.data
        setFiltereddata(newarray)
        setTotal(response.data.data.exam)
    }).catch((err) => {
        setIsLoading(false)
        setError(err)
    })
}, [token])

const filteredexam2 = useCallback(async (setFiltereddata, param, filtereddata, pagenumber1) => {
  setIsLoading(true)
  setError(null)

  console.log(param)
  await axios.get(`${API}/user/exams`,
  {
      params: {
          year: param.year,
          subject: param.subject,
          page: pagenumber1
      },
      headers: {
          'Authorization' : `Bearer ${token}`,
          'Content-Type': 'application/json'
      }
  } ).then((response) => {
      setIsLoading(false)
      const newarray = response.data.data.exam.data
      setFiltereddata(newarray)
      setFiltereddata([...filtereddata, ...newarray])
  }).catch((err) => {
      setIsLoading(false)
      setError(err)
  })
}, [token])

  return { mockexam, isLoading, error, mockexam2, filteredexam, filteredexam2, total };
};
