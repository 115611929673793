import { useState } from 'react'
import axios from 'axios'
import { Cookies } from 'react-cookie';
import { API } from '../helper/action';

export const useChangepassword = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [successmessage, setSuccessmessage] = useState(null)
    const cookies = new Cookies();

    const changepassword = async (current_password, password, password_confirmation) => {
        setIsLoading(true)
        setError(null)

        const data = {
            current_password: current_password,
            password: password,
            password_confirmation: password_confirmation,
        }
        console.log(data)

        let token = cookies.get("jwt")
        console.log(token)

       await axios.post(`${API}/user/change/password`, data ,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            const user = response.data.message
            // console.log(user)
            setSuccessmessage(user)
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
            setError(err.response.data.message)
            // console.log(err.response.data.errors.username)
        })
    }
    
    return { changepassword, isLoading, error, successmessage}
}
