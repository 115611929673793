import { useState } from 'react'
import axios from 'axios'
import { Cookies } from 'react-cookie';
import { API } from '../helper/action';

export const useUpdateprofile = () => {
    const [profileerror, setError] = useState(null)
    const [profileisLoading, setIsLoading] = useState(null)
    const [interestisLoading, setisLoading] = useState(null)
    const [message, setMessage] = useState('')
    const [profilesuccessmessage, setProfileuccessmessage] = useState(null)
    const cookies = new Cookies();


    const updateprofile = async (userinfo, setShow, show) => {
        setIsLoading(true)
        setError(null)

        const formData = new FormData()
        if (typeof userinfo.image == 'string') {

            formData.append('firstname', userinfo.firstname)
            formData.append('lastname', userinfo.lastname)
            formData.append('address', userinfo.address)
            formData.append('state', userinfo.state)
            formData.append('city', userinfo.city)
        } else {
            formData.append('firstname', userinfo.firstname)
            formData.append('lastname', userinfo.lastname)
            formData.append('image', userinfo.image)
            formData.append('address', userinfo.address)
            formData.append('state', userinfo.state)
            formData.append('city', userinfo.city)
        }




        let token = cookies.get("jwt")


        await axios.post(`${API}/user/update/profile`, formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                const user = response.data.message
                // console.log(user)
                setProfileuccessmessage(user)
                setIsLoading(false)
                setShow(!show)
                window.location.reload(false)
            }).catch((err) => {
                setIsLoading(false)
                setError(err)
                // console.log(err)
            })
    }

    const updateinterest = async (pickedinterests, data) => {
        setisLoading(true)
        setError(null)
        let token = cookies.get("jwt")

        await axios.post(`${API}/user/delete/interests`, pickedinterests,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(async (response) => {
                if (response.data.message) {
                    await axios.post(`${API}/user/update/interests`, data,
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then((response) => {
                            setMessage(response.data.message)
                            setisLoading(false)
                        }).catch((err) => {
                            setisLoading(false)
                            setError(err)
                            // console.log(err)
                        })
                }
            }).catch((err) => {
                setisLoading(false)
                setError(err)
                // console.log(err)
            })
    }

    return { updateprofile, profileisLoading, profileerror, profilesuccessmessage, updateinterest, message, interestisLoading }
}
