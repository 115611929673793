import React from "react";
// import Form from "react-bootstrap/Form";
// import { Link } from "react-router-dom";
import { ReactComponent as Comingsoon } from "../assets/images/comingsoon.svg";

import "../stylesheets/Mockexam.css";

function Mockexamcompetition() {
  return (
    <div className="comingsooncomp">
      <div className="notified">
        <p>~Coming Soon~</p>
        <h1>Get Notified when we launch</h1>
        <p>We will notify you with reminders and updates so you won’t have to miss out on anything when we launch the mock exam competition feature!</p>
      </div>
      <div>
        <Comingsoon />
      </div>
      {/* <div className="mockexamcomp">
        <div className="mockexamcompheader">
          <h3>June 2023 Mock Exam Competition</h3>
          <Form.Select>
            <option>Specify Exam Type</option>
            <option value="grapefruit">GCE</option>
            <option value="lime">WAEC</option>
            <option value="coconut">NECO</option>
          </Form.Select>
        </div>
        <div className="mockexamlist">
          <li>
            <span>This is monthly competition hosted by exams.africa</span>
          </li>
          <li>
            <span>
              The top scoring candidates will be awarded prices as a reward.
            </span>
          </li>
          <li>
            <span>
              An auto generated exam is curated or the different exam types
            </span>
          </li>
          <li>
            <span>Choose your preferred exam type</span>
          </li>
          <li>
            <span>You can now start exam, you’ve got this!</span>
          </li>
        </div>
        <div className="row justify-content-center mt-lg-4 mt-xl-5 mt-4">
          <div className="col-lg-10 col-xl-9 col-xxl-8 tyav">
            <div className="row">
              <p className="col-lg-6 col-6">
                Type: <span>MCQ</span>
              </p>
              <p className="col-lg-6 col-6">
                Year: <span>2019 - 2022</span>
              </p>
            </div>
            <div className="row">
              <p className="col-lg-6 col-6">
                Attempts Left: <span>3</span>
              </p>
              <p className="col-lg-6 col-6">
                Valid for the next: <span>25 Days</span>
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex gap-4 justify-content-center text-center mt-5">
          <Link className="leaderboard" to='/leaderboard'>View Leaderboard</Link>
          <Link>Start Your Exam</Link>
        </div>
      </div> */}
    </div>
  );
}

export default Mockexamcompetition;
