import axios from "axios";
import { useCallback, useState } from "react";
import { API } from "../helper/action";

export const usePastquestion = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [categories, setCatgeories] = useState([])

    const Category = useCallback(
        async () => {
            setIsLoading(true);
            setError(null);
    
            await axios
                .get(`${API}/user/home/categories`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    setIsLoading(false);
                    setCatgeories(response.data.data.categories)
                })
                .catch((err) => {
                    setIsLoading(false);
                    setError(err);
                });
        }, []
    )

    const Pastquestion = useCallback(
        async (setPastquestion, setTotalcount) => {
            setIsLoading(true);
            setError(null);
    
            await axios
                .get(`${API}/user/home/subjects`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    setIsLoading(false);
                    setPastquestion(response.data.data.subjects.data);
                    setTotalcount(response.data.data.subjects)
                })
                .catch((err) => {
                    setIsLoading(false);
                    setError(err);
                });
        }, []
    )

    const Pastquestion2 = useCallback(async (setPastquestion, pastquestion, pagenumber) => {
        setIsLoading(true)
        setError(null)

        await axios.get(`${API}/user/home/subjects`,
        {
            params: {
                page: pagenumber
            },
            headers: {
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            setIsLoading(false)
            const newarray = response.data.data.subjects.data
            setPastquestion([...pastquestion, ...newarray])
        }).catch((err) => {
            setIsLoading(false)
            setError(err)
        })
    }, [])

    return { Pastquestion, isLoading, error, Pastquestion2, Category, categories };
};
