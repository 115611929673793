import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import { useForgetPassword } from '../hook/useForgetPassword'
import Spinner from 'react-bootstrap/Spinner';

import {ReactComponent as Logo} from '../assets/icons/examlogo2.svg'
import '../stylesheets/Login.css'

import footericonright from '../assets/icons/footericon-right.svg'
import footericonleft from '../assets/icons/footericon-left.svg'

function Forgetpassword() {
  const [email, setEmail] = useState('')
  const {forgetpassword, isLoading, error} = useForgetPassword()

  const handlesubmit = async (e) => {
    e.preventDefault()
    await forgetpassword(email)
}


  return (
    <div>
      {/* Header */}
      <div className="row justify-content-between header">
        <div className="col-lg-2 col-sm-3 col-4">
            <Logo />
        </div>
        <div className="col-lg- header-right col-sm-4 col-6">
            <p>
            Do you remember your password now? Login<Link to="/Login">{' '}here</Link>
            </p>
        </div>
      </div>
      {/* Content */}
      <div className='row mainbody'>
        <div className='col-lg-2'>
          <h1 className='title'>Forgot Password</h1>
        </div>
        <div className='row justify-content-around pt-md-5 pt-lg-0 pt-4'>
          <div className='col-lg-7'>
          <div className='row justify-content-center'>
            <form className='col-lg-6 col-sm-6 col-8 email-confirm' onSubmit={handlesubmit}>
              <div className='text py-4'>
                <p>
                  Enter the e-mail associated with your account and a reset code will be sent to you
                </p>
              </div>
              <div className='login-input pt-3'>
                <label>Enter Email Address</label>
                <input type='email' name="email" aria-label='email' onChange={(e) => setEmail(e.target.value)}/>
                <p className='errormessage'>{error === undefined ? '' : error}</p>
              </div>
              <button className='register-button'>
              { isLoading   ? <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner> : 'Send Reset Code'}
                </button>
            </form>
          </div>
          <div className='row justify-content-between pt-5'>
            <div className='col-lg-2 footericonleft col-sm-2 col-2'>
              <img src={footericonleft} alt="footericonleft" />
            </div>
            <div className='col-lg-2 footericonright col-sm-2 col-2'>
              <img src={footericonright} alt="footericonleft" />
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Forgetpassword