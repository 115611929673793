import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useLogin } from "../hook/useLogin";
import Spinner from "react-bootstrap/Spinner";
// import FacebookLogin from "@greatsumini/react-facebook-login";
import { ReactComponent as Logo } from "../assets/icons/examlogo2.svg";
import "../stylesheets/Login.css";

import footericonright from "../assets/icons/footericon-right.svg";
import footericonleft from "../assets/icons/footericon-left.svg";
import { ReactComponent as Eye } from "../assets/icons/eye.svg";
import { ReactComponent as Eyeslash } from "../assets/icons/eye-slash.svg";
import {
  LoginSocialGoogle,
  // LoginSocialFacebook,
} from 'reactjs-social-login'

// CUSTOMIZE ANY UI BUTTON
import {
  // FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons'


export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [view, setView] = useState(false);
  const { login, isLoading, error, social_login } = useLogin();
  const [provider, setProvider] = useState('')
  const [profile, setProfile] = useState(null)

  const onLoginStart = useCallback(() => {
    // alert('login start')
    // console.log(provider);
    // console.log(profile);
    let p = profile
    let x = provider
    return [p,x]
  }, [profile, provider])

  // const onLogoutSuccess = useCallback(() => {
  //   setProfile(null)
  //   setProvider('')
  //   alert('logout success')
  // }, [])

  const handlesubmit = async (e) => {
    e.preventDefault();
    await login(username, password);
  };

  const passwordview = () => {
    const element = document.querySelector("#password");
    element.setAttribute("type", "text");
    setView(true);
  };

  const passwordhide = () => {
    const element = document.querySelector("#password");
    element.setAttribute("type", "password");
    setView(false);
  };

  // function handleCredentialResponse(response) {
  //   console.log("Encoded JWT ID token: " + response.credential);
  // }

  return (
    <div>
      {/* Header */}
      <div className="row justify-content-between header">
        <div className="col-lg-2 col-sm-3 col-4">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="col-lg-3 header-right col-sm-4 col-6">
          <p>
            Don’t have an account? Signup{" "}
            <Link to="/Register" aria-label="register">
              here
            </Link>
          </p>
        </div>
      </div>
      {/* Content */}
      <div className="row mainbody">
        <div className="col-lg-2">
          <h1 className="title">Login To account</h1>
        </div>
        <div className="row justify-content-around pt-md-5 pt-lg-0 pt-4">
          <div className="col-lg-7 form-box">
            <div className="row justify-content-center">
              <form className="col-lg-6 col-sm-6 col-10" onSubmit={handlesubmit}>
                <div className="login-input pt-3">
                  <label>Username/Phone No</label>
                  <input
                    type="text"
                    name="username"
                    aria-label="username"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    autoComplete="true"
                  />
                </div>
                <div className="login-input pt-3">
                  <label>Password</label>
                  <div className="passwordinput">
                    <input
                      type="password"
                      name="password"
                      aria-label="password"
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      autoComplete="true"
                    />
                    <span
                      onClick={passwordview}
                      className={`${view ? "d-none" : ""}`}
                    >
                      <Eyeslash />
                    </span>
                    <span
                      onClick={passwordhide}
                      className={`${view ? "" : "d-none"}`}
                    >
                      <Eye />
                    </span>
                  </div>
                </div>
                <div className="forgetpassword pt-2">
                  <Link to="/Forgetpassword">Forgot Password</Link>
                </div>
                <div></div>
                <p className="errormessage">
                  {error === undefined ? "" : error}
                </p>
                <button className="register-button" disabled={isLoading}>
                  {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Login"
                  )}
                </button>
            {/*    <LoginSocialFacebook*/}
            {/*  className="social-button register-social-button"*/}
            {/*  isOnlyGetToken={false}*/}
            {/*  appId='905156100582307'*/}
            {/*  onLoginStart={onLoginStart}*/}
            {/*  onResolve={({ provider, data }) => {*/}
            {/*    setProvider(provider)*/}
            {/*    setProfile(data)*/}
            {/*    const info = {*/}
            {/*      provider: provider,*/}
            {/*      email: data.email,*/}
            {/*      name: data.name*/}
            {/*    }*/}
            {/*    social_login(info)*/}
            {/*  }}*/}
            {/*  onReject={(err) => {*/}
            {/*    console.log(err)*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <FacebookLoginButton />*/}
            {/*</LoginSocialFacebook>*/}

            <LoginSocialGoogle
              className="social-button register-social-button shadow-none"
              client_id='1092396342303-98jlepi00ug694mae72bgcnm867rcnup.apps.googleusercontent.com'
              onLoginStart={onLoginStart}
              scope="https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
              onResolve={({ provider, data }) => {
                setProvider(provider)
                setProfile(data)
                const info = {
                  provider: provider,
                  email: data.email,
                  name: data.name
                }
                social_login(info)
              }}
              isOnlyGetToken={false}
              onReject={(err) => {
                console.log(err)
              }}
            >
              <GoogleLoginButton />
            </LoginSocialGoogle>
              </form>
            </div>
            <div className="row justify-content-between pt-5">
              <div className="col-lg-2 footericonleft col-sm-2 col-2">
                <img src={footericonleft} alt="footericonleft" />
              </div>
              <div className="col-lg-2 footericonright col-sm-2 col-2">
                <img src={footericonright} alt="footericonleft" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
