import { useState } from 'react'
import axios from 'axios'

import { useNavigate } from 'react-router-dom'
import { API } from '../helper/action'

export const useSendVerificationEmail = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    let Navigate = useNavigate()

    const verifyemail = async (code) => {
        setIsLoading(true)
        setError(null)
        
        
        let token = sessionStorage.getItem("token")
        let data = {
            verification_code: code
        }
        
       await axios.post(`${API}/user/auth/email/verify`, data,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
                if(response.data) {
                    Navigate('/login')
                }
                setIsLoading(false)
            
        }).catch((err) => {
            setError(err.response.data.message)
        })
    }

    return { verifyemail, isLoading, error}
}
