import React, { useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

import "../stylesheets/Home.css";
import homeimg from "../assets/icons/Home.svg";
import practiceimg from "../assets/images/practice.png";
import prepareimg from "../assets/icons/prepareimg.png";
import pqicon from "../assets/icons/pqicon.svg";
import meicon from "../assets/icons/meicon.svg";
import taicon from "../assets/icons/taicon.svg";
import afordicon from "../assets/icons/afordicon.svg";
import webicon from "../assets/icons/webicon.svg";
import Telegram from "../assets/icons/telegram.svg";
import Whatsapp from "../assets/icons/whatsapp.svg";
import appleicon from "../assets/icons/appleicon.svg";
import playstoreicon from "../assets/icons/playstoreicon.svg";
// import arrowicon from "../assets/icons/arrowicon.svg";
import playicon from "../assets/icons/playicon.svg";
import videoposter from "../assets/images/videoposter.jpg";
import cycimg from "../assets/images/cycimg.png";
import spsimg from "../assets/images/spsimg.png";
// import sps2img from "../assets/images/sps2img.png";
import aeimg from "../assets/images/aeimg.png";
// import { usePastquestion } from "../hook/pastquestion";

function Home() {
  const [play, setPlay] = useState(false);
  // const [pastquestion, setPastquestion] = useState([]);
  // const { Pastquestion } = usePastquestion();

  const Play = () => {
    const attr = document.createAttribute("controls");
    const element = document.getElementsByTagName("video")[0];
    element.setAttributeNode(attr);

    if (!element.hasAttribute("control")) {
      setPlay(true);
      element.play();
    } else {
      setPlay(false);
    }
  };

  // const pagenumber = 2

  // useEffect(() => {
  //   Pastquestion(setPastquestion);
  // }, [Pastquestion]);

  return (
    <div className="body">
      <Navbar />
      <div className="row home-container mt-md-4">
        <div className="col-lg-7">
          <div className="d-flex home-title">
            <h1 className="">Practice</h1>
            <img src={practiceimg} alt="practice" />
          </div>
          <div className="d-flex home-title">
            <img src={prepareimg} alt="prepare" />
            <h1>Prepare</h1>
          </div>
          <div className=" home-title home-sm-title">
            <h1>Excel</h1>
            <div className="d-grid excel-grid">
              <span>Jamb - Utme</span>
              <span>Waec - SSCE</span>
              <span>Waec - Gce</span>
              <span className="cea">Common entrance exam</span>
              <span>neco</span>
            </div>
          </div>
          <p className="home-par">
            Welcome to the Exams.Africa, Exams Africa is a platform designed
            specifically for Africans looking to practise for upcoming exams.
            Our platform offers a comprehensive collection of past exam
            questions from a wide range of subjects, allowing users to test
            their knowledge and improve their skills.
          </p>
          <div className="cta">
            <Link to="/register" className="ctareg">
              Get Started
            </Link>
            <Link to="/login" className="ctalog">
              Start Practising
            </Link>
          </div>
        </div>
        <div className="col-lg-5 img-container">
          <img src={homeimg} alt="Homeimage" />
        </div>
      </div>
      <div className="row justify-content-around home-list">
        <div className="col-md-auto col-xl-9 col-xxl-8 col-auto">
          <div className="pq-box">
            <div className="">
              <div className="p-0">
                <img src={pqicon} alt="pq" loading="lazy" />
              </div>
              <div>
                <span>Past Questions</span>
                <p>Thousands of past questions</p>
              </div>
            </div>
          </div>
          <div className="pq-box">
            <div className="">
              <div className="p-0">
                <img src={meicon} alt="pq" loading="lazy" />
              </div>
              <div>
                <span>Mock Exams</span>
                <p>Mock examination feature</p>
              </div>
            </div>
          </div>
          <div className="pq-box">
            <div className="">
              <div className="p-0">
                <img src={taicon} alt="pq" loading="lazy" />
              </div>
              <div>
                <span>Track activity</span>
                <p>Dashboard to track all activities</p>
              </div>
            </div>
          </div>
          <div className="pq-box border-0">
            <div className="">
              <div className="p-0">
                <img src={afordicon} alt="pq" loading="lazy" />
              </div>
              <div>
                <span>Affordable</span>
                <p>Low cost on in-app purchases</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row weib">
        <h2> Now you have no reason to fail </h2>
        <div className="row justify-content-evenly mt-5">
          <div className="col-xl-4 weib-img col-sm-6 ">
            <img src={cycimg} alt="cycimg" loading="lazy" />
          </div>
          <div className="col-xl-3 weib-info col-sm-5">
            <h3>Choose Your Category</h3>
            <p>
            Exams Africa gives every user a chance of choosing their exam category based on essential subjects or courses of study e.g WAEC-SSCE, UTME (JAMB), etc.
            </p>
            <span></span>
          </div>
        </div>
        <div className="row justify-content-evenly">
          <div className="col-xl-4 weib-img-mobile col-sm-6">
            <img src={spsimg} alt="cycimg" loading="lazy" />
          </div>
          <div className="col-xl-3 weib-info col-sm-5 ">
            <h3>Select Preferable Subject</h3>
            <p>
            Every user gets to choose the preferred subject to practise as there are as many essential subjects as you want on the site.
            </p>
            <span></span>
          </div>
          <div className="col-xl-4 weib-img weib-img-mobile-lg  col-sm-6">
            <img src={spsimg} alt="cycimg" loading="lazy" />
          </div>
        </div>
        <div className="row justify-content-evenly">
          <div className="col-xl-4 weib-img col-sm-6 ">
            <img src={aeimg} alt="cycimg" loading="lazy" />
          </div>
          <div className="col-xl-3 weib-info col-sm-5">
            <h3>Practise</h3>
            <p>
            Now, this is where you get to test your capabilities by practising the questions to the finish line and getting graded at the end.
            </p>
            <span></span>
          </div>
        </div>
        {/* <div className="row justify-content-evenly">
          <div className="col-xl-4 weib-img-mobile col-sm-6">
            <img src={sps2img} alt="cycimg" loading="lazy" />
          </div>
          <div className="col-xl-3 weib-info col-sm-5 ">
            <h3>Select Preferable Subject</h3>
            <p>
              The aim of this to help you see things more clearly and get a good
              impression of the possible options, whether you have your heart
              set on a particular career path or not.
            </p>
            <span></span>
          </div>
          <div className="col-xl-4 weib-img weib-img-mobile-lg  col-sm-6 ">
            <img src={sps2img} alt="cycimg" loading="lazy" />
          </div>
        </div> */}
      </div>
      <div className="row aotp justify-content-around">
        <div className="col-xxl-10 col-xl-12 col-md-auto col-10">
          <h2>Available on These platforms</h2>
          <div>
            <div className="wb-box">
              <img src={webicon} alt="webicon" loading="lazy" />
              <div>
                <span>Web</span>
                <Link to="/register">Get started</Link>
              </div>
            </div>
            <div className="wb-box">
              <img src={appleicon} alt="webicon" loading="lazy" />
              <div>
                <span>App Store</span>
                <Link>Download Now</Link>
              </div>
            </div>
            <div className="wb-box">
              <img src={playstoreicon} alt="webicon" loading="lazy" />
              <div>
                <span>Play store</span>
                <a href="https://play.google.com/store/apps/details?id=com.exams_africa">Download Now</a>
              </div>
            </div>
            <div className="wb-box ">
              <img src={Telegram} alt="webicon" loading="lazy" />
              <div>
                <span>Telegram</span>
                <Link aria-disabled="true">Coming soon</Link>
              </div>
            </div>
            <div className="wb-box border-0">
              <img src={Whatsapp} alt="webicon" loading="lazy" />
              <div>
                <span>Whatsapp</span>
                <Link aria-disabled="true">Coming soon</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row mps justify-content-center text-center">
        <div className="col-lg-10">
          <div className="row justify-content-center text-center">
            <div className="col-lg-7">
              <h2>Most Popular Subjects</h2>
              <p>
                All your past questions all in one place. This is your one stop
                hub for through and precise exams preparations.
              </p>
            </div>
          </div>
          <div className="mps-grid">
            {pastquestion.slice(0, 8).map((post, index) => {
              return (
                <div key={index}>
                  <h3>{post.name}</h3>
                  <p>{post.slug}</p>
                  <div>
                    <Link to="/pastquestions">Past Questions</Link>
                    <Link to="/pastquestions" className="arrow">
                      <img src={arrowicon} alt="arrow" loading="lazy" />
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
      <div className="row uteptr justify-content-center text-center">
        <div className="col-lg-10">
          <h3>
            Use the ExamsAfrica <br /> Platform the right way
          </h3>
          <p>
            Learn the essential ways to successfully maximize the Platform for a
            exam success.
          </p>
          <div>
            <button
              className={`playbutton ${play ? "d-none" : ""}`}
              onClick={Play}
            >
              <img src={playicon} alt="play" loading="lazy" />
              <span>Play tutorial video</span>
            </button>
            <video
              id="vid"
              width="800"
              className="vid"
              src="https://archive.org/download/examsafrica-web/Examsafrica%20web.mp4"
              poster={videoposter}
            >
              {/* <source src="https://www.youtube.com/embed/Isnp3zkW0hI"  type="video/mp4"/> */}
              {/* <source src="mov_bbb.mp4" type="video/mp4"/>
                <source src="mov_bbb.ogg" type="video/ogg"/>
                Your browser does not support HTML video. */}
            </video>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
