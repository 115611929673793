import React, { useState } from 'react'
// import { Link } from "react-router-dom";

import Navbar from '../Components/Navbar'
import Footer from "../Components/Footer";

import "../stylesheets/Contact.css";
import arrowicon from "../assets/icons/arrowiconwhite.svg";
import locationicon from "../assets/icons/locationicon.svg";
import linkedinicon from "../assets/icons/linkediconblue.svg";
import twittericon from "../assets/icons/twittericonblue.svg";
import instagramicon from "../assets/icons/instagramiconblue.svg";
import facebookicon from "../assets/icons/facebookiconblue.svg";
import { useTicket } from '../hook/ticket';

import Modal from 'react-bootstrap/Modal';


function Ticketsubmit(props) {
  // console.log(props.loading)
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className='border-0 ticket-close' closeButton>
      </Modal.Header>
      <Modal.Body className='text-center ticket-modal'>
        <h4>Successfully Submitted </h4>
        <p>
          Your ticket has been submitted successfully
        </p>
      </Modal.Body>
    </Modal>
  );
}

function Contactus() {
  const info = {
    name: '',
    email: '',
    subject: '', 
    message: ''
  }

  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState(info)
  const { ticket, isLoading } = useTicket()

  const handlesubmit = async(e) => {
    e.preventDefault()
    await ticket(data)
    if(!isLoading){
      return (setModalShow(true), setData({...data, name: '', email: '', subject: '', message: ''}))
    }
  }

  function formatPhoneNumber(phoneNumberString) {
    let p = phoneNumberString.replace(/\D+/g, '').replace(/(\d{3})(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3 $4 $5');
    return p
  }

  return (
    <div>
      <Navbar/>
      <div className='contact-container'>
        <div className='row hcwhy'>
          <div className='col-lg-7'>
            <h6>
              Support
            </h6>
            <h3>
              How can we help you?
            </h3>
            <p>
              We Will Open The World Of Knowledge For You! This is the latest online examination system you will ever need! 
            </p>
          </div>
        </div>
        <div className='row justify-content-center contact-details'>
          <div className='col-lg-5 contact-info col-sm-6'>
            <h5>
              Contact Us
            </h5>
            <p>
              We Will Open The World Of Knowledge For You! This is the latest online examination system you will ever need! 
            </p>
            <div>
              <h6>
                Head Office
              </h6>
              <p>
                41/42, industrial avenue<br />
                Sabo Yaba Lagos.
              </p>
              <div>
                <img src={locationicon} alt="locationicon" />
                <span>
                  Get directions
                </span>
              </div>
              <p>
                {formatPhoneNumber('+23409167724945')}
              </p>
              <p>
                hello@exams.africa
              </p>
              <div>
                <a href='https://www.linkedin.com/company/exams-africa/' target='_blank' rel="noreferrer">
                  <img src={linkedinicon} alt="linkedinicon" />
                </a>
                <a href='#0'>
                  <img src={twittericon} alt="twittericon" />
                </a>
                <a href='https://www.instagram.com/examsafrica/' target='_blank' rel="noreferrer">
                  <img src={instagramicon} alt="instagramicon" />
                </a>
                <a href='#0' >
                  <img src={facebookicon} alt="facebook" />
                </a>
              </div>
            </div>
          </div>
          <form className='col-lg-6 contact-form col-sm-6' onSubmit={handlesubmit}>
            <div className='row pt-3'>
                <div className='col-lg-6'>
                  <label>Name</label>
                  <input type="text" name="name" value={data.name} placeholder='Garba Garba' onChange={(e) => setData({...data, [e.target.name]: e.target.value})} />
                </div>
                <div className='col-lg-6'>
                  <label>Email Address</label>
                  <input type="email" name="email" value={data.email} placeholder='grababegd@gmail.com' required  onChange={(e) => setData({...data, [e.target.name]: e.target.value})} />
                </div>
            </div>
            <div className='pt-3'>
              <label>Subject</label>
              <input type='text' name="subject" value={data.subject} placeholder='Can’t find a subject'  onChange={(e) => setData({...data, [e.target.name]: e.target.value})} />
            </div>
            <div className='pt-3'>
              <label>How can we help you?</label>
              {/* <input type='text' name="subject" placeholder='Can’t find a subject' /> */}
              <textarea name='message' value={data.message} placeholder='I need to study for a hausa exam and i can’t find the course' onChange={(e) => setData({...data, [e.target.name]: e.target.value})}/>
            </div>
            {isLoading ? <button disabled>
              <span>
              loading
              </span>
              <img src={arrowicon} alt="arrowicon" />
            </button> : <button>
              <span>
               send message
              </span>
              <img src={arrowicon} alt="arrowicon" />
            </button>}
            <Ticketsubmit show={modalShow}
        onHide={() =>{ setModalShow(false)}} />
          </form>
        </div>
        <div className='row justify-content-center nsh'>
          <div className='col-lg-11 '>
          <h3>
                Need specific help?
              </h3>
              <p>
                Visit our frequently asked questions section here or make use our social media platforms to get in contact with us. Click on any of the social media icons to be redirected.
              </p>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Contactus