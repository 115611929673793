import axios from 'axios'
import { useCallback, useState } from 'react';
import { Cookies } from 'react-cookie';
import { API } from '../helper/action';
import { useNavigate } from 'react-router';

export const useExamquestion = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    // const cookies = new Cookies();
    
    // let token = cookies.get("jwt")
    let Navigate = useNavigate()

    const examquestion = useCallback(async (setQuestion, setExam, setMessage) => {
        setIsLoading(true)
        setError(null)
        const cookies = new Cookies();
    
    let token = cookies.get("jwt")

        let examid = cookies.get("id")
        examid = JSON.parse(atob(examid))
    
        await axios.get(`${API}/user/questions/${examid.exam}`,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            setIsLoading(false)
            
            setExam(response.data.data.Exam)
            setQuestion(response.data.data.Questions)
            setMessage(response.data.message)
            // console.log(response.data.message)
        }).catch((err) => {
            setIsLoading(false)
            // console.log(err.response.data.message);
            setError(err.response.data.message)
        })
    }, [])
    
    const questionandanswer = useCallback(async () => {
        setIsLoading(true)
        setError(null)
        const cookies = new Cookies();
    
        let token = cookies.get("jwt")

        let examid = cookies.get("id")
        examid = JSON.parse(atob(examid))
    
        await axios.get(`${API}/user/questions/${examid.exam}`,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            setIsLoading(false)
            console.log(response);
            localStorage.setItem('q&a', btoa(JSON.stringify(response.data.data)))
            if(response.data) {
                Navigate('/questionandanswer')
            }
            // console.log(response.data.message)
        }).catch((err) => {
            setIsLoading(false)
            // console.log(err.response.data.message);
            setError(err.response.data.message)
        })
    }, [ Navigate])

    
    return { examquestion, questionandanswer, isLoading, error }
}
