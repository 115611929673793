import React from "react";
import { useNavigate } from "react-router-dom";
import "../stylesheets/termsandprivacy.css";
import { ReactComponent as Arrowback } from '../assets/icons/arrowback.svg'

function Termscondition() {
  const navigate = useNavigate()

  return (
    <div className="termsandcondition">
      <div className="termsback">
        <Arrowback onClick={() => navigate(-1)}/>
      </div>
      <h1 className="text-center">Terms and Condition</h1>
      <div className="terms-container">
        <p>
          The edtech product examsafrica is provided to you on a limited,
          non-exclusive, non-transferable, revocable license for personal,
          non-commercial use only. User agrees to not reproduce, distribute,
          sell, transfer, or modify the Product without the prior written
          consent of Exams.Africa.
        </p>
        <p>
          User agrees to not use the Product for any illegal or unauthorized
          purposes. User is solely responsible for any content or information
          that is shared or posted using the Product. Company is not responsible
          for any content or information shared or posted by User.
        </p>
        <p>
          Company reserves the right to terminate User's access to the Product
          at any time, without notice, for any reason, including but not limited
          to violations of these terms and conditions. User understands and
          agrees that the Product is provided on an "as is" and "as available"
          basis, and Company makes no warranty or representation, express or
          implied, regarding the Product or its functionality.
        </p>
        <p>
          In no event shall Company be liable for any damages, losses, or
          expenses arising out of or in connection with the use or inability to
          use the Product, including but not limited to indirect, consequential,
          incidental, special, or punitive damages.
        </p>
        <p>
          These terms and conditions shall be governed by and construed in
          accordance with the laws of Nigeria, without giving effect to any
          principles of conflicts of law.
        </p>
        <p>
          User agrees to indemnify and hold Company and its affiliates,
          officers, agents, and employees harmless from any claim or demand,
          including reasonable attorneys' fees, arising out of or in connection
          with User's use of the Product.
        </p>
        <p>
          These terms and conditions constitute the entire agreement between
          User and Company regarding the use of the Product, and any prior
          agreements or understandings, whether oral or written, are hereby
          superseded and shall have no force or effect.
        </p>
        <p>
          Company reserves the right to change these terms and conditions at any
          time, without notice, and User's continued use of the Product after
          any such changes shall constitute acceptance of such changes.
        </p>
      </div>
    </div>
  );
}

export default Termscondition;
