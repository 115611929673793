import { useState } from 'react'
import axios from 'axios'
import { Cookies } from 'react-cookie';
import { API } from '../helper/action';

export const usePayment = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const cookies = new Cookies();

    const getaccount = async (setAmount, setAccount) => {
        setIsLoading(true)
        setError(null)
        // console.log(setAmount)
        let data  = {
            amount: `${setAmount}`
        }
        let token = cookies.get("jwt")
        await axios.post(`${API}/user/make-payment`, data ,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            // console.log(response.data.data.data)
            setAccount(response.data.data.data)
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
            setError(err.response.data.message)
        })
    }

    const voucher = async (data, setUssdcode) => {
        setIsLoading(true)
        setError(null)
        // console.log(setAmount)
        
        let token = cookies.get("jwt")
        await axios.post(`${API}/user/voucher`, data ,
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            // console.log(response.data.message)
            // {message === 'Kindly Check for correct Voucher Code' ? "error" : ""}
            if (response.data.message === 'Kindly Check for correct Voucher Code '){
                setUssdcode('error')
            }else {
                setUssdcode('success')
            }
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
            setError(err.response.data.message)
        })
    }
    
    return { getaccount, isLoading, error, voucher}
}
