import React, { useState } from 'react'
// import { Link } from "react-router-dom";
// import Spinner from 'react-bootstrap/Spinner';

import "../stylesheets/Support.css";

import arrowicon from "../assets/icons/arrowiconwhite.svg";
import linkedinicon from "../assets/icons/linkediconblue.svg";
import twittericon from "../assets/icons/twittericonblue.svg";
import instagramicon from "../assets/icons/instagramiconblue.svg";
import facebookicon from "../assets/icons/facebookiconblue.svg";
import { useTicket } from '../hook/ticket';

import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className='border-0 ticket-close' closeButton>
      </Modal.Header>
      <Modal.Body className='text-center ticket-modal'>
        <h4>Successfully Submitted </h4>
        <p>
          Your ticket has been submitted successfully
        </p>
      </Modal.Body>
    </Modal>
  );
}

function Support() {
  const info = {
    name: '',
    email: '',
    subject: '', 
    message: ''
  }

  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState(info)
  const { ticket, isLoading } = useTicket()

  const handlesubmit = async(e) => {
    e.preventDefault()
    await ticket(data)
    if(!isLoading){
      return (setModalShow(true), setData({...data, name: '', email: '', subject: '', message: ''}))
    }
  }

  function formatPhoneNumber(phoneNumberString) {
    let p = phoneNumberString.replace(/\D+/g, '').replace(/(\d{3})(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3 $4 $5');
    return p
  }

  return (
    <div className='supportcontent'>
      <div className='row justify-content-between'>
        <form className='col-lg-6 support-form col-sm-6' onSubmit={handlesubmit}>
          <div className='row pt-3'>
              <div className='col-lg-6'>
                <label>Name</label>
                <input type="text" name="name" value={data.name} placeholder='Garba Garba'  onChange={(e) => setData({...data, [e.target.name]: e.target.value})} />
              </div>
              <div className='col-lg-6'>
                <label>Email Address</label>
                <input type="email" name="email" value={data.email} placeholder='grababegd@gmail.com' required  onChange={(e) => setData({...data, [e.target.name]: e.target.value})}/>
              </div>
          </div>
          <div className='pt-4'>
            <label>Subject</label>
            <input type='text' name="subject" value={data.subject} placeholder='Can’t find a subject'  onChange={(e) => setData({...data, [e.target.name]: e.target.value})}/>
          </div>
          <div className='pt-4'>
            <label>How can we help you?</label>
            <textarea name='message' value={data.message} placeholder='I need to study for a hausa exam and i can’t find the course'  onChange={(e) => setData({...data, [e.target.name]: e.target.value})}/>
          </div>
          {isLoading ? <button disabled>
              <span>
            loading
            </span>
            <img src={arrowicon} alt="arrowicon" />
          </button> : <button >
              <span>
            Send message
            </span>
            <img src={arrowicon} alt="arrowicon" />
          </button>}
        </form>
        <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {setModalShow(false)}}
        />
        <div className='col-lg-5 col-sm-6'>
        <div className='row'>
        <div className='support-card col-xl-10 col-auto'>
        <div>
          <span>
            Call Us:
          </span>
          <p>
          {  formatPhoneNumber('+23409167724945')}
          </p>
        </div>
        <div>
          <p className='support-social'>
            Reach us on any of our social media platforms below:
          </p>
          <div className='pt-1'>
            <a href='https://www.linkedin.com/company/exams-africa/' target='_blank' rel="noreferrer">
              <img src={linkedinicon} alt="linkedinicon" />
            </a>
            <a href='#0' target='_blank' rel="noreferrer">
              <img src={twittericon} alt="twittericon" />
            </a>
            <a href='https://www.instagram.com/examsafrica/' target='_blank' rel="noreferrer">
              <img src={instagramicon} alt="instagramicon" />
            </a>
            <a href='#0' target='_blank' rel="noreferrer">
              <img src={facebookicon} alt="facebook" />
            </a>
          </div>
        </div>
        </div>
      </div>
        </div>
      </div>
      
    </div>
  )
}

export default Support