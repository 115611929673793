import Modal from "react-bootstrap/Modal";
import { ReactComponent as Gradingprogress } from "../assets/icons/gradingprogress.svg";


function Gradingprog(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="submitmodal"
      >
        
          <Modal.Body className="text-center exam-submit">
            <Gradingprogress />
            <h5>Grading in progress!</h5>
            <p>
                We are meticulously grading your exam, so kindly wait a quick moment while we get it ready
            </p>
            <p>
              {/* <Spinner /> */}
            </p>
          </Modal.Body>
        
      </Modal>
    );
  }

  export default Gradingprog