import React, { useEffect, useState } from 'react'
// import { Link } from "react-router-dom";

import Navbar from '../Components/Navbar'
import Footer from "../Components/Footer";

import "../stylesheets/Blog.css";
import blogimg from "../assets/images/blogimg.jpg";
import arrowicon from "../assets/icons/arrowiconwhite.svg";
import { useBlog } from '../hook/blog';
import { Link } from 'react-router-dom';
import Loader from '../Components/Loader';

function Blog() {
  const [blog, setBlog] = useState([])
  const { blogpost, isLoading } = useBlog()

  // maximum number of characters to extract

  //trim the string to the maximum length

  // function rnumber(questiontext) {
  //   let puretext = questiontext.replace(/\d+./g, "");
  //   return puretext;
  // }

  // function removenumber(describ) {
  //   let yourString = describ; //replace with your string.
  //   let maxLength = 20
  //   let trimmedString = yourString.substr(0, maxLength);
  //   trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
  //   return trimmedString
  // }

  //re-trim if we are in the middle of a word


  useEffect(() => {
    blogpost(setBlog)
  }, [blogpost])

  return (
    <div>
      <Navbar />
      <div className='blog-container'>
        <div className='row gtlu'>
          <div className='col-lg-7'>
            <h6>
              Blog
            </h6>
            <h3>
              Get the latest Updates
            </h3>
            <p>
              We Will Open The World Of Knowledge For You! This is the latest online examination system you will ever need!
            </p>
          </div>
        </div>
        {
          isLoading ? <Loader /> : <div className='blog-grid'>
            {
              blog.map((post, index) => {
                return (
                  <div className='' key={index}>
                    <div>
                      <img src={blogimg} alt="blogimg" />
                    </div>
                    <h5>
                      {post.data_values.title}
                    </h5>
                    {/* <p dangerouslySetInnerHTML={{ __html: `${rnumber(removenumber(post.data_values.body))}...`}} /> */}
                      {/* {`${rnumber(removenumber(post.data_values.body))}...`} */}
                    <Link to="/singleblog" className='blog-button' onClick={() => localStorage.setItem('postid', post.id)}>
                      <p>
                        View This Article
                      </p>
                      <img src={arrowicon} alt="arrowicon" />
                    </Link>
                  </div>
                )
              })
            }

          </div>
        }

      </div>
      <Footer />
    </div>
  )
}

export default Blog